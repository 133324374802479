<ion-header class="ion-no-border">
  <ion-toolbar>
    <ion-button fill="clear" (click)="back()">
      <img src="assets/ic-back-clay.svg" alt="navigate back button" />
    </ion-button>
  </ion-toolbar>
</ion-header>

<ion-content [fullscreen]="true">
  @if (updateProfileForm) {
  <form [formGroup]="updateProfileForm" (ngSubmit)="updateProfile()">
    <div>
      <app-avatar [avatarUrl]="this.avatarUrl" (upload)="updateAvatar($event)"></app-avatar>
    </div>
    <div id="username-block">
      <h5>Username</h5>
      <br />
      <br />
      <ion-item>
        <ion-input formControlName="username" type="text"></ion-input>
      </ion-item>
      @if (updateProfileForm.controls['username'].hasError('usernamealreadyused')) {
      <span class="error-text">This username has been taken, please pick a different one.</span>
      }
    </div>
    <div>
      <ion-button
        id="btn-submit"
        shape="round"
        type="submit"
        [disabled]="updateProfileForm?.invalid"
      >
        Save
      </ion-button>
    </div>
  </form>
  }
</ion-content>
