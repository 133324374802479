import { Component } from '@angular/core';
import { CommunityFeedComponent } from '../community-feed/community-feed.component';
import { NewsFeedComponent } from '../news-feed/news-feed.component';
import { NgClass } from '@angular/common';
import { IonHeader, IonToolbar, IonContent } from '@ionic/angular/standalone';

@Component({
  selector: 'app-social',
  templateUrl: './social.page.html',
  styleUrls: ['./social.page.scss'],
  standalone: true,
  imports: [NewsFeedComponent, CommunityFeedComponent, NgClass, IonHeader, IonToolbar, IonContent],
})
export class SocialPage {
  isNewsBtnSelected = true;
  constructor() {}

  onClickNewsBtn() {
    this.isNewsBtnSelected = true;
  }

  onClickCommunityBtn() {
    this.isNewsBtnSelected = false;
  }
}
