import { Component, Input, OnChanges, OnDestroy, SimpleChanges } from '@angular/core';
import { Subscription } from 'rxjs';
import { CharitySegment } from '../../../core/constants/CharitySegment';
import { TimeSeriesChartService } from '../../../core/services/graph/time-series-chart.service';
import { Donation } from '../../../core/models/Donation';
import { Chart } from '../../../core/constants/Chart';
import { CharityDataService } from '../../../core/services/graph/charity-data.service';
import { IonGrid, IonRow, IonCol } from '@ionic/angular/standalone';

@Component({
  selector: 'app-segment-chart',
  templateUrl: './segment-chart.component.html',
  styleUrls: ['./segment-chart.component.scss'],
  standalone: true,
  imports: [IonGrid, IonRow, IonCol],
})
export class SegmentChartComponent implements OnDestroy, OnChanges {
  @Input() donationType: string;
  @Input() userId: string;
  private readonly subscriptions = new Subscription();

  allUserDonations = [];
  totalSegmentDonationArray = [];

  chartSegments = [
    CharitySegment.CULTURE,
    CharitySegment.ENVIRONMENT,
    CharitySegment.HEALTH,
    CharitySegment.SOCIAL,
    CharitySegment.RELIEF,
    CharitySegment.RELIGION,
    CharitySegment.STEM,
    CharitySegment.MISC,
  ];
  noChartData: Donation[] = [
    {
      timestamp: 1710518453216,
      amount: 0,
    },
    {
      timestamp: 1710524642486,
      amount: 0,
    },
  ];

  constructor(
    private timeSeriesChart: TimeSeriesChartService,
    private charityDataService: CharityDataService,
  ) {}

  ngOnChanges(changes: SimpleChanges) {
    this.allUserDonations = [];
    this.totalSegmentDonationArray = [];

    if (changes && this.userId !== null) {
      let total: number = 0;

      for (const s of this.chartSegments) {
        const i: number = this.chartSegments.indexOf(s);
        if (s) {
          this.charityDataService
            .getDonationsByUserWithSegmentAndType(this.userId, s, this.donationType)
            .subscribe(res => {
              this.allUserDonations.splice(i, 0, res.length);
              total = +res.reduce((a, b) => a + b.amount, 0).toFixed(2);
              this.totalSegmentDonationArray.splice(i, 0, total);

              setTimeout(() => {
                this.renderChart(i, res);
              }, 0);
            });
        }
      }
    }
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  renderChart(index: number, data: Donation[]) {
    if (data.length <= 1) {
      data = this.noChartData;
    }
    this.timeSeriesChart.renderMiniPersonalGivingChart(
      Chart.PERSONAL_MINI_CHART_HTML_ID + index,
      data,
    );
    this.timeSeriesChart.renderMiniFriendGivingChart(
      Chart.FRIEND_MINI_CHART_HTML_ID + index,
      this.noChartData,
    );
  }
}
