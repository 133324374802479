import { Injectable } from '@angular/core';
import { Observable, throwError, from } from 'rxjs';
import { switchMap, catchError } from 'rxjs/operators';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { NavController } from '@ionic/angular/standalone';

export type Params = {
  [key: string]: string | string[] | number;
};

@Injectable({
  providedIn: 'root',
})
export class HttpClientWrapperService {
  private authSecretKey = 'Bearer Token';

  constructor(
    private httpClient: HttpClient,
    private navControl: NavController,
  ) {}

  private handleError(err, url: string) {
    if (err.status === 401 && !url.includes('/me')) {
      this.navControl.navigateRoot('/login');
    }
    return throwError(() => new Error(err.message));
  }

  private async getAuthorizationHeader(): Promise<{ headers: HttpHeaders }> {
    const token: string = sessionStorage.getItem('accessToken');
    return {
      headers: new HttpHeaders({
        Authorization: token ? `Bearer ${token}` : '',
      }),
    };
  }

  public postWithoutAuth<T, ContentType>(url: string, content: ContentType): Observable<T> {
    return this.httpClient.post<T>(url, content);
  }

  public post<T, ContentType>(url: string, content: ContentType): Observable<T> {
    return from(this.getAuthorizationHeader()).pipe(
      switchMap(headers =>
        this.httpClient.post<T>(url, content, headers).pipe(catchError(err => this.handleError(err, url)))
      )
    );
  }

  public get<T>(url: string, params: Params = {}): Observable<T> {
    return from(this.getAuthorizationHeader()).pipe(
      switchMap(headers =>
        this.httpClient.get<T>(url, { ...headers, params }).pipe(catchError(err => this.handleError(err, url)))
      )
    );
  }

  public put<T, ContentType>(url: string, content: ContentType): Observable<T> {
    return from(this.getAuthorizationHeader()).pipe(
      switchMap(headers =>
        this.httpClient.put<T>(url, content, headers).pipe(catchError(err => this.handleError(err, url)))
      )
    );
  }

  public delete<T>(url: string): Observable<T> {
    return from(this.getAuthorizationHeader()).pipe(
      switchMap(headers =>
        this.httpClient.delete<T>(url, headers).pipe(catchError(err => this.handleError(err, url)))
      )
    );
  }
}