import { Component, CUSTOM_ELEMENTS_SCHEMA, OnInit } from '@angular/core';
import { NgClass } from '@angular/common';
import {
  IonRow,
  IonCol,
  IonSegment,
  IonSegmentButton,
  IonChip,
  IonList,
  IonItem,
  IonThumbnail,
  IonText,
  IonNote,
} from '@ionic/angular/standalone';

@Component({
  selector: 'app-news-feed',
  templateUrl: './news-feed.component.html',
  styleUrls: ['./news-feed.component.scss'],
  standalone: true,
  imports: [
    NgClass,
    IonRow,
    IonCol,
    IonSegment,
    IonSegmentButton,
    IonChip,
    IonList,
    IonItem,
    IonThumbnail,
    IonText,
    IonNote,
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class NewsFeedComponent implements OnInit {
  selectedChip = 'All';
  public ionChipTitles = [
    'All',
    'Culture',
    'Environment',
    'Health',
    'Relief',
    'Religion',
    'Social',
    'STEM',
    'Trending',
    'Top Earner',
  ];
  constructor() {}

  // eslint-disable-next-line @angular-eslint/no-empty-lifecycle-method
  ngOnInit() {}

  onSelectFilterChip(v: string) {
    this.selectedChip = v;
  }
}
