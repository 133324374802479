<ion-header class="ion-no-border">
  <ion-toolbar>
    <ion-button fill="clear" (click)="back()">
      <img src="assets/ic-back-clay.svg" alt="navigate back button" />
    </ion-button>
  </ion-toolbar>
</ion-header>

<ion-content [fullscreen]="true">
  <div class="main-content">
    <section>
      <div class="img-item">
        <img [src]="product?.imageUrl" alt="{{ product?.name }}" />
      </div>

      <div class="item-details">
        <h5>{{ product?.name }}</h5>
        <h4>${{ product?.currentPrice }}</h4>
        <a>{{ charity?.name}}</a>
        <a>{{ auction?.description}}</a>
        <p>{{ product?.description }}</p>
        <!-- <p class="claim-text">
          <strong>How to claim:</strong>
          pick up at 123 N Lehi St, Lehi Utah
        </p> -->
      </div>
    </section>
  

    <section class="bidding-container">
      <div class="bidding-status">
        <ion-row>
          <ion-col>
            <h4>Starting Price</h4>
            <p *ngIf="bids.length > 0" >${{bids[bids.length-1].amount}}</p>
            <p *ngIf="bids.length === 0" >${{product?.currentPrice}}</p>
          </ion-col>
          <ion-col>
            <h4>Current Bid</h4>
            <p>${{ product?.currentPrice }}</p>
          </ion-col>
        </ion-row>
        <ion-row id="time-text">
          <img src="assets/ic-clock-clay.svg" alt="clock icon" />
          <span *ngIf="remainingTime.days > 0">
            {{ remainingTime.days }} day{{ remainingTime.days > 1 ? 's' : '' }},
          </span>
          <span *ngIf="remainingTime.hours > 0 || remainingTime.days > 0">
            {{ remainingTime.hours }} hour{{ remainingTime.hours > 1 ? 's' : '' }},
          </span>
          {{ remainingTime.minutes }} minute{{ remainingTime.minutes > 1 ? 's' : '' }} remaining
        </ion-row>
      </div>
    </section>

    <button class="btn-bid" (click)="placeBid()">Place Bid</button>

    <section class="auction-list" *ngIf="bids.length > 0">
      <ion-grid>
        <ion-row>
          <ion-col>Live Auction</ion-col>
          <ion-col>{{bids?.length}} bids made</ion-col>
        </ion-row>
        <ion-row *ngFor="let bid of bids">
          <!-- <ion-col>User Name</ion-col> -->
          <ion-col>{{bid?.createdAt | date: 'M/d/yy h:mm a'}}</ion-col>
          <ion-col>${{bid?.amount}}</ion-col>
        </ion-row>
      </ion-grid>
    </section>

  </div>
</ion-content>
