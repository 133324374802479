import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, Subscription } from 'rxjs';
import { AuthService } from '../auth/auth.service';
import { GivingGoal } from '../../models/GivingGoal';

@Injectable({
  providedIn: 'root',
})
export class GivingGoalsService {
  private readonly subscriptions = new Subscription();

  private _givingGoals$: BehaviorSubject<GivingGoal> = new BehaviorSubject<GivingGoal>(null);

  constructor(private authService: AuthService) {
  }

  get givingGoal(): GivingGoal {
    return this._givingGoals$.getValue();
  }
  get givingGoal$(): Observable<GivingGoal> {
    return this._givingGoals$.asObservable();
  }

  recordGivingGoal() {
    // Record a giving goal in the database
  }

  getGivingGoalByUserId() {
    // Fetch a giving goal from the database by user ID
  }
}
