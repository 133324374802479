import { APP_INITIALIZER, ErrorHandler, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { Router, RouterModule } from '@angular/router';
import { IonicStorageModule } from '@ionic/storage-angular';
import { SnakeCaseToTitleCasePipe } from './pipes/snake-case-to-title-case.pipe';
import * as Sentry from '@sentry/angular';

/* eslint-disable  @typescript-eslint/typedef */
const modules = [RouterModule, CommonModule, FormsModule, ReactiveFormsModule];

const ionicStorage = IonicStorageModule.forRoot();

@NgModule({
  imports: [...modules, SnakeCaseToTitleCasePipe, ionicStorage],
  exports: [...modules, SnakeCaseToTitleCasePipe],
  providers: [
    {
      provide: ErrorHandler,
      useValue: Sentry.createErrorHandler(),
    },
    {
      provide: Sentry.TraceService,
      deps: [Router],
    },
    {
      provide: APP_INITIALIZER,
      useFactory: () => () => {},
      deps: [Sentry.TraceService],
      multi: true,
    },
  ],
})
export class SharedModule {}
