import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-charity-info',
  templateUrl: './charity-info.component.html',
  styleUrls: ['./charity-info.component.scss'],
  standalone: true,
})
export class CharityInfoComponent implements OnInit {
  constructor() {}

  // eslint-disable-next-line @angular-eslint/no-empty-lifecycle-method
  ngOnInit() {}
}
