import { Component, OnDestroy } from '@angular/core';
import { FormBuilder, Validators, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { Subscription } from 'rxjs';
import { NavController } from '@ionic/angular/standalone';
import { AvatarComponent } from '../avatar/avatar.component';
import { User } from '../../../core/models/User';
import {
  IonHeader,
  IonToolbar,
  IonButton,
  IonContent,
  IonItem,
  IonInput,
} from '@ionic/angular/standalone';

@Component({
  selector: 'app-edit-profile',
  templateUrl: './edit-profile.page.html',
  styleUrls: ['./edit-profile.page.scss'],
  standalone: true,
  imports: [
    FormsModule,
    ReactiveFormsModule,
    AvatarComponent,
    IonHeader,
    IonToolbar,
    IonButton,
    IonContent,
    IonItem,
    IonInput,
  ],
})
export class EditProfilePage implements OnDestroy {
  loggedInUser: User;
  private readonly subscriptions = new Subscription();

  updateProfileForm = this.fb.group({
    username: ['', [Validators.required, Validators.minLength(2)]],
    avatar_url: [],
  });

  constructor(
    private fb: FormBuilder,
    private navController: NavController,
  ) {}

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  updateProfile() {
    // Update user profile
  }

  get avatarUrl() {
    return this.updateProfileForm?.value?.avatar_url as string;
  }

  updateAvatar(event: string) {
    this.updateProfileForm.patchValue({
      avatar_url: event,
    });
  }

  back() {
    this.navController.back();
  }
}
