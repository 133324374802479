<ion-header class="ion-no-border">
  <ion-toolbar>
    <ion-button fill="clear" (click)="back()">
      <img src="assets/ic-back-clay.svg" alt="navigate back button" />
      Back
    </ion-button>
  </ion-toolbar>
</ion-header>

<ion-content [fullscreen]="true">
  <div class="container">
    <h3 class="header">Menu</h3>

    <ion-list>
      <ion-item [button]="true" (click)="goToItem('summary')">
        <div class="unread-indicator-wrapper" slot="start"></div>
        <ion-label>
          <ion-text>Charity Information</ion-text>
          <br />
          <ion-note color="medium" class="ion-text-wrap">Modify or update information</ion-note>
        </ion-label>
      </ion-item>

      <ion-item [button]="true" (click)="goToItem('transfer')">
        <div class="unread-indicator-wrapper" slot="start"></div>
        <ion-label>
          <ion-text>Transfers</ion-text>
          <br />
          <ion-note color="medium" class="ion-text-wrap">Deposit, withdrawals</ion-note>
        </ion-label>
      </ion-item>

      <ion-item [button]="true" (click)="goToItem('security')">
        <div class="unread-indicator-wrapper" slot="start">
          <div class="unread-indicator"></div>
        </div>
        <ion-label>
          <ion-text>Security and privacy</ion-text>
          <br />
          <ion-note color="medium" class="ion-text-wrap">
            Password, two-factor authentication, data sharing
          </ion-note>
        </ion-label>
      </ion-item>

      <ion-item [button]="true" (click)="goToItem('settings')">
        <div class="unread-indicator-wrapper" slot="start"></div>
        <ion-label>
          <ion-text>Settings</ion-text>
          <br />
          <ion-note color="medium" class="ion-text-wrap">Notifications, account</ion-note>
        </ion-label>
      </ion-item>

      <ion-item [button]="true" (click)="goToItem('support')">
        <div class="unread-indicator-wrapper" slot="start"></div>
        <ion-label>
          <ion-text>Sunny Street Support</ion-text>
          <br />
          <ion-note color="medium" class="ion-text-wrap">Help Center, Contact us</ion-note>
        </ion-label>
      </ion-item>

      <ion-item [button]="true" (click)="goToItem('account')">
        <div class="unread-indicator-wrapper" slot="start"></div>
        <ion-label>
          <ion-text>Switch Account</ion-text>
          <br />
          <ion-note color="medium" class="ion-text-wrap">Go back to personal account</ion-note>
        </ion-label>
      </ion-item>

      <ion-item lines="none">
        <div class="unread-indicator-wrapper" slot="start"></div>
        <ion-label>
          <ion-text>Account No</ion-text>
          <br />
          <ion-note color="medium" class="ion-text-wrap">#123232323</ion-note>
        </ion-label>
      </ion-item>
    </ion-list>

    <div class="btn-user">
      <button (click)="switchToUser()">Switch to User Portal</button>
    </div>

    <div class="btn-signout">
      <button (click)="signOut()">Log Out</button>
    </div>
  </div>
</ion-content>
