import { Injectable } from '@angular/core';
import { FollowedCharity } from '../../models/FollowedCharity';
import { Charity } from '../../models/Charity';
import { Observable } from 'rxjs';
import { environment } from '../../../../environments/environment';
import { HttpClientWrapperService } from '../http/http-client-wrapper.service';

@Injectable({
  providedIn: 'root',
})
export class CharityFollowService {
  private readonly baseApiUrl = `${environment.beApiUrl}/api/v1/followed-charities`;

  constructor(private httpClient: HttpClientWrapperService) {}

  isCharityFollowed(userId: string, charityId: string): Observable<boolean> {
    return this.httpClient.get<boolean>(
      `${this.baseApiUrl}?charityId=${charityId}&userId=${userId}`,
    );
  }

  getFollowedCharitiesList(userId: string): Observable<Charity[]> {
    return this.httpClient.get<Charity[]>(`${this.baseApiUrl}/user/${userId}`);
  }

  followCharity(userId: string, charity: Charity): Observable<FollowedCharity> {
    const charityToFollow: FollowedCharity = {
      followingUserId: userId,
      followedCharityId: charity?.id,
    };

    return this.httpClient.post<FollowedCharity, FollowedCharity>(
      `${this.baseApiUrl}`,
      charityToFollow,
    );
  }

  unfollowCharity(userId: string, charityId: string): Observable<FollowedCharity> {
    const charityToUnfollow: FollowedCharity = {
      followingUserId: userId,
      followedCharityId: charityId,
    };

    return this.httpClient.post<FollowedCharity, FollowedCharity>(
      `${this.baseApiUrl}/unfollow`,
      charityToUnfollow,
    );
  }
}
