<ion-header class="ion-no-border">
  <ion-toolbar>
    <ion-button fill="clear" (click)="back()">
      <img src="assets/ic-back-clay.svg" alt="navigate back button" />
    </ion-button>
  </ion-toolbar>
</ion-header>

<ion-content [fullscreen]="true">
  <div class="container">
    <div>
      <h3 class="header">Security</h3>
      <p>Protect your Sunny Street account with additional layers of security.</p>

      <ion-list>
        <ion-item [button]="true">
          <div class="unread-indicator-wrapper" slot="start"></div>
          <ion-label>
            <ion-text>Create passkey</ion-text>
            <br />
          </ion-label>
        </ion-item>

        <ion-item [button]="true">
          <div class="unread-indicator-wrapper" slot="start"></div>
          <ion-label>
            <ion-text>Change password</ion-text>
            <br />
          </ion-label>
        </ion-item>

        <ion-item [button]="true">
          <div class="unread-indicator-wrapper" slot="start"></div>
          <ion-label>
            <ion-text>Two-factor authentication</ion-text>
            <br />
            <ion-note color="medium" class="ion-text-wrap">Disabled</ion-note>
          </ion-label>
        </ion-item>

        <ion-item [button]="true">
          <div class="unread-indicator-wrapper" slot="start"></div>
          <ion-label>
            <ion-text>Device Security</ion-text>
            <br />
            <ion-note color="medium" class="ion-text-wrap">Face ID</ion-note>
          </ion-label>
        </ion-item>

        <ion-item [button]="true">
          <div class="unread-indicator-wrapper" slot="start"></div>
          <ion-label>
            <ion-text>Devices</ion-text>
            <br />
          </ion-label>
        </ion-item>
      </ion-list>
    </div>

    <div>
      <h3 class="header">Privacy</h3>
      <p>Manage how your data is used.</p>

      <ion-list>
        <ion-item [button]="true">
          <div class="unread-indicator-wrapper" slot="start"></div>
          <ion-label>
            <ion-text>Manage profile visibility</ion-text>
            <br />
          </ion-label>
        </ion-item>

        <ion-item [button]="true">
          <div class="unread-indicator-wrapper" slot="start"></div>
          <ion-label>
            <ion-text>Blocking</ion-text>
            <br />
          </ion-label>
        </ion-item>

        <ion-item [button]="true">
          <div class="unread-indicator-wrapper" slot="start"></div>
          <ion-label>
            <ion-text>Manage your data</ion-text>
            <br />
          </ion-label>
        </ion-item>

        <ion-item [button]="true">
          <div class="unread-indicator-wrapper" slot="start"></div>
          <ion-label>
            <ion-text>Privacy Policy</ion-text>
            <br />
          </ion-label>
        </ion-item>
      </ion-list>
    </div>
  </div>
</ion-content>
