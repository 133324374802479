import { Component, ElementRef, OnDestroy, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular/standalone';
import { NgClass, DatePipe, TitleCasePipe, CommonModule } from '@angular/common';
import { CheckoutComponent } from '../../../shared/components/stripe-embedded-components/checkout/checkout.component';
import { AlertService } from '../../../core/services/navigation/alert.service';
//import { Charity } from '../../../core/models/Charity';
import { DonateModeToggleComponent } from 'src/app/shared/components/donate-mode-toggle/donate-mode-toggle.component';
import { Subscription } from 'rxjs';
import {
  IonHeader,
  IonToolbar,
  IonButtons,
  IonButton,
  IonContent,
  IonItem,
  IonGrid,
  IonRow,
  IonCol,
  IonLabel,
  IonInput,
  IonText,
  IonIcon,
  IonCheckbox,
} from '@ionic/angular/standalone';
import { Product } from 'src/app/core/models/Product';
import { User } from 'src/app/core/models/User';
import { AuthService } from 'src/app/core/services/auth/auth.service';
import { CommerceService } from 'src/app/core/services/commerce/commerce.service';
import { FormBuilder, Validators, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ViewChild } from '@angular/core';
import { CharityManagementService } from 'src/app/core/services/charity/charity-management.service';
import { SafeResourceUrl } from '@angular/platform-browser';

@Component({
  selector: 'app-product-modal',
  templateUrl: './product-modal.component.html',
  styleUrls: ['./product-modal.component.scss'],
  standalone: true,
  imports: [
    NgClass,
    FormsModule,
    ReactiveFormsModule,
    DatePipe,
    TitleCasePipe,
    CheckoutComponent,
    IonHeader,
    IonToolbar,
    IonButtons,
    IonButton,
    IonContent,
    IonGrid,
    IonRow,
    IonCol,
    IonItem,
    IonLabel,
    IonInput,
    CommonModule,
    IonIcon,
    IonText,
    IonCheckbox,
    DonateModeToggleComponent,
  ],
})
export class ProductModalComponent implements OnInit, OnDestroy {
  private readonly subscriptions = new Subscription();

  @ViewChild('fileInput') fileInput: ElementRef;
  loggedInUser: User;
  charityId: string;
  imagePreview: string | null = null;
  selectedFile: File | null = null;
  _avatarUrl: SafeResourceUrl = 'assets/ic-profile-avatar.svg';

  itemForm = this.fb.group({
    itemName: ['', Validators.required],
    itemPrice: [null, [Validators.required, Validators.min(0), Validators.pattern(/^\d+(\.\d+)?$/)]],
    itemDescription: ['', Validators.maxLength(255)],
    itemImage: [null],
  });

  constructor(
    private modalCtrl: ModalController,
    private alertService: AlertService,
    private authService: AuthService,
    private commerceService: CommerceService,
    private fb: FormBuilder,
    private charityManagementService: CharityManagementService
  ) {}

  ngOnInit(): void {
    this.getLoggedInUser();
  }

  ngOnDestroy() {
    this.subscriptions.unsubscribe();
  }

  getLoggedInUser() {
    this.subscriptions.add(
      this.authService.user$.subscribe(user => {
        if (user) {
          this.loggedInUser = user;
          this.getCharityId(user.id);
        }
      })
    )
  }

  cancel() {
    this.alertService
      .showAlertForDonation('Cancel', 'Do you want to cancel this action?')
      .subscribe((res: HTMLIonAlertElement) => {
        res.onDidDismiss().then(data => {
          if (data.role === 'yes') {
            this.modalCtrl.dismiss(null, 'no');
          }
        });
      });
  }

  openFileDialog() {
    this.fileInput.nativeElement.click();
  }

  selectImage(event: Event): void {
    const input: HTMLInputElement = event.target as HTMLInputElement;
    if (input?.files?.length) {
      this.selectedFile = input.files[0];
  
      const reader: FileReader = new FileReader();
      reader.onload = () => {
        this.imagePreview = reader.result as string;
      };
      reader.readAsDataURL(this.selectedFile);
    }
  }

  publishItem() {
    if (this.itemForm.invalid) {
      console.error('Form is invalid:', this.itemForm.errors);
      return;
    }

    const product: Partial<Product> = {
      name: this.itemForm.get('itemName').value,
      currentPrice: Number(this.itemForm.get('itemPrice').value),
      description: this.itemForm.get('itemDescription').value,
      acceptsBids: false,
      charityId: Number(this.charityId),
    };
    const productBlob: Blob = new Blob([JSON.stringify(product)], { type: 'application/json' });

    const formData: FormData = new FormData();
    formData.append('product', productBlob);
    formData.append('image', this.selectedFile, this.selectedFile.name);

    this.commerceService.postProduct(formData).subscribe({
      next: () => {
        this.modalCtrl.dismiss();
      },
      error: (err) => {
        console.error('Error posting product:', err);
      },
    });
  }

  getCharityId(userId: string) {
    this.charityManagementService.getCharityClaimsByUserId(userId).subscribe(
      (data) => {
        this.charityId = data[0].charityId
      }
    )
  }
}
