<ion-header class="ion-no-border">
  <ion-toolbar>
    <div>
      <ion-button fill="clear" (click)="back()">
        <img src="assets/ic-back-clay.svg" alt="navigate back button" />
      </ion-button>
      <ion-title>Sunny Street Menu</ion-title>
    </div>
  </ion-toolbar>
</ion-header>

<ion-content [fullscreen]="true">
  <div class="container">
    <section class="user-summary-section">
      <div
        class="profile-avatar"
        (click)="addProfilePicture()"
        (keydown)="addProfilePicture()"
        tabindex="{0}"
      >
        <img alt="avatar" [src]="avatarUrl" />
      </div>

      <div class="user-info">
        <ion-label>
          <p class="username">&#64;{{loggedInUser?.email}}</p>
          <br />
        </ion-label>
      </div>
    </section>

    <ion-list>

      <!-- <ion-item [button]="true" (click)="goToItem('summary')">
        <div class="unread-indicator-wrapper" slot="start"></div>
        <ion-label>
          <ion-text>Giving</ion-text>
          <br />
          <ion-note color="medium" class="ion-text-wrap">Balances, recurring donations</ion-note>
        </ion-label>
      </ion-item> -->

      <!-- <ion-item [button]="true" (click)="goToItem('transfer')">
        <div class="unread-indicator-wrapper" slot="start"></div>
        <ion-label>
          <ion-text>Transfer</ion-text>
          <br />
          <ion-note color="medium" class="ion-text-wrap">Deposit, withdrawals</ion-note>
        </ion-label>
      </ion-item> -->

      <ion-item [button]="true" (click)="goToItem('security')">
        <div class="unread-indicator-wrapper" slot="start">
          <div class="unread-indicator"></div>
        </div>
        <ion-label>
          <ion-text>Security and privacy</ion-text>
          <br />
          <ion-note color="medium" class="ion-text-wrap">
            Password, two-factor authentication, data sharing
          </ion-note>
        </ion-label>
      </ion-item>

      <ion-item [button]="true" (click)="goToItem('settings')">
        <div class="unread-indicator-wrapper" slot="start"></div>
        <ion-label>
          <ion-text>Settings</ion-text>
          <br />
          <ion-note color="medium" class="ion-text-wrap">Notifications, account</ion-note>
        </ion-label>
      </ion-item>

      <ion-item [button]="true" (click)="goToItem('support')">
        <div class="unread-indicator-wrapper" slot="start"></div>
        <ion-label>
          <ion-text>Sunny Street Support</ion-text>
          <br />
          <ion-note color="medium" class="ion-text-wrap">Help Center, Contact us</ion-note>
        </ion-label>
      </ion-item>

      <ion-item lines="none" [button]="true" (click)="goToItem('manage-charity')">
        <div class="unread-indicator-wrapper" slot="start"></div>
        <ion-label>
          <ion-text>Manage Charities</ion-text>
          <br />
          <ion-note color="medium" class="ion-text-wrap">Manage or claim a charity</ion-note>
        </ion-label>
      </ion-item>

      <!-- <ion-item [button]="true" (click)="testDeepLink()">
        <div class="unread-indicator-wrapper" slot="start"></div>
        <ion-label>
          <ion-text>Test Deep Link</ion-text>
          <br />
          <ion-note color="medium" class="ion-text-wrap">
            Click here to redirect and test deep link
          </ion-note>
        </ion-label>
      </ion-item> -->

      <!-- <ion-item lines="none">
        <div class="unread-indicator-wrapper" slot="start"></div>
        <ion-label>
          <ion-text>Account No</ion-text>
          <br />
          <ion-note color="medium" class="ion-text-wrap">#123232323</ion-note>
        </ion-label>
      </ion-item> -->
    </ion-list>

    <div class="btn-charity" *ngIf="authority ==='ROLE_CHARITY'">
      <button (click)="switchToCharity()">Switch to Charity Portal</button>
    </div>
    <div class="btn-signout">
      <button (click)="signOut()">Log Out</button>
    </div>
  </div>
</ion-content>
