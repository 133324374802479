import { Component, OnDestroy, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { ModalController, NavController, ViewWillEnter } from '@ionic/angular/standalone';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { InviteFriendModalComponent } from '../invite-friend-modal/invite-friend-modal.component';
import { NgClass, TitleCasePipe } from '@angular/common';
import { User } from '../../../core/models/User';
import { CharitySegment } from '../../../core/constants/CharitySegment';
import { GivingSegment } from '../../../core/models/GivingSegment';
import { AuthService } from '../../../core/services/auth/auth.service';
import { BubbleChartService } from '../../../core/services/graph/bubble-chart.service';
import { addIcons } from 'ionicons';
import {
  beerOutline,
  footstepsOutline,
  medkitOutline,
  colorPaletteOutline,
  tennisballOutline,
  globeOutline,
} from 'ionicons/icons';
import {
  IonHeader,
  IonToolbar,
  IonContent,
  IonLabel,
  IonList,
  IonItem,
  IonNote,
  IonRow,
  IonCol,
  IonSegment,
  IonSegmentButton,
  IonChip,
  IonIcon,
  IonText,
} from '@ionic/angular/standalone';

@Component({
  selector: 'app-profile',
  templateUrl: './profile.page.html',
  styleUrls: ['./profile.page.scss'],
  standalone: true,
  imports: [
    NgClass,
    TitleCasePipe,
    IonHeader,
    IonToolbar,
    IonContent,
    IonLabel,
    IonList,
    IonItem,
    IonNote,
    IonRow,
    IonCol,
    IonSegment,
    IonSegmentButton,
    IonChip,
    IonIcon,
    IonText,
  ],
})
export class ProfilePage implements OnInit, OnDestroy, ViewWillEnter {
  loggedInUser: User;
  private readonly subscriptions = new Subscription();
  public tabTitles = [
    CharitySegment.CULTURE,
    CharitySegment.ENVIRONMENT,
    CharitySegment.HEALTH,
    CharitySegment.SOCIAL,
    CharitySegment.RELIEF,
    CharitySegment.RELIGION,
    CharitySegment.STEM,
  ];

  public chartData: GivingSegment[] = [];
  selectedTab = CharitySegment.CULTURE;
  totalGiving = 0;

  chartInitialData: GivingSegment[] = [
    { sum: 0, charitySegment: CharitySegment.CULTURE, percentage: 0 },
    { sum: 0, charitySegment: CharitySegment.ENVIRONMENT, percentage: 0 },
    { sum: 0, charitySegment: CharitySegment.SOCIAL, percentage: 0 },
    { sum: 0, charitySegment: CharitySegment.RELIEF, percentage: 0 },
    { sum: 0, charitySegment: CharitySegment.RELIGION, percentage: 0 },
    { sum: 0, charitySegment: CharitySegment.STEM, percentage: 0 },
    { sum: 0, charitySegment: CharitySegment.HEALTH, percentage: 0 },
  ];

  avatarUrl: SafeResourceUrl = 'assets/ic-profile-avatar.svg';

  constructor(
    private authService: AuthService,
    private bubbleChartService: BubbleChartService,
    private navController: NavController,
    private modalController: ModalController,
    private readonly dom: DomSanitizer,
  ) {
    addIcons({
      beerOutline,
      footstepsOutline,
      medkitOutline,
      colorPaletteOutline,
      tennisballOutline,
      globeOutline,
    });
  }

  ngOnInit() {
    this.getLoggedInUserInfo();
    if (this.chartData.length < 1) {
      this.chartData = this.chartInitialData;
    }
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  getLoggedInUserInfo() {
    const subscription: Subscription = this.authService.user$.subscribe({
      next: user => {
        if (user) {
          this.loggedInUser = user;
          this.getBubbleChartData(user.id);
          this.downloadImage();
        }
      },
      error: () => {},
    });
    this.subscriptions.add(subscription);
  }

  getBubbleChartData(id: string) {
    this.subscriptions.add(
      this.bubbleChartService.getGivingSegments(id).subscribe(data => {
        if (data) {
          let filteredData: GivingSegment[] = data.filter(
            d =>
              d.charitySegment != CharitySegment.MISC &&
              d.charitySegment != CharitySegment.NOT_APPLICABLE,
          );

          this.totalGiving = filteredData?.reduce((a, b) => a + b.sum, 0);
          filteredData.map(d => {
            d.percentage = Math.round((d.sum / this.totalGiving) * 100);
          });

          // Fill in bubble that doesn't have data so that it show 0% percent on the chart
          filteredData = this.chartInitialData.map(
            d => filteredData.find(s => s.charitySegment === d.charitySegment) || d,
          );

          const sortedData: GivingSegment[] = filteredData?.sort(
            (a, b) => b?.percentage - a?.percentage,
          );
          this.chartData = this.arrayMove(sortedData, 0, 3);
        }
      }),
    );
  }

  // Helper function
  arrayMove(arr: GivingSegment[], fromIndex: number, toIndex: number) {
    const newArr: GivingSegment[] = arr;
    const element: GivingSegment = arr[fromIndex];
    newArr.splice(fromIndex, 1);
    newArr.splice(toIndex, 0, element);
    return newArr;
  }

  downloadImage() {
    //
  }

  onClickInviteFriends() {
    this.modalController
      .create({
        component: InviteFriendModalComponent,
        cssClass: 'invite-friend-modal',
      })
      .then(modal => {
        modal.present();

        modal.onDidDismiss().then();
      });
  }

  ionViewWillEnter() {
    //await this.authService.getUserInformation();
  }

  setCurrentTab(tab: CharitySegment) {
    this.selectedTab = tab;
  }

  addProfilePicture() {
    this.navController.navigateForward(['/edit-profile']).then(r => r);
  }

  goToDiscoverPage() {
    this.navController.navigateForward(['/user-role-tabs/discover']).then(r => r);
  }

  goToNewsFeedPage() {
    this.navController.navigateForward(['/user-role-tabs/social']).then(r => r);
  }
}
