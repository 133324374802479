<ion-content [fullscreen]="true">
  <div class="container">
    <h3 class="header">Claim a Charity</h3>

    <form [formGroup]="form">
      <ion-item lines="none">
        <ion-input
          label-placement="floating"
          placeholder="Jon Smith"
          formControlName="charity_name"
          ngDefaultControl
        >
          <div slot="label">Charity Name</div>
        </ion-input>
      </ion-item>
      <br />

      <ion-item lines="none">
        <ion-input
          label-placement="floating"
          type="number"
          placeholder="000000000"
          formControlName="ein"
          ngDefaultControl
        >
          <div slot="label">EIN</div>
        </ion-input>
      </ion-item>
      <br />

      <ion-item lines="none">
        <ion-input
          label-placement="floating"
          placeholder="Jon Smith"
          formControlName="contact"
          ngDefaultControl
        >
          <div slot="label">Charity Contact</div>
        </ion-input>
      </ion-item>
      <br />

      <ion-item lines="none">
        <ion-input
          label-placement="floating"
          type="email"
          placeholder="email@sunny.com"
          formControlName="email"
          ngDefaultControl
        >
          <div slot="label">Email</div>
        </ion-input>
      </ion-item>
      <br />

      <ion-item lines="none">
        <ion-input
          label-placement="floating"
          placeholder="123 Sunny Street"
          formControlName="street"
          ngDefaultControl
        >
          <div slot="label">Street</div>
        </ion-input>
      </ion-item>
      <br />

      <ion-item lines="none">
        <ion-input
          label-placement="floating"
          placeholder="Lehi"
          formControlName="city"
          ngDefaultControl
        >
          <div slot="label">City</div>
        </ion-input>
      </ion-item>
      <br />

      <ion-item lines="none">
        <ion-input
          label-placement="floating"
          placeholder="Utah"
          formControlName="state"
          ngDefaultControl
        >
          <div slot="label">State</div>
        </ion-input>
      </ion-item>
      <br />

      <ion-item lines="none">
        <ion-input
          label-placement="floating"
          placeholder="12334"
          formControlName="zip"
          ngDefaultControl
        >
          <div slot="label">Zip</div>
        </ion-input>
      </ion-item>
      <br />

      <ion-item lines="none">
        <ion-input
          label-placement="floating"
          placeholder="sunny.com"
          formControlName="website"
          ngDefaultControl
        >
          <div slot="label">Website</div>
        </ion-input>
      </ion-item>
      <br />

      <div class="docs-requirements">
        Please Upload:
        <ul>
          <li>Proof of EIN</li>
          <li>Driver License with Matching Name</li>
          <li>State Registration</li>
        </ul>
      </div>

      <label class="btn-upload-container" for="file-upload">
        <div class="btn-upload">
          <img src="../../../../../../assets/ic-upload.svg" alt="upload icon" />
          Upload Documents
        </div>
      </label>

      <input
        hidden
        id="file-upload"
        type="file"
        accept="pdf/*"
        formControlName="documents"
        (change)="onUploadFiles($event)"
        multiple
      />

      <div class="chosen-files-container">
        @for (item of uploadedDocs; track item) {
          <span>{{ item?.name }}</span>
          <br />
        }
      </div>
    </form>

    <div class="btn-submit">
      <button
        [ngClass]="form.invalid ? 'btn-disabled' : 'btn-enabled'"
        [disabled]="form.invalid"
        (click)="submit()"
      >
        Submit Claim
      </button>
    </div>
  </div>
</ion-content>
