<ion-header class="ion-no-border">
  <ion-toolbar></ion-toolbar>
</ion-header>

<ion-content [fullscreen]="true">
  <div class="main-content">
    <section>
      <div class="header-container">
        <ion-thumbnail>
          <img
            alt="Silhouette of mountains"
            src="https://ionicframework.com/docs/img/demos/thumbnail.svg"
          />
        </ion-thumbnail>
        <h3>{{charity?.name | titlecase}}</h3>
      </div>

      <div class="stats-summary">
        <h1>
          ${{totalMoneyDonation.toFixed(2)}} /
          <span>{{totalHourDonation}} hr</span>
        </h1>
        <div class="text-small">
          <p id="dollar-text">
            <img src="assets/ic-polygon-green.svg" alt="icon" />
            ${{totalRangeMoneyDonation.toFixed(2)}}
          </p>
          <p id="hour-text">
            <img src="assets/ic-polygon-clay.svg" alt="icon" />
            {{totalRangeHourDonation}} hr
          </p>
          <p>{{timeRangeText}}</p>
        </div>
      </div>
      <!--  Graph-->
      @defer (on immediate) {
      <app-charity-chart
        [donations]="getDonations()"
        [isHourMode]="isHourMode"
        (tabSelectedEmitter)="onTabSelected($event)"
      ></app-charity-chart>
      }
    </section>

    <section class="profile-buttons">
      <div class="btn-see-followers">See Followers</div>
      <div class="btn-edit-profile">Edit Profile</div>
    </section>

    <section class="position-section">
      <h4>Charity Position</h4>
      <ion-grid>
        <ion-row>
          <ion-col>
            <p>Quantity</p>
            <span>{{moneyDonationHistory?.length ? moneyDonationHistory.length : '0'}}</span>
          </ion-col>
          <ion-col>
            <p>Total Donation</p>
            <span>${{totalDonatedMoneyToCharity.toFixed(2)}}</span>
          </ion-col>
        </ion-row>
        <ion-row>
          <ion-col>
            <p>Average Donation</p>
            <span>
              ${{(totalDonatedMoneyToCharity / (moneyDonationHistory?.length ?
              moneyDonationHistory.length : 1)).toFixed(2)}}
            </span>
          </ion-col>
          <ion-col>
            <p>Segment Diversity</p>
            <span>
              {{((totalDonatedMoneyToCharity / totalDonatedMoneyForCharity)*100).toFixed(0)}}%
            </span>
          </ion-col>
        </ion-row>
        <ion-row style="margin-top: 1rem">
          <ion-col>
            <p class="today-donation">Today's Donations</p>
          </ion-col>
          <ion-col>
            <p class="today-donation">${{todayDonationForCharity.toFixed(2)}}</p>
          </ion-col>
        </ion-row>
      </ion-grid>
    </section>

    <section class="charity-section">
      <h4>Charity Information</h4>
      <ion-grid>
        <ion-row>
          <ion-col>
            <p>Revenue</p>
            <span>{{charity?.revenueAmount}}</span>
          </ion-col>
          <ion-col>
            <p>Assets</p>
            <span>{{charity?.assetAmount}}</span>
          </ion-col>
        </ion-row>
        <ion-row>
          <ion-col>
            <p>Name</p>
            <span>{{charity?.name}}</span>
          </ion-col>
          <ion-col>
            <p>EIN</p>
            <span>{{charity?.ein}}</span>
          </ion-col>
        </ion-row>
        <ion-row>
          <ion-col>
            <p>Website</p>
            <span id="website-link">{{charity?.website}}website.com</span>
          </ion-col>
          <ion-col>
            <p>Segment</p>
            <span>{{charity?.charitySegment}}</span>
          </ion-col>
        </ion-row>
      </ion-grid>
    </section>

    <section class="donation-history-section">
      <h4>Donation Received</h4>
      <ion-grid>
        <ion-row>
          <ion-col class="col-header-date">Date</ion-col>
          <ion-col class="col-header-date">Giver</ion-col>
          <ion-col class="col-header-amount">Amount</ion-col>
        </ion-row>
        @for (d of moneyDonationHistory; track d) {
        <ion-row>
          <ion-col class="col-date">{{d?.timestamp | date: "M/d/yyyy"}}</ion-col>
          <ion-col class="col-date">Test name</ion-col>
          <ion-col class="col-amount">${{d.amount}}</ion-col>
        </ion-row>
        }
      </ion-grid>
    </section>

    <!-- <section class="explore-impact-section">
      <div class="section-title-container">
        <h4>Impact Points</h4>
        <img src="assets/ic-plus-circle.svg" alt="plus circle icon" />
      </div>
      <div>
        <ion-item></ion-item>
      </div>
    </section>

    <section class="auction-section">
      <div class="section-title-container">
        <h4>Active Auctions</h4>
        <img src="assets/ic-plus-circle.svg" alt="plus circle icon" />
      </div>

      <div>
        <ion-item></ion-item>
      </div>
    </section>

    <section class="event-section">
      <div class="section-title-container">
        <h4>Events</h4>
        <img src="assets/ic-plus-circle.svg" alt="plus circle icon" />
      </div>

      <div>
        <ion-item></ion-item>
      </div>
    </section> -->
  </div>
</ion-content>
