import { Component, CUSTOM_ELEMENTS_SCHEMA, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { NavController } from '@ionic/angular/standalone';
import { Subscription } from 'rxjs';
import { NgClass, TitleCasePipe } from '@angular/common';
import { CharityService } from '../../../core/services/charity/charity.service';
import { Charity } from '../../../core/models/Charity';
import { addIcons } from 'ionicons';
import { options } from 'ionicons/icons';
import {
  IonHeader,
  IonToolbar,
  IonContent,
  IonRow,
  IonSearchbar,
  IonCol,
  IonIcon,
  IonSegment,
  IonSegmentButton,
  IonChip,
  IonList,
  IonItem,
  IonButton,
  IonInfiniteScroll,
  IonInfiniteScrollContent,
} from '@ionic/angular/standalone';

@Component({
  selector: 'app-discover',
  templateUrl: './discover.page.html',
  styleUrls: ['./discover.page.scss'],
  standalone: true,
  imports: [
    TitleCasePipe,
    NgClass,
    IonHeader,
    IonToolbar,
    IonContent,
    IonRow,
    IonSearchbar,
    IonCol,
    IonIcon,
    IonSegment,
    IonSegmentButton,
    IonChip,
    IonList,
    IonItem,
    IonButton,
    IonInfiniteScroll,
    IonInfiniteScrollContent,
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class DiscoverPage implements OnInit, OnDestroy {
  private readonly subscriptions = new Subscription();
  @ViewChild(IonInfiniteScroll) infiniteScroll: IonInfiniteScroll;
  charities: Charity[] = [];
  filteredCharities: Charity[] = [];
  selectedChip: string = '';
  query: string = '';
  startSearch = false;
  public ionChipTitles = [
    'All',
    'Culture',
    'Environment',
    'Health',
    'Relief',
    'Religion',
    'Social',
    'STEM',
    'Trending',
    'Top Earner',
  ];

  constructor(private charityService: CharityService, private navController: NavController) {
    addIcons({ options });
  }

  ngOnInit() {
    this.getCharities();
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  getCharities() {
    this.subscriptions.add(
      this.charityService.getCharities('', '', 0, 20).subscribe(charities => {
        this.charities = charities;
      }),
    );
  }

  getData(event) {
    let charitySegment: string = '';
    let sort: string[] = [];
    if (
      this.selectedChip == 'Culture' ||
      this.selectedChip == 'Environment' ||
      this.selectedChip == 'Health' ||
      this.selectedChip == 'Religion' ||
      this.selectedChip == 'Social' ||
      this.selectedChip == 'STEM' ||
      this.selectedChip == 'Relief'
    ) {
      charitySegment = this.selectedChip.toLowerCase();
    } else if (this.selectedChip == 'Trending') {
      sort = ['income'];
    } else if (this.selectedChip == 'Top Earner') {
      sort = ['-revenue'];
    }

    this.subscriptions.add(
      this.charityService
        .getCharities(this.query, charitySegment, 0, this.charities.length + 10, sort)
        .subscribe(charities => {
          this.charities = charities;
          event.target.complete();
        }),
    );
  }

  onFilteringCharities(v: string) {
    if (this.selectedChip === v) {
      this.startSearch = false;
      this.selectedChip = '';
      if (v !== 'All') {
        this.getCharities();
      }
    } else {
      this.startSearch = true;
      this.selectedChip = v;
  
      if (v === 'All') {
        this.getCharities();
      } else {
        let charitySegment: string = '';
        let sort: string[] = [];
  
        if (
          v === 'Culture' ||
          v === 'Environment' ||
          v === 'Health' ||
          v === 'Religion' ||
          v === 'Social' ||
          v === 'STEM' ||
          v === 'Relief'
        ) {
          charitySegment = v.toLowerCase();
        } else if (v === 'Trending') {
          sort = ['income'];
        } else if (v === 'Top Earner') {
          sort = ['-revenue'];
        }
  
        this.subscriptions.add(
          this.charityService
            .getCharities(this.query, charitySegment, 0, 10, sort)
            .subscribe(charities => {
              this.charities = charities;
            }),
        );
      }
    }
  }

  onSelectCharity(charityId: string) {
    this.navController.navigateBack(['/user-role-tabs/charity/' + charityId], {
      queryParams: { fromDiscover: true }
    });
  }

  clearFilter() {
    this.selectedChip = '';
    this.startSearch = false;
  }

  searchCharities() {
    let charitySegment: string = '';
    let sort: string[] = [];
    if (
      this.selectedChip == 'Culture' ||
      this.selectedChip == 'Environment' ||
      this.selectedChip == 'Health' ||
      this.selectedChip == 'Religion' ||
      this.selectedChip == 'Social' ||
      this.selectedChip == 'STEM' ||
      this.selectedChip == 'Relief'
    ) {
      charitySegment = this.selectedChip.toLowerCase();
    } else if (this.selectedChip == 'Trending') {
      sort = ['income'];
    } else if (this.selectedChip == 'Top Earner') {
      sort = ['-revenue'];
    }

    this.subscriptions.add(
      this.charityService
        .getCharities(this.query, charitySegment, 0, 10, sort)
        .subscribe(charities => {
          this.charities = charities;
        }),
    );
  }

  handleSearch(event) {
    const query: string = event.target.value.toLowerCase();
    this.query = query;
    this.startSearch = query.length > 0;

    this.searchCharities();
  }

  goToNewsFeedPage() {
    this.navController.navigateForward(['/user-role-tabs/social']).then(r => r);
  }
}
