<div>
  <div class="avatar-container">
    <label class="button primary block" for="single">
      @if (_avatarUrl !== 'assets/ic-profile-avatar.svg') {
        <div id="image-container">
          <img [src]="_avatarUrl" alt="Avatar" class="img-avatar" />
        </div>
      }
      @if (_avatarUrl === 'assets/ic-profile-avatar.svg') {
        <div style="cursor: pointer">
          <img [src]="_avatarUrl" alt="Avatar" class="img-avatar" />
        </div>
      }
    </label>

    <input
      style="visibility: hidden; position: absolute"
      type="file"
      id="single"
      accept="image/*"
      (change)="selectImage($event)"
    />
  </div>
  @if (_avatarUrl !== 'assets/ic-profile-avatar.svg') {
    <ion-icon id="ic-plus" color="primary" name="add-circle"></ion-icon>
  }

  <ion-modal [isOpen]="isModalOpen">
    <ng-template>
      <div class="img-cropper-container">
        <ion-row>
          <ion-col size="2" class="ion-text-center">
            <ion-button id="btn-rotate-hover" fill="clear" (click)="rotate()">
              <ion-icon name="refresh" slot="start"></ion-icon>
            </ion-button>
            <ion-popover trigger="btn-rotate-hover" triggerAction="hover">
              <ng-template>Rotate</ng-template>
            </ion-popover>
          </ion-col>
          <ion-col size="2" class="ion-text-center">
            <ion-button fill="clear" (click)="flipHorizontal()">
              <ion-icon name="code-outline"></ion-icon>
            </ion-button>
          </ion-col>
          <ion-col size="2" class="ion-text-center">
            <ion-button fill="clear" (click)="flipVertical()">
              <ion-icon name="chevron-expand-outline"></ion-icon>
            </ion-button>
          </ion-col>
          <ion-col size="2" class="ion-text-center">
            <ion-button fill="clear" (click)="discardChanges()">
              <ion-icon name="close-circle-outline"></ion-icon>
            </ion-button>
          </ion-col>
          <ion-col size="2" class="ion-text-center">
            <ion-button fill="clear" (click)="cropImage()">
              <ion-icon name="checkmark-circle-outline"></ion-icon>
            </ion-button>
          </ion-col>
        </ion-row>

        <image-cropper
          #cropper
          [imageChangedEvent]="imageChangedEvent"
          [maintainAspectRatio]="true"
          [aspectRatio]="4 / 3"
          (imageCropped)="imageCropped($event)"
          (imageLoaded)="imageLoaded()"
          (loadImageFailed)="loadImageFailed()"
          format="jpeg"
          [transform]="transform"
          [autoCrop]="false"
          outputType="both"
        ></image-cropper>
      </div>
    </ng-template>
  </ion-modal>
</div>
