import { Component, inject, OnInit, ViewChild } from '@angular/core';
import { NavController } from '@ionic/angular/standalone';
import {
  FormBuilder,
  FormGroup,
  Validators,
  FormsModule,
  ReactiveFormsModule,
} from '@angular/forms';
import { FormValidators } from '../../core/utilities/form-validators';
import { AlertService } from 'src/app/core/services/navigation/alert.service';
import { addIcons } from 'ionicons';
import { eye, eyeOff } from 'ionicons/icons';
import {
  IonHeader,
  IonToolbar,
  IonIcon,
  IonContent,
  IonImg,
  IonItem,
  IonInput,
  IonButton,
} from '@ionic/angular/standalone';
import { AuthService } from 'src/app/core/services/auth/auth.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.page.html',
  styleUrls: ['./login.page.scss'],
  standalone: true,
  imports: [
    FormsModule,
    ReactiveFormsModule,
    IonHeader,
    IonToolbar,
    IonIcon,
    IonContent,
    IonImg,
    IonItem,
    IonInput,
    IonButton,
  ],
})
export class LoginPage implements OnInit {
  loginForm: FormGroup;
  showPassword = false;

  authService = inject(AuthService);
  @ViewChild('email') input!: IonInput;

  constructor(
    private fb: FormBuilder,
    private navController: NavController,
    private alertService: AlertService,
  ) {
    addIcons({ eye, eyeOff });
  }
  ionViewDidEnter() {
    this.input.setFocus();
  }
  ngOnInit() {
    this.loginForm = this.fb.group({
      email: ['', [Validators.required, FormValidators.email]],
      password: ['', [Validators.required, Validators.minLength(6)]],
    });
  }

  goToWelcomePage() {
    this.navController.navigateRoot('/welcome').then(r => r);
  }

  goToForgotPasswordPage() {
    this.navController.navigateForward(['/forgot-password']).then(r => r);
  }

  async onLogIn() {
    try {
      await this.authService.login({
        username: this.loginForm.value.email,
        password: this.loginForm.value.password,
      });
      this.navController.navigateRoot('/user-role-tabs').then(r => r);
    } catch {
      this.alertService.showAlertMessage(
        'Login failed',
        'Please check your credentials and try again!',
      );
    }
  }

  togglePasswordMode() {
    this.showPassword = !this.showPassword;
  }
}
