<ion-header class="ion-no-border">
  <ion-toolbar>
    <ion-icon
      class="ic-back"
      [src]="'/assets/ic-circle-back.svg'"
      (click)="goToLoginPage()"
    ></ion-icon>
  </ion-toolbar>
</ion-header>

<ion-content [fullscreen]="true">
  <div class="content" [hidden]="emailSent">
    <form [formGroup]="sendEmailForm" (ngSubmit)="onSendResetPasswordEmail()">
      <div class="title">Reset your password</div>
      <br />
      <div class="sub-title">
        Please enter the email address associated with your account. An email with instructions on
        how to reset your password will be sent.
      </div>
      <ion-item lines="none">
        <ion-input placeholder="email" formControlName="email" ngDefaultControl></ion-input>
      </ion-item>
      <br />

      <ion-button
        class="btn-send-email"
        type="submit"
        shape="round"
        expand="block"
        [disabled]="!sendEmailForm.valid"
      >
        Send Email
      </ion-button>
      <br />
    </form>
  </div>

  <div class="content" [hidden]="!emailSent">
    <div class="title">Check your email</div>
    <br />
    <div class="sub-title">
      We just sent you an email with password reset instructions to your email address:
    </div>
    <div class="email-value">{{sendEmailForm?.value.email}}</div>
    <br />

    <ion-button class="btn-done" shape="round" expand="block" (click)="goToLoginPage()">
      Done
    </ion-button>
    <br />

    <!--    TODO-->
    <!--    <a>Didn't receive an email?</a>-->
  </div>
</ion-content>
