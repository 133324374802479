import { Component, CUSTOM_ELEMENTS_SCHEMA, ElementRef, OnInit, ViewChild } from '@angular/core';
import { NavController } from '@ionic/angular/standalone';
import { FormBuilder, Validators, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { FormValidators } from '../../core/utilities/form-validators';
import { AlertService } from '../../core/services/navigation/alert.service';
import { addIcons } from 'ionicons';
import { eye, eyeOff } from 'ionicons/icons';
import {
  IonHeader,
  IonToolbar,
  IonIcon,
  IonContent,
  IonItem,
  IonInput,
  IonCheckbox,
  IonButton,
} from '@ionic/angular/standalone';
import { AuthService } from 'src/app/core/services/auth/auth.service';

@Component({
  selector: 'app-signup',
  templateUrl: './signup.page.html',
  styleUrls: ['./signup.page.scss'],
  standalone: true,
  imports: [
    FormsModule,
    ReactiveFormsModule,
    IonHeader,
    IonToolbar,
    IonIcon,
    IonContent,
    IonItem,
    IonInput,
    IonCheckbox,
    IonButton,
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class SignupPage implements OnInit {
  @ViewChild('formSwiper') swiperRef: ElementRef | undefined;
  @ViewChild('emailInput') emailInput: IonInput;
  showPassword = false;
  showTooltip: boolean;
  slideIndex: number = 0;

  isEmailAlreadyUsedValidator() {
    //
  }

  form = this.fb.group({
    first_name: ['', [Validators.required, Validators.minLength(2)]],
    last_name: ['', [Validators.required, Validators.minLength(2)]],
    email: [
      '',
      [Validators.required, Validators.email, FormValidators.email],
      this.isEmailAlreadyUsedValidator(),
    ],
    password: [
      '',
      [
        Validators.required,
        Validators.minLength(6),
        Validators.maxLength(40),
        FormValidators.oneLowercase,
        FormValidators.oneUppercase,
        FormValidators.oneDigit,
        FormValidators.oneSpecial,
      ],
    ],
    valid_age: [false, [Validators.requiredTrue]],
    terms_conditions: [false, Validators.requiredTrue],
  });

  constructor(
    private fb: FormBuilder,
    private navController: NavController,
    private alertService: AlertService,
    private authService: AuthService,
  ) {
    addIcons({ eye, eyeOff });
  }

  ionViewDidEnter() {
    this.emailInput.setFocus();
  }

  ngOnInit() {
    this.form.setValue({
      first_name: '',
      last_name: '',
      email: '',
      password: '',
      valid_age: false,
      terms_conditions: false,
    });
  }

  goToWelcomePage() {
    this.navController.navigateBack(['/']);
  }

  goToLoginPage() {
    this.navController.navigateBack(['/login']);
  }

  async onSignUp() {
    try {
      await this.authService.signUpNewUser(this.form.value.password, {
        email: this.form.value.email,
        firstName: this.form.value.first_name,
        lastName: this.form.value.last_name,
        avatarUrl: 'test',
      });

      this.alertService.showAlertMessage('Signup success', 'Welcome to Sunny Street!');
      this.goToLoginPage();
    } catch (error) {
      this.alertService.showAlertMessage('Registration failed, please try again!', error.message);
    }
  }

  next() {
    this.swiperRef.nativeElement.swiper.slideNext();
    this.slideIndex = this.swiperRef.nativeElement.swiper.activeIndex;
  }

  back() {
    this.swiperRef.nativeElement.swiper.slidePrev();
    this.slideIndex = this.swiperRef.nativeElement.swiper.activeIndex;
  }

  togglePasswordMode() {
    this.showPassword = !this.showPassword;
  }

  focus() {
    this.showTooltip = true;
  }

  focusOut() {
    this.showTooltip = false;
  }
}
