<ion-header class="ion-no-border">
  <ion-toolbar></ion-toolbar>
</ion-header>

<ion-content [fullscreen]="true">
  <ion-refresher slot="fixed" (ionRefresh)="handleRefresh($event)" />
  <div class="main-content">
    <!--  Main graphs/trend section-->
    <section>
      <div class="header-container">
        <h3>Giving</h3>
        <span class="btn-share" (click)="onShare()" (keydown)="onShare()" tabindex="{0}">
          <img src="assets/btn-share.svg" alt="share button" />
        </span>
      </div>

      <div class="stats-summary">
        <h1>
          @if (!isHourMode) { ${{totalMoneyDonation().toFixed(2)}} } @else {
          <span>{{totalHourDonation()}} hr</span>
          }
          <app-donate-mode-toggle [isHourMode]="isHourMode" (toggle)="onToggle()" />
        </h1>
        <div class="text-small">
          @if (!isHourMode) {
          <p id="dollar-text">
            <img src="assets/ic-polygon-green.svg" alt="icon" />
            ${{totalRangeMoneyDonation.toFixed(2)}}
          </p>
          } @else {
          <p id="hour-text">
            <img src="assets/ic-polygon-clay.svg" alt="icon" />
            {{totalRangeHourDonation}} hr
          </p>
          }
          <p>{{timeRangeText}}</p>
        </div>
      </div>

      <!--  Graph-->
      @defer (when dataIsLoaded) {
      <div>
        <app-giving-chart
          [donations]="getDonations()"
          [isHourMode]="isHourMode"
          (tabSelectedEmitter)="onChartTabSelected($event)"
        ></app-giving-chart>
      </div>
      }
    </section>

    <ion-item (click)="onOpenGivingGoalModal()" lines="none" button>
      <ion-label>
        <ion-text>Giving Goal</ion-text>
      </ion-label>
      <ion-text style="padding-right: 15px">{{donatedPercentage}}%</ion-text>
    </ion-item>

    <div class="line"></div>

    <ion-item (click)="onSelectTopCharity()" class="grid-header" lines="none" button>
      <ion-label>
        <ion-text><h4>Your Top Charity</h4></ion-text>
      </ion-label>
      <ion-text>See All</ion-text>
    </ion-item>
    <section class="top-section">
      <ion-grid>
        @defer (when topCharity) {
        <ion-row class="ion-justify-content-between">
          <ion-col size="auto">
            <ion-label class="ellipsis">{{topCharity.name | titlecase}}</ion-label>
            <br />
            <ion-note>{{topCharity.totalUserDonations.count}} Donations</ion-note>
          </ion-col>
          <ion-col size="auto">
            <div id="timeSeriesCharityMiniChartTopCharity"></div>
          </ion-col>
          <ion-col size="auto">
            <button>
              {{!isHourMode ? '$' + topCharity.totalUserDonations.amount :
              topCharity.totalUserDonations.amount + ' hr'}}
            </button>
          </ion-col>
        </ion-row>
        }
      </ion-grid>
    </section>

    <ion-item (click)="onSelectTopDonation()" class="grid-header" lines="none" button>
      <ion-label>
        <ion-text><h4>Top Donation</h4></ion-text>
      </ion-label>
      <ion-text>See All</ion-text>
    </ion-item>
    <section class="top-section">
      <ion-grid>
        @defer (when topDonation) {
        <ion-row class="ion-justify-content-between">
          <ion-col size="auto">
            <ion-label>{{topDonation.charityName | titlecase}}</ion-label>
            <br />
            <ion-note>{{topDonation.dateString}}</ion-note>
          </ion-col>
          <ion-col size="auto">
            <button>
              {{!isHourMode ? '$' + topDonation.amount.toFixed(2) : topDonation.amount + ' hr'}}
            </button>
          </ion-col>
        </ion-row>
        }
      </ion-grid>
    </section>

    <ion-item class="grid-header" lines="none">
      <ion-label>
        <ion-text>
          <h3>Charity Categories</h3>
        </ion-text>
      </ion-label>
    </ion-item>
    <ion-item lines="none">
      <div class="circles-container">
        @for (data of bubbleChartData; track data; let i = $index) {
        <div
          class="deg{{i+1}}"
          [ngClass]="selectedTab === data?.charitySegment ? 'btn-selected' : 'btn-not-selected'"
          (click)="setCurrentTab(data?.charitySegment)"
          (keydown)="setCurrentTab(data?.charitySegment)"
          tabindex="{0}"
          [style.width.px]="data?.percentage+90"
          [style.height.px]="data?.percentage+90"
        >
          <p class="graph-percentage">
            {{data?.percentage ? data?.percentage : 0}}%
            <br />
            <span class="graph-title">{{data?.charitySegment | titlecase}}</span>
          </p>
        </div>
        }
      </div>
    </ion-item>

    <!--  Charity Segments section-->
    <app-segment-chart
      [donationType]="isHourMode ? 'hour' : 'money'"
      [userId]="loggedInUser?.id"
    ></app-segment-chart>
  </div>
</ion-content>
