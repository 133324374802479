<ion-header class="ion-no-border">
  <ion-toolbar>
    <ion-button fill="clear" (click)="back()">
      <img src="assets/ic-back-clay.svg" alt="navigate back button" />
    </ion-button>
  </ion-toolbar>
</ion-header>

<ion-content [fullscreen]="true">
  <div class="container">
    <h3 class="header">Notifications</h3>

    <ion-list>
      <ion-item lines="none">test item</ion-item>
    </ion-list>
  </div>
</ion-content>
