export enum CharitySegment {
  CULTURE = 'CULTURE',
  ENVIRONMENT = 'ENVIRONMENT',
  HEALTH = 'HEALTH',
  SOCIAL = 'SOCIAL',
  RELIEF = 'RELIEF',
  STEM = 'STEM',
  RELIGION = 'RELIGION',
  MISC = 'MISC',
  NOT_APPLICABLE = 'NOT_APPLICABLE',
}

export enum CharityDetailedSegment {
  CULTURE = 'Arts, Education, and Culture',
  ENVIRONMENT = 'Environmental and Animal Protection',
  HEALTH = 'Heath, Wellness, and Research',
  SOCIAL = 'Social Services and Human Rights',
  RELIEF_1 = 'Disaster Preparedness and Community Support',
  RELIEF_2 = 'Global Affairs and Security',
  RELIEF_3 = 'Philanthropy and Volunteering',
  STEM = 'Science, Technology, and Innovation',
  RELIGION = 'Religious and Spiritual Development',
  MISC = 'Miscellaneous',
}
