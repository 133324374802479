import { Component, OnInit } from '@angular/core';
import {
  FormBuilder,
  FormGroup,
  Validators,
  FormsModule,
  ReactiveFormsModule,
} from '@angular/forms';
import { NavController } from '@ionic/angular/standalone';
import { AuthService } from '../../core/services/auth/auth.service';
import { FormValidators } from '../../core/utilities/form-validators';
import { Router } from '@angular/router';
import { AlertService } from '../../core/services/navigation/alert.service';
import { addIcons } from 'ionicons';
import { eye, eyeOff } from 'ionicons/icons';
import {
  IonHeader,
  IonToolbar,
  IonIcon,
  IonContent,
  IonItem,
  IonInput,
  IonButton,
} from '@ionic/angular/standalone';

@Component({
  selector: 'app-reset-password',
  templateUrl: './reset-password.page.html',
  styleUrls: ['./reset-password.page.scss'],
  standalone: true,
  imports: [
    FormsModule,
    ReactiveFormsModule,
    IonHeader,
    IonToolbar,
    IonIcon,
    IonContent,
    IonItem,
    IonInput,
    IonButton,
  ],
})
export class ResetPasswordPage implements OnInit {
  createNewPasswordForm: FormGroup;
  showPassword = false;
  showConfirmPassword = false;

  constructor(
    private fb: FormBuilder,
    private navController: NavController,
    private authService: AuthService,
    private alertService: AlertService,
    private router: Router,
  ) {
    addIcons({ eye, eyeOff });
  }

  ngOnInit() {
    this.createNewPasswordForm = this.fb.group({
      password: [
        '',
        [
          Validators.required,
          Validators.minLength(6),
          FormValidators.oneLowercase,
          FormValidators.oneUppercase,
          FormValidators.oneDigit,
          FormValidators.oneSpecial,
        ],
      ],
      confirm_password: [
        '',
        [
          Validators.required,
          Validators.minLength(6),
          FormValidators.oneLowercase,
          FormValidators.oneUppercase,
          FormValidators.oneDigit,
          FormValidators.oneSpecial,
        ],
      ],
    });
  }

  goToLoginPage() {
    this.navController.navigateBack(['/login']);
  }

  onCheckPasswords(input: string) {
    if (input === this.password.value) {
      this.confirmPassword.setValidators(null);
    } else {
      this.confirmPassword.setErrors({ incorrect: true });
    }
  }

  onResetPassword() {
    //
  }

  togglePasswordMode() {
    this.showPassword = !this.showPassword;
  }

  toggleConfirmPasswordMode() {
    this.showConfirmPassword = !this.showConfirmPassword;
  }

  get password() {
    return this.createNewPasswordForm.get('password');
  }
  get confirmPassword() {
    return this.createNewPasswordForm.get('confirm_password');
  }
}
