import { Injectable } from '@angular/core';
import { CharityClaim } from '../../models/CharityClaim';
import { Observable } from 'rxjs';
import { environment } from '../../../../environments/environment';
import { HttpClientWrapperService } from '../http/http-client-wrapper.service';

@Injectable({
  providedIn: 'root',
})
export class CharityManagementService {
  private readonly baseApiUrl = `${environment.beApiUrl}/api/v1/charity-claims`;

  constructor(private httpClient: HttpClientWrapperService) {}

  submitCharityClaim(claim: CharityClaim): Observable<CharityClaim> {
    return this.httpClient.post<CharityClaim, CharityClaim>(`${this.baseApiUrl}`, claim);
  }

  getCharityClaimsByUserId(userId: string): Observable<CharityClaim[]> {
    return this.httpClient.get<CharityClaim[]>(`${this.baseApiUrl}?userId=${userId}`);
  }

  getCharityClaim(id: string): Observable<CharityClaim> {
    return this.httpClient.get<CharityClaim>(`${this.baseApiUrl}/${id}`);
  }
}
