import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { Product } from 'src/app/core/models/Product';
import { Auction, AuctionData } from 'src/app/core/models/Auction';
import { HttpClientWrapperService } from '../http/http-client-wrapper.service';
import { Observable, forkJoin, switchMap, map, shareReplay } from 'rxjs';
import { CharityService } from '../charity/charity.service';
import { Bid } from '../../models/Bid';

@Injectable({
  providedIn: 'root',
})
export class CommerceService {
  private readonly productsApiUrl = `${environment.beApiUrl}/api/v1/products`;
  private readonly auctionsApiUrl = `${environment.beApiUrl}/api/v1/auctions`;
  private readonly bidsApiUrl = `${environment.beApiUrl}/api/v1/bids`;
  private allAuctions$: Observable<Auction[]> | null = null;

  constructor(private httpClient: HttpClientWrapperService, private charityService: CharityService) {}

  getAllMarketplaceProducts(): Observable<Product[]> {
    return this.httpClient.get<Product[]>(this.productsApiUrl).pipe(
      map(products => products.filter(product => product.acceptsBids === false))
    );
  }
  
  getProductsByAuctionId(auctionId: number): Observable<Product[]> {
    return this.httpClient.get<Product[]>(`${this.productsApiUrl}?auctionId=${auctionId.toString()}`);
  }
  
  getProductByProductId(productId: string): Observable<Product> {
    return this.httpClient.get<Product>(`${this.productsApiUrl}/${productId}`);
  }
  
  getAllAuctions(): Observable<Auction[]> {
    if (!this.allAuctions$) {
      this.allAuctions$ = this.httpClient.get<Auction[]>(this.auctionsApiUrl).pipe(
        shareReplay(1)
      );
    }
    return this.allAuctions$;
  }

  getAuctionByAuctionId(auctionId: string): Observable<Auction> {
    return this.httpClient.get<Auction>(`${this.auctionsApiUrl}/${auctionId}`);
  }

  loadAllAuctionData(): Observable<AuctionData[]> {
    return this.getAllAuctions().pipe(
      switchMap((auctions: Auction[]) =>
        forkJoin(
          auctions.map((auction: Auction) =>
            this.loadAuctionData(auction)
          )
        )
      )
    );
  }

  getAuctionsByCharityId(charityId: number): Observable<Auction[]> {
    return this.getAllAuctions().pipe(
      map((auctions) => auctions.filter((auction) => auction.charityId === charityId))
    );
  }

  loadAuctionDataByCharityId(charityId: number): Observable<AuctionData[]> {
    return this.getAuctionsByCharityId(charityId).pipe(
      switchMap((auctions) =>
        forkJoin(
          auctions.map((auction) => this.loadAuctionData(auction))
        )
      )
    );
  }
  
  private loadAuctionData(auction: Auction): Observable<AuctionData> {
    return forkJoin({
      products: this.getProductsByAuctionId(auction.id).pipe(
        map((products) =>
          Array.isArray(products)
            ? products.filter(product => product.acceptsBids === true)
            : []
        )
      ),
      charity: this.charityService.getCharity(auction.charityId.toString())
    }).pipe(
      map(({ products, charity }) => ({
        charity,
        products,
        auction
      }))
    );
  }

  getBidsByProductId(productId: string): Observable<Bid[]> {
    return this.httpClient.get<Bid[]>(`${this.bidsApiUrl}?productId=${productId}`);
  }

  placeBid(bid: Bid): Observable<Bid> {
    return this.httpClient.post<Bid, Bid>(`${this.bidsApiUrl}`, bid);
  }

  postProduct(formData: FormData): Observable<Product> {
    return this.httpClient.post<Product, FormData>(`${this.productsApiUrl}`, formData);
  }
}
