<ion-header class="ion-no-border">
  <ion-toolbar>
    <ion-button fill="clear" (click)="back()">
      <img src="../../../../../../assets/ic-back-clay.svg" alt="navigate back button" />
    </ion-button>
  </ion-toolbar>
</ion-header>

<ion-content [fullscreen]="true">
  <div class="container">
    <h3 class="header">Manage Charities</h3>

    @if (claims?.length < 1) {
      <p class="sub-header">You have no charities to manage.</p>
    }

    <!-- Show when there are claimed charity-->
    @if (claims?.length > 0) {
      <p class="sub-header">Select the charity you wish to mange below.</p>

      <!-- Claimed charity -->
      @for (item of claims; track claims) {
        @if (item.status === ClaimStatus.APPROVED) {
          <div>
            <ion-item [button]="true" (click)="goToCharityManagementPage(item?.id)">
              <div style="padding: 10px 10px 10px 0">
                <img
                  style="width: 50px; border-radius: 8px"
                  alt="Silhouette of mountains"
                  src="https://ionicframework.com/docs/img/demos/thumbnail.svg"
                />
              </div>

              <ion-label>
                <ion-text>{{ item?.charityName }}</ion-text>
                <br />
                <ion-note color="medium" class="ion-text-wrap">EIN: {{ item?.ein }}</ion-note>
              </ion-label>
            </ion-item>
          </div>
        }
      }

      <!-- Pending claims -->
      <h3 class="header">Pending Claims</h3>
      @for (item of claims; track claims) {
        @if (item.status !== ClaimStatus.APPROVED) {
          <div class="pending-claim">
            <ion-item>
              <div style="padding: 10px 10px 10px 0">
                <img
                  style="width: 50px; border-radius: 8px"
                  alt="Silhouette of mountains"
                  src="https://ionicframework.com/docs/img/demos/thumbnail.svg"
                />
              </div>

              <ion-label>
                <ion-text>{{ item?.charityName }}</ion-text>
                <br />
                <ion-note color="medium" class="ion-text-wrap">{{ item?.status }}</ion-note>
              </ion-label>
            </ion-item>
          </div>
        }
      }
    }

    <div class="btns">
      <button class="btn-claim" (click)="goToClaimCharity()">Claim A Charity</button>
    </div>
  </div>
</ion-content>
