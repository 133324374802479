<ion-header class="ion-no-border">
  <ion-toolbar>
    <ion-button fill="clear" (click)="back()">
      <img src="assets/ic-back-clay.svg" alt="navigate back button" />
    </ion-button>
  </ion-toolbar>
</ion-header>

<ion-content [fullscreen]="true">
  <div>
    <h3 class="header">Settings</h3>

    <ion-list>
      <ion-item [button]="true">
        <div class="unread-indicator-wrapper" slot="start"></div>
        <ion-label>
          <ion-text>Personal Information</ion-text>
          <br />
        </ion-label>
      </ion-item>

      <ion-item [button]="true">
        <div class="unread-indicator-wrapper" slot="start"></div>
        <ion-label>
          <ion-text>Notifications and Messages</ion-text>
          <br />
        </ion-label>
      </ion-item>
    </ion-list>
  </div>

  <div>
    <h3 class="header">App Preferences</h3>

    <ion-list>
      <ion-item [button]="true">
        <div class="unread-indicator-wrapper" slot="start"></div>
        <ion-label>
          <ion-text>App appearance</ion-text>
          <br />
        </ion-label>
      </ion-item>

      <ion-item [button]="true">
        <div class="unread-indicator-wrapper" slot="start"></div>
        <ion-label>
          <ion-text>Data usage</ion-text>
          <br />
        </ion-label>
      </ion-item>
    </ion-list>
  </div>

  <div class="btn-deactivate">
    <button (click)="deactivate()">Deactivate your account</button>
  </div>
</ion-content>
