import { Component, CUSTOM_ELEMENTS_SCHEMA, OnDestroy, OnInit } from '@angular/core';
import { NavController } from '@ionic/angular/standalone';
import { NgClass, TitleCasePipe, CommonModule } from '@angular/common';
import { Subject, takeUntil } from 'rxjs';
import {
  IonHeader,
  IonToolbar,
  IonButton,
  IonContent,
  IonSearchbar,
  IonRow,
  IonCol,
  IonSegment,
  IonSegmentButton,
  IonChip,
  IonGrid,
} from '@ionic/angular/standalone';
import { ActivatedRoute } from '@angular/router';
import { CommerceService } from 'src/app/core/services/commerce/commerce.service';
import { AuctionData } from 'src/app/core/models/Auction';
import { Product } from 'src/app/core/models/Product';

@Component({
  selector: 'app-charity-auction',
  templateUrl: './charity-auction.component.html',
  styleUrls: ['./charity-auction.component.scss'],
  standalone: true,
  imports: [
    TitleCasePipe,
    NgClass,
    IonHeader,
    IonToolbar,
    IonButton,
    IonContent,
    IonSearchbar,
    IonRow,
    IonCol,
    IonSegment,
    IonSegmentButton,
    IonChip,
    IonGrid,
    CommonModule
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class CharityAuctionComponent implements OnInit, OnDestroy {
  // Might bring this back for a future version
  // public ionChipTitles = ['All', 'Ending Soon', 'New', 'Favorite'];
  // selectedChip: string = 'All';

  charityId!: number;
  auctionsData: AuctionData[] = [];
  private destroy$ = new Subject<void>();
  charityName: string;

  constructor(
    private navController: NavController,
    private route: ActivatedRoute,
    private commerceService: CommerceService
  ) {}

  // Might bring back for future version
  // onFiltering(v: string) {
  //   this.selectedChip = v;
  // }

  ngOnInit() {
    const charityId: string = this.route.snapshot.paramMap.get('id')!;
    this.charityId = Number(charityId);
    this.loadAuctions();
  }

  loadAuctions() {
    this.commerceService.loadAuctionDataByCharityId(this.charityId).pipe(
      takeUntil(this.destroy$)
    ).subscribe({
      next: (filteredAuctions) => {
        this.auctionsData = filteredAuctions;
        this.charityName = filteredAuctions[0].charity.name;
      },
      error: (err) => {
        console.error('Failed to filter auctions:', err);
      }
    });
  }

  back() {
    this.navController.back();
  }

  onSelectItem(auctionData: AuctionData, product: Product) {
    const productId: string = product.id.toString();
    const charityId: string = auctionData.charity.id.toString();
    const auctionId: string = auctionData.auction.id.toString();
    this.navController.navigateForward(['/user-role-tabs/item-detail-auction/' + productId], {
      state: { charityId, auctionId }
    });
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }
}
