<ion-header class="ion-no-border">
  <ion-toolbar>
    <ion-button fill="clear" (click)="back()">
      <img src="assets/ic-back-clay.svg" alt="navigate back button" />
    </ion-button>
  </ion-toolbar>
</ion-header>

<ion-content [fullscreen]="true">
  <div class="main-content">
    <section>
      <h4>{{charityName}} Auctions</h4>
    </section>

    <section>

      <!-- Might bring back for a future version
      <div class="search-bar">
        <ion-searchbar></ion-searchbar>
        <img src="assets/ic-filter.svg" alt="filter icon" />
      </div>

      <ion-row>
        <ion-col size="12">
          <ion-segment mode="md" [scrollable]="true">
            @for (title of ionChipTitles; track title) {
            <ion-segment-button (click)="onFiltering(title)">
              <ion-chip [ngClass]="selectedChip === title ? 'selected-chip' : ''">
                {{ title }}
              </ion-chip>
            </ion-segment-button>
            }
          </ion-segment>
        </ion-col>
      </ion-row> -->

      <ion-grid>
        <div *ngFor="let auctionData of auctionsData">
          <div
            class="charity-header"
            tabindex="0"
          >
            <h3 class="charity-name">{{ auctionData.auction.description }}</h3>
            <div>
              <p>Ends {{ auctionData.auction.endDate | date: 'MM/dd/yyyy h:mm a' }}</p>
            </div>
            
          </div>
      
          <ion-row>
            <ion-col *ngFor="let product of auctionData.products" size="4">
              <div (click)="onSelectItem(auctionData, product)" tabindex="0" (keyup)="onSelectItem(auctionData, product)">
                <img [src]="product.imageUrl" alt="{{ product.name }}" />
                <div>
                  <h5>{{ product.name }}</h5>
                  <p>{{ auctionData.charity.name }}</p>
                </div>
              </div>
            </ion-col>
          </ion-row>
        </div>
      </ion-grid>
    </section>

    

    <!-- Bring back for next version
     <hr />
    <section>
      <h3>Previous Auctions</h3>
    </section> -->
  </div>
</ion-content>
