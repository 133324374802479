import { Injectable, inject } from '@angular/core';
import { Credentials } from 'src/app/core/models/Credentials';
import { environment } from 'src/environments/environment';
import { RefreshToken, Auth } from 'src/app/core/models/UserSession';
import { HttpClientWrapperService, Params } from '../http/http-client-wrapper.service';
import { BehaviorSubject, Observable } from 'rxjs';
import * as Sentry from '@sentry/angular';
import { User, SignUpUser } from '../../models/User';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  private readonly authApiUrl = `${environment.beApiUrl}/api/v1/auth`;
  private readonly userApiUrl = `${environment.beApiUrl}/api/v1/user-profile`;
  private httpClient = inject(HttpClientWrapperService);
  public userSession$: BehaviorSubject<string> = new BehaviorSubject<string>(null);
  private currentUser$: BehaviorSubject<User> = new BehaviorSubject<User>(null);
  authenticated = false;

  constructor() {
    this.initializeAuth();
  }

  private initializeAuth() {
    const accessToken: string = sessionStorage.getItem('accessToken');
    const username: string = sessionStorage.getItem('username');
    if (accessToken) {
      this.authenticated = true;
      this.userSession$.next(accessToken);
      this.getAuthUser(username);
    } else {
      this.logout();
    }
  }

  private async getAuthUser(username: string) {
    try {
      const usr: User = await this.httpClient.get<User>(`${this.userApiUrl}?email=${username}`).toPromise();
      this.authenticated = true;
      this.currentUser$.next(usr);
    } catch (error) {
      console.error('Error fetching user details:', error);
    }
  }

  get session$(): Observable<string> {
    return this.userSession$.asObservable();
  }

  get user$(): Observable<User> {
    return this.currentUser$.asObservable();
  }

  async login(credentials: Credentials): Promise<void> {
    try{
      const auth: Auth = await this.httpClient.postWithoutAuth<Auth, Credentials>(`${this.authApiUrl}/login`, credentials).toPromise();
      sessionStorage.setItem('accessToken', auth.accessToken);
      sessionStorage.setItem('username', credentials.username);
      const usr: User = await this.httpClient.get<User>(`${this.userApiUrl}?email=${credentials.username}`).toPromise();
      this.currentUser$.next(usr);
      Sentry.setUser({ email: credentials.username });
    } catch (error) {
      console.error('Login error:', error);
      throw new Error('Login failed. Please try again.');
    }
  }

  async logout(): Promise<void> {
    sessionStorage.removeItem('accessToken');
    sessionStorage.removeItem('username');
    this.authenticated = false;
    this.userSession$.next(null);
    this.currentUser$.next(null);
  }

  async changePassword(oldPassword: string, newPassword: string): Promise<void> {
    try {
      await this.httpClient.postWithoutAuth<RefreshToken, Params>(
        `${this.authApiUrl}/change-password`, 
        { oldPassword, newPassword }
      ).toPromise();
    } catch (err) {
      console.error("Error changing password:", err);
      throw err;
    }
  }

  async signUpNewUser(password: string, user: User): Promise<void> {
    try {
      await this.httpClient.postWithoutAuth<void, SignUpUser>(`${this.userApiUrl}/create-user`, {
        profile: user,
        password,
      }).toPromise();
    } catch (error) {
      console.error("Signup error:", error);
      throw new Error("Signup failed. Please try again.");
    }
  }

  changeProfilePic(id: number, image: File): Promise<User> {
    const formData: FormData = new FormData();
    formData.append('image', image);
  
    return this.httpClient.post<User, FormData>(`${this.userApiUrl}/${id}/upload-image`, formData).toPromise();
  }

  getUserAthority() {
    return this.httpClient.get<Auth>(`${this.authApiUrl}/me`);
  }

  refreshCurrentUser() {
    const username: string = sessionStorage.getItem('username');
    if (username) {
      this.getAuthUser(username);
    }
  }
}
