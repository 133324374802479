<ion-header class="ion-no-border">
  <ion-toolbar>
    <ion-button fill="clear" (click)="back()">
      <img src="assets/ic-back-clay.svg" alt="navigate back button" />
    </ion-button>
  </ion-toolbar>
</ion-header>

<ion-content [fullscreen]="true">
  <div class="container">
    <div>
      <h3 class="header">Transfer</h3>

      <ion-list>
        <ion-item [button]="true">
          <div class="unread-indicator-wrapper" slot="start"></div>
          <img alt="bank icon" src="assets/ic-bank.svg" />
          <ion-label>
            <ion-text>Transfer Money</ion-text>
            <br />
            <ion-note color="medium" class="ion-text-wrap">
              Deposit money to your Sunny Street account or withdraw to your bank.
            </ion-note>
          </ion-label>
        </ion-item>

        <ion-item [button]="true">
          <div class="unread-indicator-wrapper" slot="start"></div>
          <img alt="bank icon" src="assets/ic-double-arrows.svg" />
          <ion-label>
            <ion-text>Manage recurring deposits</ion-text>
            <br />
            <ion-note color="medium" class="ion-text-wrap">
              Schedule recurring, automatic deposits to your Sunny Street account.
            </ion-note>
          </ion-label>
        </ion-item>
      </ion-list>
    </div>

    <div>
      <h3 class="header">Linked account</h3>
      <ion-list>
        <ion-item>
          <div class="unread-indicator-wrapper" slot="start"></div>
          <img alt="bank icon" src="assets/ic-union.svg" />
          <ion-label>
            <ion-text>Checking</ion-text>
            <br />
            <ion-note color="medium" class="ion-text-wrap">Checking ****1232</ion-note>
          </ion-label>

          <ion-label slot="end">Verified</ion-label>
        </ion-item>

        <ion-item>
          <div class="unread-indicator-wrapper" slot="start"></div>
          <img alt="plus circle in gray color" src="assets/ic-plus-circle-gray.svg" />
          <ion-label>
            <ion-text (click)="addAccount()">Add Account</ion-text>
            <br />
          </ion-label>
        </ion-item>
      </ion-list>
    </div>

    <div>
      <h3 class="header">Completed transfers</h3>

      <ion-list>
        <ion-item [button]="true">
          <div class="unread-indicator-wrapper" slot="start"></div>
          <ion-label>
            <ion-text>Deposit from checking</ion-text>
            <br />
            <ion-note color="medium" class="ion-text-wrap">Mar 12, 2024</ion-note>
          </ion-label>

          <ion-label slot="end">+10.00</ion-label>
        </ion-item>

        <ion-item [button]="true">
          <div class="unread-indicator-wrapper" slot="start"></div>
          <ion-label>
            <ion-text>Deposit from checking</ion-text>
            <br />
            <ion-note color="medium" class="ion-text-wrap">Mar 14, 2024</ion-note>
          </ion-label>

          <ion-label slot="end">+13.00</ion-label>
        </ion-item>

        <div id="show-more">Show More</div>
      </ion-list>
    </div>
  </div>
</ion-content>
