<ion-header class="ion-no-border">
  <ion-toolbar>
    @if (slideIndex === 0) {
    <ion-icon
      class="ic-back"
      [src]="'/assets/ic-circle-back.svg'"
      (click)="goToWelcomePage()"
    ></ion-icon>
    } @if (slideIndex !== 0) {
    <ion-icon class="ic-back" [src]="'/assets/ic-circle-back.svg'" (click)="back()"></ion-icon>
    }
  </ion-toolbar>
</ion-header>

<ion-content [fullscreen]="true">
  <form [formGroup]="form">
    <swiper-container #formSwiper [allowTouchMove]="false">
      <swiper-slide>
        <div class="signup-section-title">
          <span>What's your email address?</span>
          <p>You'll use this email to log in.</p>
          <br />
        </div>
        <ion-item lines="none">
          <ion-input
            formControlName="email"
            placeholder="email"
            type="email"
            ngDefaultControl
            #emailInput
          ></ion-input>
        </ion-item>

        @if (form.controls['email'].hasError('emailalreadyused')) {
        <span class="error-text">
          This email has already been used, please choose a different one.
        </span>
        }
      </swiper-slide>

      <swiper-slide>
        <div class="signup-section-title">
          <span>Choose a Password</span>
          <p></p>
          <br />
        </div>
        @if (showTooltip) {
        <p class="tooltip-password-requirement">
          Must be minimum of 8 characters long
          <br />
          Must contain at least 1 special character
          <br />
          Must contain at least 1 number
          <br />
          Must contain at least 1 uppercase letter
          <br />
          Must contain at least 1 lowercase letter
          <br />
        </p>
        }
        <ion-item lines="none">
          <ion-input
            formControlName="password"
            placeholder="password"
            [type]="showPassword ? 'text' : 'password'"
            (ionFocus)="focus()"
            (ionBlur)="focusOut()"
            ngDefaultControl
          ></ion-input>
          <ion-icon
            [name]="showPassword ? 'eye' : 'eye-off'"
            class="m-auto"
            (click)="togglePasswordMode()"
          ></ion-icon>
        </ion-item>
      </swiper-slide>

      <swiper-slide>
        <div class="signup-section-title">
          <span>What's your legal name?</span>
          <p>
            Enter your first and last name as
            <br />
            they appear on your Government ID.
          </p>
          <br />
        </div>
        <ion-item lines="none">
          <ion-input
            formControlName="first_name"
            placeholder="first name"
            type="text"
            ngDefaultControl
          ></ion-input>
        </ion-item>
        <ion-item lines="none">
          <ion-input
            formControlName="last_name"
            placeholder="last name"
            type="text"
            ngDefaultControl
          ></ion-input>
        </ion-item>

        <div class="checkboxes">
          <ion-checkbox formControlName="valid_age" label-placement="end">
            I am 13 years of age or older
          </ion-checkbox>
          <br />
          <ion-checkbox formControlName="terms_conditions" label-placement="end">
            I agree to the
            <span>Terms and Conditions</span>
          </ion-checkbox>
        </div>
      </swiper-slide>
    </swiper-container>

    @if (slideIndex === 0) {
    <ion-button
      class="btn-continue-1"
      (click)="next()"
      shape="round"
      expand="block"
      [disabled]="!form.controls['email'].valid"
    >
      Continue
    </ion-button>
    } @if (slideIndex === 1) {
    <ion-button
      class="btn-continue-2"
      (click)="next()"
      shape="round"
      expand="block"
      [disabled]="!form.controls['password'].valid"
    >
      Continue
    </ion-button>
    } @if (slideIndex === 2) {
    <ion-button
      class="btn-signup"
      type="submit"
      shape="round"
      expand="block"
      (click)="onSignUp()"
      [disabled]="!form.valid"
    >
      Sign Up
    </ion-button>
    }
  </form>
</ion-content>
