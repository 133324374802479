<ion-header class="ion-no-border">
  <ion-toolbar>
    <div class="btn-close" (click)="cancel()" (keydown)="cancel()" tabindex="{0}">
      <img src="assets/ic-close-clay.svg" alt="close icon button" />
    </div>
  </ion-toolbar>
</ion-header>

<ion-content class="ion-padding">
  <div class="logo">
    <img src="assets/logo-big.svg" alt="sunny street logo" />
  </div>

  <div class="text-container">
    <h1>Invite a friend!</h1>
    <p>
      Invite your friends and multiply our impact! Together, we can make a bigger difference in the
      world.
    </p>
  </div>

  <div>
    <button (click)="onInvite()" class="btn-invite">Invite Contacts</button>
    <button (click)="onShare()" class="btn-share">Share Link</button>
  </div>
</ion-content>
