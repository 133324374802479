<ion-header class="ion-no-border custom-header">
  @if (!isMainPage) {
    <ion-toolbar>
      <ion-button fill="clear" (click)="back()">
        <img src="../../../../assets/ic-back-clay.svg" alt="navigate back button" />
      </ion-button>
    </ion-toolbar>
  }

  @if (isMainPage) {
    <ion-toolbar>
      <ion-buttons slot="start">
        <!--        @if (currentTab === 'charity-info') {-->
        <!--          <p class="btn-back" (click)="back()">-->
        <!--            <img src="../../../../assets/ic-back.svg" alt="back icon">-->
        <!--          </p>-->
        <!--        }-->
        <p class="ss-logo">Sunny Street</p>
      </ion-buttons>
      <ion-buttons slot="end">
        <ion-button (click)="goToSearchPage()" id="btn-ic-search" fill="clear">
          <ion-icon name="search-outline"></ion-icon>
        </ion-button>
        <ion-button (click)="goToNotificationsPage()" id="btn-ic-notification" fill="clear">
          <ion-icon name="notifications"></ion-icon>
        </ion-button>
        <ion-button (click)="goToMenuPage()" id="btn-ic-menu" fill="clear">
          <ion-icon name="menu"></ion-icon>
        </ion-button>
      </ion-buttons>
    </ion-toolbar>
  }
</ion-header>

<ion-content [fullscreen]="true" force-overscroll="true">
  <ion-refresher slot="fixed" [pullFactor]="0.5" [pullMin]="100" [pullMax]="200">
    <ion-refresher-content></ion-refresher-content>
  </ion-refresher>

  <ion-tabs (ionTabsDidChange)="setCurrentTab($event)">
    <ion-tab-bar slot="bottom">
      <ion-tab-button tab="charity-info">
        <ion-icon
          [src]="
            currentTab === 'charity-info' ? '/assets/ic-trend.svg' : '/assets/ic-trend-outline.svg'
          "
        ></ion-icon>
      </ion-tab-button>

      <!-- <ion-tab-button tab="charity-event">
        <ion-icon
          [src]="
            currentTab === 'charity-event'
              ? '/assets/ic-calendar.svg'
              : '/assets/ic-calendar-outline.svg'
          "
        ></ion-icon>
      </ion-tab-button> -->

      <ion-tab-button tab="charity-commerce">
        <ion-icon
          [src]="
            currentTab === 'charity-commerce' ? '/assets/ic-bag.svg' : '/assets/ic-bag-outline.svg'
          "
        ></ion-icon>
      </ion-tab-button>

      <ion-tab-button tab="charity-newsfeed">
        <ion-icon
          [src]="
            currentTab === 'charity-newsfeed'
              ? '/assets/ic-feed.svg'
              : '/assets/ic-feed-outline.svg'
          "
        ></ion-icon>
      </ion-tab-button>

      <!-- <ion-tab-button tab="charity-profile">
        <ion-icon
          [src]="
            currentTab === 'charity-profile'
              ? '/assets/ic-person.svg'
              : '/assets/ic-person-outline.svg'
          "
        ></ion-icon>
      </ion-tab-button> -->
    </ion-tab-bar>
  </ion-tabs>
</ion-content>
