import { Component, CUSTOM_ELEMENTS_SCHEMA, OnDestroy, OnInit } from '@angular/core';
import { NgClass, TitleCasePipe, CommonModule } from '@angular/common';
import { Subscription } from 'rxjs';
import {
  IonHeader,
  IonToolbar,
  IonContent,
  IonSearchbar,
  IonRow,
  IonCol,
  IonSegment,
  IonSegmentButton,
  IonChip,
  IonGrid,
  NavController,
  ModalController,
} from '@ionic/angular/standalone';
import { CommerceService } from '../../../core/services/commerce/commerce.service';
import { Product } from '../../../core/models/Product';
import { ProductModalComponent } from '../product-modal/product-modal.component';
import { AuthService } from 'src/app/core/services/auth/auth.service';
import { User } from 'src/app/core/models/User';
import { CharityManagementService } from 'src/app/core/services/charity/charity-management.service';

@Component({
  selector: 'app-charity-marketplace',
  templateUrl: './charity-marketplace.component.html',
  styleUrls: ['./charity-marketplace.component.scss'],
  standalone: true,
  imports: [
    TitleCasePipe,
    NgClass,
    IonHeader,
    IonToolbar,
    IonContent,
    IonSearchbar,
    IonRow,
    IonCol,
    IonSegment,
    IonSegmentButton,
    IonChip,
    IonGrid,
    CommonModule
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class CharityMarketplaceComponent implements OnDestroy, OnInit {
  private readonly subscriptions = new Subscription();
  products: Product[] = [];
  public ionChipTitles = ['All', 'Ending Soon', 'New', 'Favorite'];
  selectedChip: string = 'All';
  private subscription: Subscription = new Subscription();
  loggedInUser: User;
  charityId: string;

  constructor(
    private commerceService: CommerceService, 
    private navController: NavController,
    private modalCtrl: ModalController,
    private authService: AuthService,
    private charityManagementService: CharityManagementService
  ) {}

  onFiltering(v: string) {
    this.selectedChip = v;
  }

  ngOnInit() {
    this.getLoggedInUser();
  }

  getLoggedInUser() {
    this.subscriptions.add(
      this.authService.user$.subscribe(user => {
        if (user) {
          this.loggedInUser = user;
          this.getCharityId(user.id);
        }
      })
    )
  }

  getCharityId(userId: string) {
    this.charityManagementService.getCharityClaimsByUserId(userId).subscribe(
      (data) => {
        this.charityId = data[0].charityId;
        this.loadProducts(this.charityId);
      }
    )
  }

  loadProducts(charityId: string) {
    const id: number = Number(charityId);
    const sub: Subscription = this.commerceService.getAllMarketplaceProducts().subscribe({
      next: (products) => {
        this.products = products.filter(product => product.charityId === id);
      },
      error: (err) => {
        console.error('Failed to fetch products:', err);
      }
    });
    this.subscription.add(sub);
  }

  onSelectItem(product: Product) {
    const id: string = product.id.toString();
    this.navController.navigateForward('/user-role-tabs/item-detail/'+id, {
      state: { product }
    });
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

  newItem() {
    this.modalCtrl
      .create({
        component: ProductModalComponent,
      })
      .then(modal => modal.present());
  }
  
}
