import { inject } from '@angular/core';
import { CanActivateFn } from '@angular/router';
import { NavController } from '@ionic/angular/standalone';

export const authGuard: CanActivateFn = async (
): Promise<boolean> => {
  const navController: NavController = inject(NavController);

  const token: string = sessionStorage.getItem('accessToken');
  if (token) {
    return true;
  }

  navController.navigateRoot(['/welcome']);
  return false;
};
