<ion-header class="ion-no-border">
  <ion-toolbar>
    <ion-button fill="clear" (click)="back()">
      <img src="assets/ic-back-clay.svg" alt="navigate back button" />
    </ion-button>
  </ion-toolbar>
</ion-header>

<ion-content [fullscreen]="true">
  <div>
    <h3 class="header">Sunny Street Support</h3>

    <div class="search-bar">
      <ion-searchbar></ion-searchbar>
    </div>

    <ion-list>
      <ion-item [button]="true">
        <div class="unread-indicator-wrapper" slot="start"></div>
        <ion-label>
          <ion-text>Help Center</ion-text>
          <br />
        </ion-label>
      </ion-item>

      <ion-item [button]="true">
        <div class="unread-indicator-wrapper" slot="start"></div>
        <ion-label>
          <ion-text>FAQ</ion-text>
          <br />
        </ion-label>
      </ion-item>
    </ion-list>
  </div>

  <div>
    <h3 class="header">Disclosures</h3>

    <ion-list>
      <ion-item [button]="true">
        <div class="unread-indicator-wrapper" slot="start"></div>
        <ion-label>
          <ion-text>Sunny Street Terms</ion-text>
          <br />
        </ion-label>
      </ion-item>

      <ion-item [button]="true">
        <div class="unread-indicator-wrapper" slot="start"></div>
        <ion-label>
          <ion-text>Privacy</ion-text>
          <br />
        </ion-label>
      </ion-item>
    </ion-list>
  </div>

  <div id="app-version">Version v2024.1.0</div>
</ion-content>
