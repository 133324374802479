<ion-header class="ion-no-border">
  <ion-toolbar></ion-toolbar>
</ion-header>

<ion-content [fullscreen]="true">
  <div class="main-content">
    <section>
      <h4>Your Marketplace Item</h4>
      <p>These are the items you have available on the marketplace</p>
    </section>

    <section>
      <!-- Might bring back in later version -->
      <!-- <div class="search-bar">
        <ion-searchbar></ion-searchbar>
        <img src="assets/ic-filter.svg" alt="filter icon" />
      </div> -->
      <!-- <ion-row>
        <ion-col size="12">
          <ion-segment mode="md" scrollable="true">
            @for (title of ionChipTitles; track title) {
            <ion-segment-button (click)="onFiltering(title)">
              <ion-chip [ngClass]="selectedChip === title ? 'selected-chip' : ''">
                {{ title }}
              </ion-chip>
            </ion-segment-button>
            }
          </ion-segment>
        </ion-col>
      </ion-row> -->

      <div class="btn-new-item">
        <button (click)="newItem()">Add New Item</button>
      </div>

      <ion-grid>
        <ion-row>
          <ion-col *ngFor="let product of products" size="4" (click)="onSelectItem(product)">
            <div>
              <img [src]="product.imageUrl" alt="{{ product.name }}" />
              <div>
                <h5>{{ product.name }}</h5>
                <p>${{ product.currentPrice }}</p>
              </div>
            </div>
          </ion-col>
        </ion-row>
      </ion-grid>
    </section>
  </div>
</ion-content>
