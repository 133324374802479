<div class="container">
  <div (click)="cancel()" (keydown)="cancel()" tabindex="{0}">
    <img src="assets/ic-back.svg" alt="back arrow icon" />
  </div>

  <p>
    Track your progress towards your giving goals.
    <br />
    Set your goals for this year:
  </p>

  <form [formGroup]="form">
    <ion-item lines="none">
      <ion-label>Monetary Support:</ion-label>
      <div (click)="decrementMoney()" (keydown)="decrementMoney()" tabindex="{0}">
        <img src="assets/ic-minus-gray.svg" alt="icon minus" />
      </div>
      <ion-input
        class="input"
        [(ngModel)]="form.value.moneyGoal"
        formControlName="moneyGoal"
        type="number"
      ></ion-input>
      <div (click)="incrementMoney()" (keydown)="incrementMoney()" tabindex="{0}">
        <img src="assets/ic-plus-gray.svg" alt="icon plus" />
      </div>
    </ion-item>

    <ion-item lines="none">
      <ion-label>Volunteer Hours:</ion-label>
      <div (click)="decrementHour()" (keydown)="decrementHour()" tabindex="{0}">
        <img src="assets/ic-minus-gray.svg" alt="icon minus" />
      </div>
      <ion-input
        class="input"
        [(ngModel)]="form.value.hourGoal"
        formControlName="hourGoal"
        type="number"
      ></ion-input>
      <div (click)="incrementHour()" (keydown)="incrementHour()" tabindex="{0}">
        <img src="assets/ic-plus-gray.svg" alt="icon plus" />
      </div>
    </ion-item>

    <button (click)="submit()">Submit</button>
  </form>

  <div class="progress-bar">
    <ion-progress-bar [value]="completedPercentageMoneyGoal"></ion-progress-bar>
  </div>
</div>
