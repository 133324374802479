<!--DONATION FAILED VIEW-->
@if (donationStatus === 'open' && !isViewInvoice) {
  <ion-content class="ion-padding">
    <div class="confirmation-header">
      <h3>Donation Failed To Complete.</h3>
      <p>
        Your donation of
        <span>$</span>
        {{ currentDonation?.amount }} is NOT completed. Please try again or contact us via Customer
        Support for assistance.
      </p>
    </div>
    <div class="confirm-btns">
      <button class="btn-done" (click)="done()">Done</button>
    </div>
  </ion-content>
}

<!--DONATION COMPLETED VIEW-->
@if (donationStatus === 'complete' && !isViewInvoice) {
  <ion-content class="ion-padding">
    <div class="confirmation-header">
      <h3>Donation Completed</h3>
      <p>
        Your donation of
        <span>$</span>
        {{ currentDonation?.amount }} is complete.
      </p>
    </div>
    <div>
      <ion-item>
        <ion-label style="font-weight: 600">Today's Donation</ion-label>
        <ion-text style="font-weight: 600">
          <span>$</span>
          {{ todaysMoneyDonation }}
        </ion-text>
      </ion-item>
      <ion-item>
        <ion-label>Average Donation</ion-label>
        <ion-text>
          <span>$</span>
          {{ averageMoneyDonation }}
        </ion-text>
      </ion-item>
      <ion-item>
        <ion-label>Total Donation</ion-label>
        <ion-text>
          <span>$</span>
          {{ totalMoneyDonation }}
        </ion-text>
      </ion-item>
    </div>
    <div class="confirm-btns">
      <button class="btn-done" (click)="done()">Done</button>
      <button class="btn-view" (click)="viewDonationInvoice()">View Donation</button>
    </div>
  </ion-content>
}

<!--INVOICE VIEW-->
@if (donationStatus === 'complete' && isViewInvoice) {
  <ion-content
    class="ion-padding"
    style="--ion-background-color: #ffffff; --ion-text-color: #393d39"
  >
    <div class="header-section">
      <h4>Sunny Street Donation</h4>
      <h6>{{ currentDonation?.id }}</h6>
    </div>
    <div id="line"></div>
    <div class="donation-details">
      <div>
        <h5>Donation Status</h5>
        <p>Filled</p>
      </div>
      <div>
        <h5>Submitted</h5>
        <p>{{ currentDonation?.timestamp | date: "MMM dd, yyyy 'at' hh:mm a" }}</p>
      </div>
      <div>
        <h5>Entered Amount</h5>
        <p>
          <span>$</span>
          {{ currentDonation?.amount }}
        </p>
      </div>
      <div>
        <h5>Fill Date</h5>
        <p>{{ currentDonation?.timestamp | date: "MMM dd, yyyy 'at' hh:mm a" }}</p>
      </div>
      <div
        id="btn-download"
        (click)="downloadDonationReceipt()"
        (keydown)="downloadDonationReceipt()"
        tabindex="1"
      >
        Download Receipt
      </div>
    </div>
    <button class="btn-done" (click)="done()">Done</button>
    <ion-text>Thank you for your generous contribution!</ion-text>
    <br>
    <ion-text>
      Your support for this charity and your trust in Sunny Street are truly appreciated. Your donation plays a vital role in helping us achieve our mission to provide charities with the funding they need to make a meaningful difference in their causes.
    </ion-text>
    <br>
    <ion-text>Thank you for being a part of the Sunny Street mission!</ion-text>
    <br>
    <ion-text>With gratitude,</ion-text>
    <br>
    <ion-text><em>The Sunny Street Team</em></ion-text>
  </ion-content>
}
