<ion-header class="ion-no-border">
  <ion-toolbar>
    <ion-button fill="clear" (click)="back()">
      <img src="assets/ic-back-clay.svg" alt="navigate back button" />
    </ion-button>
  </ion-toolbar>
</ion-header>

<ion-content [fullscreen]="true">
  <!--  @if(!accountId) {-->
  <div class="container">
    <h3 class="header">Linked accounts</h3>

    <ion-list>
      <ion-item>
        <div class="unread-indicator-wrapper" slot="start"></div>
        <img alt="bank icon" src="assets/ic-union.svg" />
        <ion-label>
          <ion-text>Checking</ion-text>
          <br />
          <ion-note color="medium" class="ion-text-wrap">Checking ****1232</ion-note>
        </ion-label>

        <ion-label slot="end">Verified</ion-label>
      </ion-item>

      <ion-item>
        <div class="unread-indicator-wrapper" slot="start"></div>
        <img alt="plus circle in gray color" src="assets/ic-plus-circle-gray.svg" />
        <ion-label>
          <ion-text (click)="createStripeAccount()">Add Stripe Account</ion-text>
          <br />
          <ion-note>Enroll in Stripe to receive donations</ion-note>
        </ion-label>
      </ion-item>
    </ion-list>
  </div>
</ion-content>
