import { Component, Input, OnInit } from '@angular/core';
import { NavController } from '@ionic/angular/standalone';
import {
  FormBuilder,
  FormGroup,
  FormsModule,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';
import { NgClass } from '@angular/common';
import { CharityManagementService } from '../../../../../core/services/charity/charity-management.service';
import { CharityClaim } from '../../../../../core/models/CharityClaim';
import { ClaimStatus } from '../../../../../core/constants/ClaimStatus';
import { FormValidators } from '../../../../../core/utilities/form-validators';
import { AlertService } from '../../../../../core/services/navigation/alert.service';
import { Charity } from '../../../../../core/models/Charity';
import { IonContent, IonItem, IonInput } from '@ionic/angular/standalone';

@Component({
  selector: 'app-claim-charity',
  templateUrl: './claim-charity.component.html',
  styleUrls: ['./claim-charity.component.scss'],
  standalone: true,
  imports: [FormsModule, ReactiveFormsModule, NgClass, IonContent, IonItem, IonInput],
})
export class ClaimCharityComponent implements OnInit {
  @Input() userId: string;
  @Input() charity: Charity;

  form: FormGroup;
  uploadedDocs = [];

  constructor(
    private fb: FormBuilder,
    private navController: NavController,
    private manageCharityService: CharityManagementService,
    private alertService: AlertService,
  ) {}

  ngOnInit() {
    this.form = this.fb.group({
      charity_name: [this.charity.name, [Validators.required, Validators.minLength(3)]],
      ein: [this.charity.ein, [Validators.required, Validators.minLength(9)]],
      contact: ['', [Validators.required, Validators.minLength(2)]],
      email: ['', [Validators.required]],
      street: ['', [Validators.required, Validators.minLength(2)]],
      city: ['', [Validators.required, Validators.minLength(2)]],
      state: ['', [Validators.required, Validators.minLength(2)]],
      zip: ['', [Validators.required, Validators.minLength(5), Validators.maxLength(5)]],
      website: ['', [Validators.required, FormValidators.website]],
      documents: ['', [Validators.required]],
    });
  }

  onUploadFiles(e) {
    this.uploadedDocs = e.target.files;
  }

  submit() {
    const claim: CharityClaim = {
      charityName: this.charity.name,
      ein: this.charity.ein,
      contact: this.form.value.contact,
      email: this.form.value.email,
      street: this.form.value.street,
      city: this.form.value.city,
      state: this.form.value.state,
      zip: this.form.value.zip,
      website: this.form.value.website,
      userId: this.userId,
      status: ClaimStatus.PENDING,
    };

    this.manageCharityService.submitCharityClaim(claim).subscribe(
      () => {
        this.alertService.showAlertMessage(
          'Claim Submitted',
          'Please allow up to 19 business days to hear back from our team.',
        );
        this.form.reset();
        this.navController.back();
      },
      err => {
        console.error(err);
        this.alertService.showAlertMessage(
          'Failed to Submit Claim',
          'Sorry for the inconvenience. Please try again or contact us for support.',
        );
      },
    );
  }
}
