import { Component, OnDestroy, OnInit, signal } from '@angular/core';
import { Subscription } from 'rxjs';
import { GivingGoalModalComponent } from '../giving-goal-modal/giving-goal-modal.component';
import { SegmentChartComponent } from '../segment-chart/segment-chart.component';
import { GivingChartComponent } from './giving-chart/giving-chart.component';
import { Chart } from '../../../core/constants/Chart';
import { GivingGoal } from '../../../core/models/GivingGoal';
import { User } from '../../../core/models/User';
import { GivingGoalsService } from '../../../core/services/giving-goals/giving-goals.service';
import { TimeSeriesChartService } from '../../../core/services/graph/time-series-chart.service';
import { Donation } from '../../../core/models/Donation';
import { AuthService } from '../../../core/services/auth/auth.service';
import { CharityDataService } from '../../../core/services/graph/charity-data.service';
import { CharityService } from 'src/app/core/services/charity/charity.service';
import { DonateModeToggleComponent } from 'src/app/shared/components/donate-mode-toggle/donate-mode-toggle.component';
import { CharitySegment } from '../../../core/constants/CharitySegment';
import { GivingSegment } from '../../../core/models/GivingSegment';
import { NgClass, TitleCasePipe } from '@angular/common';
import { Charity } from '../../../core/models/Charity';
import {
  ModalController,
  AlertController,
  NavController,
  IonRefresher,
  IonHeader,
  IonToolbar,
  IonContent,
  IonItem,
  IonLabel,
  IonText,
  IonGrid,
  IonNote,
  IonCol,
  IonRow,
} from '@ionic/angular/standalone';
import { BubbleChartService } from 'src/app/core/services/graph/bubble-chart.service';
import { addIcons } from 'ionicons';
import * as icons from 'ionicons/icons';

interface TopCharity extends Charity {
  totalUserDonations?: { count: number; amount: number };
}
interface TopDonation extends Donation {
  charityName?: string;
  dateString?: string;
}

@Component({
  selector: 'app-home',
  templateUrl: './home.page.html',
  styleUrls: ['./home.page.scss'],
  standalone: true,
  imports: [
    IonRefresher,
    GivingChartComponent,
    SegmentChartComponent,
    IonHeader,
    IonToolbar,
    IonContent,
    IonItem,
    IonLabel,
    IonText,
    IonGrid,
    IonNote,
    IonCol,
    IonRow,
    DonateModeToggleComponent,
    NgClass,
    TitleCasePipe,
  ],
})
export class HomePage implements OnInit, OnDestroy {
  private readonly subscriptions = new Subscription();
  timeRangeText: string = Chart.ALL_TEXT;

  moneyDonations: Donation[] = [];
  hourDonations: Donation[] = [];

  totalMoneyDonation = signal(0);
  totalHourDonation = signal(0);
  totalRangeMoneyDonation = 0;
  totalRangeHourDonation = 0;

  dataIsLoaded = false;
  isHourMode = false;
  loggedInUser: User;
  givingGoal: GivingGoal;
  donatedPercentage = '0';

  followedCharityList: Charity[] = [];
  showButtonText = 'Show More';
  showCount = 4;
  topCharity: TopCharity;
  topDonation: TopDonation;

  noChartData: Donation[] = [
    {
      timestamp: 1710518453216,
      amount: 0,
    },
    {
      timestamp: 1710524642486,
      amount: 0,
    },
  ];

  totalDonatedAmountArray = [];
  numberOfDonationsArray = [];

  public tabTitles = [
    CharitySegment.CULTURE,
    CharitySegment.ENVIRONMENT,
    CharitySegment.HEALTH,
    CharitySegment.SOCIAL,
    CharitySegment.RELIEF,
    CharitySegment.RELIGION,
    CharitySegment.STEM,
  ];

  public lineChartData: GivingSegment[] = [];
  public bubbleChartData: GivingSegment[] = [];
  selectedTab = CharitySegment.CULTURE;
  totalGiving = 0;

  chartInitialData: GivingSegment[] = [
    { sum: 0, charitySegment: CharitySegment.CULTURE, percentage: 0 },
    { sum: 0, charitySegment: CharitySegment.ENVIRONMENT, percentage: 0 },
    { sum: 0, charitySegment: CharitySegment.SOCIAL, percentage: 0 },
    { sum: 0, charitySegment: CharitySegment.RELIEF, percentage: 0 },
    { sum: 0, charitySegment: CharitySegment.RELIGION, percentage: 0 },
    { sum: 0, charitySegment: CharitySegment.STEM, percentage: 0 },
    { sum: 0, charitySegment: CharitySegment.HEALTH, percentage: 0 },
  ];

  constructor(
    private charityDataService: CharityDataService,
    private charityService: CharityService,
    private givingGoalsService: GivingGoalsService,
    private navController: NavController,
    private modalController: ModalController,
    private authService: AuthService,
    private alertController: AlertController,
    private bubbleChartService: BubbleChartService,
    private timeSeriesChart: TimeSeriesChartService,
  ) {
    addIcons({ ...icons });
  }

  async ngOnInit() {
    await this.getLoggedInUser();
    if (this.bubbleChartData.length < 1) {
      this.bubbleChartData = this.chartInitialData;
    }
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  onToggle() {
    this.isHourMode = !this.isHourMode;
    this.getTopCharity();
    this.getTopDonation();
  }

  getDonations() {
    return !this.isHourMode ? this.moneyDonations : this.hourDonations;
  }

  onOpenGivingGoalModal() {
    this.modalController
      .create({
        component: GivingGoalModalComponent,
        componentProps: {
          totalDonatedMoney: this.totalMoneyDonation(),
          totalDonatedHour: this.totalHourDonation(),
          givingGoal: this.givingGoal,
        },
        cssClass: 'giving-goal-modal',
      })
      .then(modal => {
        modal.present();

        modal.onDidDismiss().then(() => {
          this.getGivingGoal();
        });
      });
  }

  getLoggedInUser() {
    this.subscriptions.add(
      this.authService.user$.subscribe(user => {
        if (user) {
          this.loggedInUser = user;
          this.getGivingChartData(user?.id, 'all');
          this.getBubbleChartData(user.id);
        }
      }),
    );
  }

  getGivingChartData(userId: string, tab: string) {
    const numOfDays: number = TimeSeriesChartService.convertStringTabToNumOfDays(tab);

    this.subscriptions.add(
      this.charityDataService.getDonationsByTimeRangeByUserId(userId, numOfDays).subscribe(res => {
        if (res) {
          // Get total money donated
          this.moneyDonations = res.filter(donation => donation.donationType == 'MONEY');
          this.totalMoneyDonation.set(this.moneyDonations.reduce((a, b) => a + b.amount, 0));
          this.totalRangeMoneyDonation = this.totalMoneyDonation();

          // Get total hours donated
          this.hourDonations = res.filter(donation => donation.donationType == 'HOUR');
          this.totalHourDonation.set(this.hourDonations.reduce((a, b) => a + b.amount, 0));
          this.totalRangeHourDonation = this.totalHourDonation();

          this.dataIsLoaded = true;
          this.getGivingGoal();
          this.getTopCharity();
          this.getTopDonation();
        }
      }),
    );
  }

  getGivingGoal() {
    this.subscriptions.add(
      this.givingGoalsService.givingGoal$.subscribe(goal => {
        if (goal) {
          this.givingGoal = goal;
          this.donatedPercentage = ((this.totalMoneyDonation() / goal.money_goal) * 100).toFixed(0);
        }
      }),
    );
  }

  getTopCharity() {
    const charities: object = {};
    const donations: Donation[] = !this.isHourMode ? this.moneyDonations : this.hourDonations;
    donations.map(donation => {
      if (charities[donation.charityId] !== undefined) {
        charities[donation.charityId] += donation.amount;
      } else {
        Object.assign(charities, donation.charityId);
        charities[donation.charityId] = donation.amount;
      }
    });
    const topCharityId: string = Object.keys(charities).reduce((a, b) =>
      charities[a] > charities[b] ? a : b,
    );
    const charityDonations: Donation[] = donations.filter(
      donation => donation.charityId == topCharityId,
    );

    this.subscriptions.add(
      this.charityService.getCharity(topCharityId).subscribe(charity => {
        this.topCharity = {
          ...charity,
          totalUserDonations: { count: charityDonations.length, amount: charities[charity.id] },
        };
        setTimeout(() => {
          this.renderChart('TopCharity', charityDonations);
        });
      }),
    );
  }

  getTopDonation() {
    const donations: Donation[] = !this.isHourMode ? this.moneyDonations : this.hourDonations;
    const topDonationIndex: string = Object.keys(donations).reduce((a, b) =>
      donations[a].amount > donations[b].amount ? a : b,
    );
    const topDonation: Donation = donations[topDonationIndex];
    this.subscriptions.add(
      this.charityService.getCharity(topDonation.charityId).subscribe(charity => {
        const date: Date = new Date(topDonation.timestamp);
        this.topDonation = {
          ...topDonation,
          charityName: charity?.name,
          dateString: date.toDateString(),
        };
      }),
    );
  }

  onShare(): void {
    if (navigator.share) {
      navigator
        .share({
          title: 'Check out the Sunny Street app!',
          text: 'I found this great app. You should check it out!',
          url: 'https://sunnystreet.com/',
        })
        .catch(error => {
          console.error('Error sharing', error);
          this.showFallbackShareOption();
        });
    } else {
      this.showFallbackShareOption();
    }
  }

  showFallbackShareOption(): void {
    const shareUrl: string = 'https://sunnystreet.com/';
    navigator.clipboard.writeText(shareUrl).then(() => {
      this.alertController
        .create({
          header: 'Link copied to clipboard',
          buttons: ['OK'],
          cssClass: 'alert-message',
        })
        .then((alert: HTMLIonAlertElement) => {
          alert.present();
        })
        .catch(err => {
          console.error('Failed to copy link to clipboard:', err);
          this.alertController
            .create({
              header: 'Failed to copy the link.',
              buttons: ['OK'],
              cssClass: 'alert-error',
            })
            .then((alert: HTMLIonAlertElement) => {
              alert.present();
            });
        });
    });
  }

  onChartTabSelected(e: string) {
    this.getGivingChartData(this.loggedInUser.id, e);

    switch (true) {
      case e == 'today':
        this.timeRangeText = Chart.TODAY_TEXT;
        break;
      case e == '1w':
        this.timeRangeText = Chart.ONE_WEEK_TEXT;
        break;
      case e == '1m':
        this.timeRangeText = Chart.ONE_MONTH_TEXT;
        break;
      case e == '3m':
        this.timeRangeText = Chart.THREE_MONTH_TEXT;
        break;
      case e == '6m':
        this.timeRangeText = Chart.SIX_MONTH_TEXT;
        break;
      case e == '1y':
        this.timeRangeText = Chart.ONE_YEAR_TEXT;
        break;
      case e == 'all':
        this.timeRangeText = Chart.ALL_TEXT;
        break;
    }
  }

  goToDiscoverPage() {
    this.navController.navigateForward(['/user-role-tabs/discover']).then(r => r);
  }

  handleRefresh(event: CustomEvent) {
    this.getLoggedInUser();
    this.getGivingChartData(this.loggedInUser.id, 'today');
    event.detail.complete();
  }

  getBubbleChartData(id: string) {
    this.subscriptions.add(
      this.bubbleChartService.getGivingSegments(id).subscribe(data => {
        if (data) {
          let filteredData: GivingSegment[] = data.filter(
            d =>
              d.charitySegment != CharitySegment.MISC &&
              d.charitySegment != CharitySegment.NOT_APPLICABLE,
          );

          this.totalGiving = filteredData?.reduce((a, b) => a + b.sum, 0);
          filteredData.map(d => {
            d.percentage = Math.round((d.sum / this.totalGiving) * 100);
          });

          // Fill in bubble that doesn't have data so that it show 0% percent on the chart
          filteredData = this.chartInitialData.map(
            d => filteredData.find(s => s.charitySegment === d.charitySegment) || d,
          );

          const sortedData: GivingSegment[] = filteredData?.sort(
            (a, b) => b?.percentage - a?.percentage,
          );
          this.bubbleChartData = this.arrayMove(sortedData, 0, 3);
        }
      }),
    );
  }

  // Helper function
  arrayMove(arr: GivingSegment[], fromIndex: number, toIndex: number) {
    const newArr: GivingSegment[] = arr;
    const element: GivingSegment = arr[fromIndex];
    newArr.splice(fromIndex, 1);
    newArr.splice(toIndex, 0, element);
    return newArr;
  }

  setCurrentTab(tab: CharitySegment) {
    this.selectedTab = tab;
  }

  renderChart(uniqueId: string, data: Donation[]) {
    if (data.length <= 1) {
      data = this.noChartData;
    }
    this.timeSeriesChart.renderMiniPersonalGivingChart(
      Chart.CHARITY_MINI_CHART_HTML_ID + uniqueId,
      data,
    );
  }

  onSelectCharity(charityId: string) {
    this.navController.navigateForward(['/user-role-tabs/charity/' + charityId]);
  }

  onSelectTopCharity() {
    this.navController.navigateForward(['/user-role-tabs/giving']);
  }

  onSelectTopDonation() {
    this.navController.navigateForward(['/user-role-tabs/history']);
  }
}
