import { Component, OnInit } from '@angular/core';
import { SharedModule } from '../../../shared/shared.module';
import { IonHeader, IonToolbar, IonContent } from '@ionic/angular/standalone';

@Component({
  selector: 'app-charity-profile',
  templateUrl: './charity-profile.page.html',
  styleUrls: ['./charity-profile.page.scss'],
  standalone: true,
  imports: [SharedModule, IonHeader, IonToolbar, IonContent],
})
export class CharityProfilePage implements OnInit {
  constructor() {}

  // eslint-disable-next-line @angular-eslint/no-empty-lifecycle-method
  ngOnInit() {}
}
