import { Component, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular/standalone';
import { IonHeader, IonToolbar, IonContent } from '@ionic/angular/standalone';

@Component({
  selector: 'app-invite-friend-modal',
  templateUrl: './invite-friend-modal.component.html',
  styleUrls: ['./invite-friend-modal.component.scss'],
  standalone: true,
  imports: [IonHeader, IonToolbar, IonContent],
})
export class InviteFriendModalComponent implements OnInit {
  constructor(private modalCtrl: ModalController) {}

  // eslint-disable-next-line @angular-eslint/no-empty-lifecycle-method
  ngOnInit() {}

  cancel() {
    return this.modalCtrl.dismiss(null, 'cancel');
  }

  onInvite() {}

  onShare() {}
}
