import { Component, Input, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular/standalone';
import { SharedModule } from '../../../shared.module';
import { StripePaymentService } from '../../../../core/services/payment/stripe-payment.service';
import { StripeConnectInstance } from '@stripe/connect-js';

@Component({
  selector: 'app-onboarding',
  templateUrl: './onboarding.component.html',
  styleUrls: ['./onboarding.component.scss'],
  standalone: true,
  imports: [SharedModule],
})
export class OnboardingComponent implements OnInit {
  @Input() connectAccountId: string;

  constructor(
    private modalCtrl: ModalController,
    private stripeService: StripePaymentService
  ) {}

  ngOnInit() {
    this.onBoarding();
  }

  cancel() {
    this.modalCtrl.dismiss(null, 'cancel');
  }

  onBoarding() {
    const instance: StripeConnectInstance = this.stripeService.initializeStripeConnectInstance(
      this.connectAccountId,
    );

    const container: HTMLElement = document.getElementById('container');

    /* eslint-disable  @typescript-eslint/typedef */
    const accountOnboarding = instance.create('account-onboarding');
    accountOnboarding.setOnExit(() => {});
    container.appendChild(accountOnboarding);
  }
}
