<ion-header class="ion-no-border">
  <ion-toolbar>
    <ion-button fill="clear" (click)="back()">
      <img src="assets/ic-back-clay.svg" alt="navigate back button" />
    </ion-button>
  </ion-toolbar>
</ion-header>

<ion-content [fullscreen]="true" class="ion-padding">
  <div class="container">
    <div class="search-bar">
      <ion-searchbar></ion-searchbar>
    </div>

    <ion-list>
      <ion-item lines="none">test item</ion-item>
      <ion-item lines="none">test item</ion-item>
      <ion-item lines="none">test item</ion-item>
      <ion-item lines="none">test item</ion-item>
      <ion-item lines="none">test item</ion-item>
      <ion-item lines="none">test item</ion-item>
    </ion-list>
  </div>
</ion-content>
