<ion-header class="ion-no-border">
  <ion-toolbar>
    <ion-icon
      class="ic-back"
      [src]="'/assets/ic-circle-back.svg'"
      (click)="goToLoginPage()"
    ></ion-icon>
  </ion-toolbar>
</ion-header>

<ion-content [fullscreen]="true">
  <form [formGroup]="createNewPasswordForm" (ngSubmit)="onResetPassword()">
    <div class="title">Change your password</div>
    <br />
    <div class="sub-title">
      Your new password must meet these requirements:

      <p class="tooltip-password-requirement">
        Must be minimum of 8 characters long
        <br />
        Must contain at least 1 special character
        <br />
        Must contain at least 1 number
        <br />
        Must contain at least 1 uppercase letter
        <br />
        Must contain at least 1 lowercase letter
        <br />
      </p>
    </div>

    <ion-item lines="none">
      <ion-input
        placeholder="new password"
        formControlName="password"
        [type]="showPassword ? 'text' : 'password'"
        ngDefaultControl
      ></ion-input>
      <ion-icon
        [name]="showPassword ? 'eye' : 'eye-off'"
        class="m-auto"
        (click)="togglePasswordMode()"
      ></ion-icon>
    </ion-item>
    <br />

    <ion-item lines="none">
      <ion-input
        class="confirm-password-input"
        placeholder="confirm new password"
        formControlName="confirm_password"
        [type]="showConfirmPassword ? 'text' : 'password'"
        (keyup)="onCheckPasswords(confirmPassword.value)"
        ngDefaultControl
      ></ion-input>
      <ion-icon
        [name]="showConfirmPassword ? 'eye' : 'eye-off'"
        class="m-auto"
        (click)="toggleConfirmPasswordMode()"
      ></ion-icon>
    </ion-item>
    <br />

    <ion-button
      class="btn-reset-password"
      type="submit"
      shape="round"
      expand="block"
      [disabled]="!createNewPasswordForm.valid"
    >
      Reset Password
    </ion-button>
    <br />
  </form>
</ion-content>
