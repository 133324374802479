<ion-header class="ion-no-border">
  <ion-toolbar></ion-toolbar>
</ion-header>

<ion-content [fullscreen]="true">
  <div class="main-content">
    <section>
      <h4>Donation History</h4>
      @defer (when dataIsLoaded) { @if (donations.length < 1) {
      <div>Please go to Explore Charities to make a donation.</div>
      } @defer (when donations.length > 0) {
      <ion-grid>
        @for (d of donations; track d.id; let i = $index) {
        <div>
          <div>
            <ion-row class="ion-justify-content-between">
              <ion-col size="auto">
                <ion-label>{{d.charityName | titlecase}}</ion-label>
                <br />
                <ion-note>{{d.dateString}}</ion-note>
              </ion-col>
              <ion-col size="auto">
                <button>
                  {{ d?.donationType === 'MONEY' ? '$' + d.amount.toFixed(2) : d.amount + ' hr' }}
                </button>
              </ion-col>
            </ion-row>
          </div>
        </div>
        }
      </ion-grid>
      } }
    </section>
  </div>
</ion-content>
