import { Component } from '@angular/core';
import { NavController } from '@ionic/angular/standalone';
import {
  IonHeader,
  IonToolbar,
  IonButton,
  IonContent,
  IonList,
  IonItem,
  IonThumbnail,
  IonLabel,
  IonText,
} from '@ionic/angular/standalone';

@Component({
  selector: 'app-charity-account',
  templateUrl: './charity-account.component.html',
  styleUrls: ['./charity-account.component.scss'],
  standalone: true,
  imports: [
    IonHeader,
    IonToolbar,
    IonButton,
    IonContent,
    IonList,
    IonItem,
    IonThumbnail,
    IonLabel,
    IonText,
  ],
})
export class CharityAccountComponent {
  constructor(private navController: NavController) {}

  back() {
    this.navController.back();
  }

  goToPersonalAccount() {
    this.navController.navigateBack(['/user-role-tabs']);
  }
}
