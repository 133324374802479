<ion-header class="ion-no-border">
  <ion-toolbar class="donation-toolbar">
    <div class="top-buttons">
      <ion-buttons>
        <ion-button color="medium" (click)="cancel()">
          <img src="../../../../../assets/ic-close.svg" alt="close button" />
        </ion-button>
      </ion-buttons>
    </div>
  </ion-toolbar>
</ion-header>

<ion-content class="ion-padding">
  <div class="main-content">
    @if (!accountId) {
      <p>
        Failed to create a new Stripe account, please try again or contact us via customer support!
      </p>
    }
    @if (accountId) {
      <div>
        <app-onboarding [connectAccountId]="accountId"></app-onboarding>
      </div>
    }
  </div>
</ion-content>
