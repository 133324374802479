<ion-header class="ion-no-border">
  <ion-toolbar></ion-toolbar>
</ion-header>

<ion-content [fullscreen]="true">
  <div class="main-content">
    <section>
      <h4>Silent Auctions</h4>
      <p>Shop and support your favorite charities</p>
    </section>

    <section>
      <div class="search-bar">
        <ion-searchbar></ion-searchbar>
        <img src="assets/ic-filter.svg" alt="filter icon" />
      </div>

      <ion-row>
        <ion-col size="12">
          <ion-segment mode="md" [scrollable]="true">
            @for (title of ionChipTitles; track title) {
            <ion-segment-button (click)="onFiltering(title)">
              <ion-chip [ngClass]="selectedChip === title ? 'selected-chip' : ''">
                {{ title }}
              </ion-chip>
            </ion-segment-button>
            }
          </ion-segment>
        </ion-col>
      </ion-row>

      <ion-grid>
        <div *ngFor="let auctionData of auctionsData">
          <div
            class="charity-header"
            (click)="goToCharityAuction(auctionData.charity.id)"
            (keyup)="goToCharityAuction(auctionData.charity.id)"
            tabindex="0"
          >
            <h3 class="charity-name">{{ auctionData.charity.name }}</h3>
            <img src="assets/ic-arrow.svg" alt="" />
          </div>
      
          <ion-row>
            <ion-col *ngFor="let product of auctionData.products" size="4">
              <div 
                (click)="onSelectItem(auctionData, product)"
                (keyup)="onSelectItem(auctionData, product)"
                tabindex="0"
              >
                <img [src]="product.imageUrl" alt="{{ product.name }}" />
                <div>
                  <h5>{{ product.name }}</h5>
                  <p>{{ auctionData.charity.name }}</p>
                </div>
              </div>
            </ion-col>
          </ion-row>
        </div>
      </ion-grid>
    </section>

    <section></section>
  </div>
</ion-content>
