import { Component } from '@angular/core';
import { ModalController, NavController } from '@ionic/angular/standalone';
import { StripePaymentService } from '../../../../core/services/payment/stripe-payment.service';
import { OnboardingComponent } from '../../stripe-embedded-components/onboarding/onboarding.component';
import { ConnectAccountComponent } from '../../stripe-embedded-components/connect-account/connect-account.component';
import {
  IonHeader,
  IonToolbar,
  IonButton,
  IonContent,
  IonList,
  IonItem,
  IonLabel,
  IonText,
  IonNote,
} from '@ionic/angular/standalone';

@Component({
  selector: 'app-charity-transfers',
  templateUrl: './charity-transfers.component.html',
  styleUrls: ['./charity-transfers.component.scss'],
  standalone: true,
  imports: [
    OnboardingComponent,
    IonHeader,
    IonToolbar,
    IonButton,
    IonContent,
    IonList,
    IonItem,
    IonLabel,
    IonText,
    IonNote,
  ],
})
export class CharityTransfersComponent {
  accountId: string;

  constructor(
    private navController: NavController,
    private stripeService: StripePaymentService,
    private modalCtrl: ModalController,
  ) {}

  getUserInfo() {
    // if user has an account id then show the onboarding if not completed or the account management page, if not then show create account
  }

  createStripeAccount() {
    this.stripeService.createConnectAccount().subscribe(accountId => {
      this.accountId = accountId;
      this.modalCtrl
        .create({
          component: ConnectAccountComponent,
          componentProps: {
            accountId: this.accountId,
          },
        })
        .then(modal => {
          modal.prepend();
        });
    });
  }

  back() {
    this.navController.back();
  }
}
