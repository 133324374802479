<ion-header class="ion-no-border">
  <ion-toolbar></ion-toolbar>
</ion-header>

<ion-content [fullscreen]="true">
  <div class="main-content">
    @if (isNewsBtnSelected) {
    <app-news-feed></app-news-feed>
    } @if (!isNewsBtnSelected) {
    <app-community-feed></app-community-feed>
    }
  </div>

  <section class="btn-section">
    <button
      [ngClass]="!isNewsBtnSelected ? 'btn-community-selected' : 'btn-community'"
      (click)="onClickCommunityBtn()"
    >
      Community
    </button>
    <button
      [ngClass]="isNewsBtnSelected ? 'btn-news-selected' : 'btn-news'"
      (click)="onClickNewsBtn()"
    >
      News
    </button>
  </section>
</ion-content>
