import { Injectable } from '@angular/core';
import * as Plotly from 'plotly.js-dist';
import {
  Chart,
  getEmptyBigChartTrace,
  getEmptyMiniFriendChartTrace,
  getEmptyMiniPersonalChartTrace,
  getLayoutForBigChart,
  getLayoutForMiniChart,
  getPlotlyConfig,
} from '../../constants/Chart';
import { Donation } from '../../models/Donation';
import { Observable } from 'rxjs';

const shortMonths: Array<string> = [
  'Jan',
  'Feb',
  'Mar',
  'Apr',
  'May',
  'Jun',
  'Jul',
  'Aug',
  'Sept',
  'Oct',
  'Nov',
  'Dec',
];

interface PlotlyElement extends HTMLElement {
  _fullLayout?: {
    xaxis: { range: number | string; p2c: (x: number) => string };
    yaxis: { range: number | string };
    margin: { l: number };
    annotations: [{ xshift: number }];
  };
}

@Injectable({
  providedIn: 'root',
})
export class TimeSeriesChartService {
  constructor() {}

  renderBigLineChart(chartHtmlId: string, donations: Donation[], isHourMode: boolean) {
    return new Observable(observer => {
      let cumulativeAmount: number = 0;
      const xValues: string[] = [];
      const yValues: number[] = [];
      const layout: Partial<Plotly> = getLayoutForBigChart();

      donations.map(donation => {
        xValues.push(this.getDateStrForChart(donation.timestamp));
        cumulativeAmount += donation?.amount || 0;
        yValues.push(cumulativeAmount);
      });

      let trace: Partial<Plotly> = getEmptyBigChartTrace(isHourMode);
      trace = { ...trace, x: xValues, y: yValues };

      this.setDonationsTooltips(donations, trace, isHourMode);

      Plotly.react(chartHtmlId, [trace], layout, getPlotlyConfig());
      observer.next();
      observer.complete();
    });
  }

  renderVLine(
    chartEl: PlotlyElement,
    show: boolean,
    center?: { x: number; y: number },
    donations?: Donation[],
    isHourMode?: boolean,
  ) {
    const donationsFound: boolean = Array.isArray(donations) && donations.length > 0;

    let layout: object = {
      'shapes[0].visible': show && donationsFound,
      'annotations[0].visible': show && donationsFound,
    };

    if (show && donationsFound && center !== undefined) {
      const { xaxis, yaxis, margin, annotations } = chartEl._fullLayout;
      const { left: chartLeft, right: chartRight } = chartEl.getBoundingClientRect();
      const ymax: number = donations.reduce((a, b) => a + b.amount, 0);

      const selectedTimestamp: number = Number(xaxis.p2c(center.x - margin.l));
      const selectedDate: Date = new Date(selectedTimestamp);
      const [month, day, year] = [
        selectedDate.getMonth(),
        selectedDate.getDate(),
        selectedDate.getFullYear(),
      ];
      let annotationStr: string = `${shortMonths[month]} ${day}, ${year}<br>`;
      const vLinePos: string = this.getDateStrForChart(selectedTimestamp);

      let selectedDonation: number = 0;
      donations.map(donation => {
        const dDate: Date = new Date(donation.timestamp);
        if (dDate.toDateString() == selectedDate.toDateString()) {
          selectedDonation += donation.amount;
        }
      });
      annotationStr += !isHourMode ? `$${selectedDonation.toFixed(2)}` : `${selectedDonation} hr`;

      const annotationEl: object = document.getElementsByClassName('annotation');
      let { left, right } = annotationEl[0]?.getBoundingClientRect() || { undefined };
      left = Math.floor(left);
      right = Math.ceil(right);

      let { xshift } = annotations[0];
      const xshiftL: number | boolean = left <= chartLeft ? chartLeft - left : false;
      const xshiftR: number | boolean = right >= chartRight ? chartRight - right : false;

      if (xshiftL === false && xshiftR === false) {
        xshift = 0;
      } else if (xshiftL) {
        xshift = xshiftL;
      } else if (xshiftR) {
        xshift = xshiftR;
      }

      const vLineLayout: object = {
        'xaxis.range': xaxis.range,
        'yaxis.range': yaxis.range,
        'shapes[0].x0': vLinePos,
        'shapes[0].x1': vLinePos,
        'shapes[0].y0': yaxis.range[0],
        'shapes[0].y1': ymax,
        'annotations[0].x': vLinePos,
        'annotations[0].y': ymax,
        'annotations[0].text': annotationStr,
        'annotations[0].xshift': xshift,
      };
      layout = { ...layout, ...vLineLayout };
    }
    return Plotly.relayout(chartEl, layout);
  }

  setDonationsTooltips(donations: Donation[], trace: Partial<Plotly>, isHourMode: boolean) {
    trace['hovertemplate'] = donations.map(d =>
      d ? this.getTooltip(d.timestamp, d.amount, !isHourMode ? 'dollar' : 'hr') : null,
    );
    return;
  }

  getTooltip(time: number, value: number, typeOfValue: string) {
    const date: Date = new Date(time);
    const dateStr: string = `${date.getMonth() + 1}/${date.getDate()}/${date.getFullYear()}`;

    const dateTimeStyle: string = `style="color: #ffffff; font-size: 10px;"`;
    const donationValueStyle: string = 'style="color: #ffffff; font-size: 12px"';

    if (typeOfValue === 'dollar') {
      return `<span style="text-align: center"><span ${dateTimeStyle}>${dateStr}</span><br>
<span ${donationValueStyle}><b>$${value}</b></span></span>
<extra></extra>`;
    }
    return `<span style="text-align: center"><span ${dateTimeStyle}>${dateStr}</span><br>
<span ${donationValueStyle}><b>${value} hr</b></span></span>
<extra></extra>`;
  }

  // Mini charts
  renderMiniPersonalGivingChart(chartHtmlId: string, data: Donation[]) {
    const xValues: number[] = [];
    const yValues: number[] = [];

    for (const d of data) {
      xValues.push(d.timestamp);
      yValues.push(d.amount);
    }

    const trace: Partial<Plotly> = getEmptyMiniPersonalChartTrace();
    trace.x = xValues;
    trace.y = yValues;

    Plotly.react(chartHtmlId, [trace], getLayoutForMiniChart(), getPlotlyConfig());
  }

  renderMiniFriendGivingChart(chartHtmlId: string, data: Donation[]) {
    const xValues: number[] = [];
    const yValues: number[] = [];

    for (const d of data) {
      xValues.push(d.timestamp);
      yValues.push(d.amount);
    }

    const trace: Partial<Plotly> = getEmptyMiniFriendChartTrace();
    trace.x = xValues;
    trace.y = yValues;

    Plotly.newPlot(chartHtmlId, [trace], getLayoutForMiniChart(), getPlotlyConfig());
  }

  getDateStrForChart(timestamp: number): string {
    const date: Date = new Date(timestamp);
    const [month, day, year, hours, minutes, seconds] = [
      date.getMonth() + 1,
      date.getDate(),
      date.getFullYear(),
      date.getHours(),
      date.getMinutes(),
      date.getSeconds(),
    ];

    // prettier-ignore
    return `${year}-${month}-${day} ${
        ('0' + hours).slice(-2)}:${
        ('0' + minutes).slice(-2)}:${
        ('0' + seconds).slice(-2)}`;
  }

  static convertStringTabToNumOfDays(tab: string): number {
    switch (true) {
      case tab == 'today':
        return Chart.TODAY;
      case tab == '1w':
        return Chart.ONE_WEEK;
      case tab == '1m':
        return Chart.ONE_MONTH;
      case tab == '3m':
        return Chart.THREE_MONTH;
      case tab == '6m':
        return Chart.SIX_MONTH;
      case tab == '1y':
        return Chart.ONE_YEAR;
      default:
        return Chart.ALL;
    }
  }
}
