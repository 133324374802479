import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular/standalone';
import { NgClass, DatePipe, TitleCasePipe } from '@angular/common';
import { CheckoutComponent } from '../../../shared/components/stripe-embedded-components/checkout/checkout.component';
import { AlertService } from '../../../core/services/navigation/alert.service';
import { Charity } from '../../../core/models/Charity';
import { Donation } from '../../../core/models/Donation';
import { CharityDataService } from '../../../core/services/graph/charity-data.service';
import { DonateModeToggleComponent } from 'src/app/shared/components/donate-mode-toggle/donate-mode-toggle.component';
import { Subscription } from 'rxjs';
import {
  IonHeader,
  IonToolbar,
  IonButtons,
  IonButton,
  IonContent,
  IonGrid,
  IonRow,
  IonCol,
  IonItem,
  IonLabel,
  IonText,
  IonCheckbox,
} from '@ionic/angular/standalone';
import { StripePaymentService } from 'src/app/core/services/payment/stripe-payment.service';

@Component({
  selector: 'app-donation-modal',
  templateUrl: './donation-modal.component.html',
  styleUrls: ['./donation-modal.component.scss'],
  standalone: true,
  imports: [
    NgClass,
    DatePipe,
    TitleCasePipe,
    CheckoutComponent,
    IonHeader,
    IonToolbar,
    IonButtons,
    IonButton,
    IonContent,
    IonGrid,
    IonRow,
    IonCol,
    IonItem,
    IonLabel,
    IonText,
    IonCheckbox,
    DonateModeToggleComponent,
  ],
})
export class DonationModalComponent implements OnInit, OnDestroy {
  private readonly subscriptions = new Subscription();

  @Input() selectedCharity: Charity;
  @Input() userId: string;
  @Input() moneyDonationHistory: Donation[];
  @Input() hourDonationHistory: Donation[];

  accountId = 'acct_1PVcbkDBVigCH5BR';

  isHourMode = false;

  isConfirmation = false;
  isComplete = false;
  isViewInvoice = false;
  isCheckout = false;

  donationValue = '';
  newDonation: Donation;
  serviceFee = 0;

  todaysMoneyDonation = 0;
  averageMoneyDonation = 1;
  totalMoneyDonation = 0;

  constructor(
    private modalCtrl: ModalController,
    private alertService: AlertService,
    private charityDataService: CharityDataService,
    private stripeService: StripePaymentService
  ) {}

  ngOnInit(): void {
    this.calculateMoneyDonationStats();
  }

  ngOnDestroy() {
    this.subscriptions.unsubscribe();
  }

  calculateMoneyDonationStats() {
    this.totalMoneyDonation = 0;
    this.todaysMoneyDonation = 0;

    const currentDate: number = Date.now();
    const secondsOfOneDay: number = 24 * 60 * 60 * 1000;
    const endDate: number = currentDate - secondsOfOneDay;

    this.moneyDonationHistory?.forEach(md => {
      if (endDate < md.timestamp && md.timestamp < currentDate) {
        this.todaysMoneyDonation = Number((this.todaysMoneyDonation + md.amount).toFixed(2));
      }

      this.totalMoneyDonation = Number((this.totalMoneyDonation + md.amount).toFixed(2));
    });
    this.averageMoneyDonation = Number(
      (this.totalMoneyDonation / this.moneyDonationHistory?.length).toFixed(2),
    );
  }

  isNumeric(value: string) {
    return /^-?\d+$/.test(value);
  }

  onToggle() {
    this.isHourMode = !this.isHourMode;
  }

  onKeyPress(v) {
    return (this.donationValue = this.donationValue + v.toString());
  }

  onBackSpace() {
    this.donationValue = this.donationValue.substring(0, this.donationValue.length - 1);
  }

  cancel() {
    this.alertService
      .showAlertForDonation('Cancel', 'Do you want to cancel this action?')
      .subscribe((res: HTMLIonAlertElement) => {
        res.onDidDismiss().then(data => {
          if (data.role === 'yes') {
            this.modalCtrl.dismiss(null, 'no');
            this.stripeService.destroyStripeCheckout();
          }
        });
      });
  }

  back() {
    this.alertService
      .showAlertForDonation('Cancel Donation!', 'You want to cancel this activity?')
      .subscribe((res: HTMLIonAlertElement) => {
        res.onDidDismiss().then(data => {
          if (data.role === 'yes') {
            this.isConfirmation = false;
          }
        });
      });
  }

  calculateServiceFee(value: number) {
    if (value < 40) {
      this.serviceFee = 1;
    } else {
      this.serviceFee = value * 0.025;
    }
  }

  goToConfirmationPage(v) {
    if (v != 0) {
      this.donationValue = v;
      this.calculateServiceFee(+this.donationValue);
    }
    this.isConfirmation = true;
  }

  onConfirm() {
    this.isCheckout = true;

    if (this.selectedCharity) {
      this.newDonation = {
        donationType: this.isHourMode ? 'HOUR' : 'MONEY',
        charityId: this.selectedCharity?.id,
        userId: this.userId,
        amount: +this.donationValue,
        feeAmount: this.serviceFee,
        timestamp: Date.now(),
        charitySegment: this.selectedCharity.charitySegment,
      };

      if (this.isHourMode) {
        this.subscriptions.add(
          this.charityDataService.addNewDonation(this.newDonation).subscribe(
            () => {
              this.isComplete = true;
            },
            error => {
              console.error('Error adding new donation', error);
            },
          ),
        );
      }

      sessionStorage.setItem('current_donation', JSON.stringify(this.newDonation));

      this.isConfirmation = false;
    }
  }

  done() {
    return this.modalCtrl.dismiss(this.donationValue, 'confirm');
  }

  viewDonation() {
    this.isViewInvoice = true;
  }
}
