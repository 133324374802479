<ion-header class="ion-no-border">
  <ion-toolbar class="donation-toolbar">
    <div class="top-buttons">
      <ion-buttons>
        <ion-button color="medium" (click)="cancel()">
          <img src="assets/ic-close.svg" alt="close button" />
        </ion-button>
      </ion-buttons>
    </div>
  </ion-toolbar> 
</ion-header>

@if (isConfirmation) {
  <ion-content class="ion-padding">
    <div class="confirmation-header">
      <h3>
        Confirm your Donation to
        <br />
        {{ selectedCharity?.name | titlecase }}
      </h3>
    </div>
  
    <ion-item>
      <ion-label>Donation Amount</ion-label>
      <ion-text>
        <span>$</span>
        {{ (+donationValue).toFixed(2) }}
      </ion-text>
    </ion-item>
  
    <ion-item lines="none">
      <ion-checkbox label-placement="end" [checked]="true">
        <div class="ion-text-wrap">
          I'd like to cover all transaction fees so that 100% of my donation goes to
          {{ selectedCharity?.name | titlecase }}.
        </div>
      </ion-checkbox>
    </ion-item>
  
    <ion-item>
      <ion-label style="font-weight: 600">Total Donation</ion-label>
      <ion-text style="font-weight: 600">
        <span>$</span>
        {{ (+donationValue + serviceFee).toFixed(2) }}
      </ion-text>
    </ion-item>
  
    <div class="confirm-btns">
      <button class="btn-done" (click)="onConfirm()">Confirm</button>
      <button class="btn-view" (click)="cancel()">Cancel</button>
    </div>
  </ion-content>
  }

@if (!isConfirmation) {
<ion-content class="ion-padding" style="--ion-background-color: white">
  <app-checkout
    [accountId]="accountId"
    [charityName]="selectedCharity?.name"
    [donation]="newDonation"
  ></app-checkout>
</ion-content>
}


