import { Component, Input, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular/standalone';
import { FormBuilder, FormGroup, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { GivingGoal } from '../../../core/models/GivingGoal';
import { GivingGoalsService } from '../../../core/services/giving-goals/giving-goals.service';
import { IonItem, IonLabel, IonInput, IonProgressBar } from '@ionic/angular/standalone';

@Component({
  selector: 'app-giving-goal-modal',
  templateUrl: './giving-goal-modal.component.html',
  styleUrls: ['./giving-goal-modal.component.scss'],
  standalone: true,
  imports: [FormsModule, ReactiveFormsModule, IonItem, IonLabel, IonInput, IonProgressBar],
})
export class GivingGoalModalComponent implements OnInit {
  @Input() totalDonatedMoney: number;
  @Input() totalDonatedHour: number;
  @Input() givingGoal: GivingGoal;

  form: FormGroup;

  completedPercentageMoneyGoal = 0;
  completedPercentageHourGoal = 0;

  constructor(
    private modalCtrl: ModalController,
    private fb: FormBuilder,
    private givingGoalsService: GivingGoalsService,
  ) {}

  ngOnInit() {
    if (this.givingGoal) {
      this.form = this.fb.group({
        moneyGoal: [this.givingGoal?.money_goal],
        hourGoal: [this.givingGoal?.hour_goal],
      });
    } else {
      this.form = this.fb.group({
        moneyGoal: [0],
        hourGoal: [0],
      });
    }

    this.completedPercentageMoneyGoal = this.totalDonatedMoney / this.form.value.moneyGoal;
  }

  incrementMoney() {
    this.form.value.moneyGoal++;
  }

  decrementMoney() {
    if (this.form.value.moneyGoal > 0) {
      this.form.value.moneyGoal--;
    }
  }

  incrementHour() {
    this.form.value.hourGoal++;
  }

  decrementHour() {
    if (this.form.value.hourGoal > 0) {
      this.form.value.hourGoal--;
    }
  }

  submit() {
    // If the user has a giving goal, update it
  }

  cancel() {
    return this.modalCtrl.dismiss(null, 'cancel');
  }
}
