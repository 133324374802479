import { Environment } from '../app/core/models/Environment';

export const environment: Environment = {
  production: true,
  name: 'production',

  // server
  beApiUrl: 'https://api.dev.sunnystreet.com',

  baseUrl: 'https://sunnystreet.app',

  stripePublishableKey:
    'pk_test_51OjOE7DGEe6UUXl1GNTuvkOylTvqdIRCCBsnkSM96F0oTlUZl3RUHy5sFFmWTGE6C5yCnZxrJ99KIzUVqgVFQnqA00m2hJ00UQ',
};
