import {
  Component,
  CUSTOM_ELEMENTS_SCHEMA,
  ElementRef,
  inject,
  OnInit,
  ViewChild,
} from '@angular/core';
import { NavController } from '@ionic/angular/standalone';
import { NgClass } from '@angular/common';
import { IonContent, IonImg, IonGrid, IonRow, IonCol, IonButton } from '@ionic/angular/standalone';

import { AuthService } from 'src/app/core/services/auth/auth.service';

@Component({
  selector: 'app-welcome',
  templateUrl: './welcome.page.html',
  styleUrls: ['./welcome.page.scss'],
  standalone: true,
  imports: [NgClass, IonContent, IonImg, IonGrid, IonRow, IonCol, IonButton],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class WelcomePage implements OnInit {
  @ViewChild('welcomePageSwiper') swiperRef: ElementRef | undefined;
  slideIndex = 0;
  private authService = inject(AuthService);

  constructor(private navController: NavController) {
    this.authService.user$.subscribe(result => {
      if (result && result.email) {
        this.navController.navigateRoot('/user-role-tabs').then(r => r);
      }
    });
  }
  // eslint-disable-next-line @angular-eslint/no-empty-lifecycle-method
  ngOnInit() {}

  goToLogInPage() {
    this.navController.navigateForward(['/login']).then(r => r);
  }

  goToSignUpPage() {
    this.navController.navigateForward(['/signup']).then(r => r);
  }

  onSlideChange() {
    this.slideIndex = this.swiperRef?.nativeElement.swiper.activeIndex;
  }
}
