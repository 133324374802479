import { Component, EventEmitter, Input, OnInit, Output, ViewChild, SecurityContext } from '@angular/core';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import {
  ImageCroppedEvent,
  ImageCropperComponent,
  ImageTransform,
  ImageCropperModule,
} from 'ngx-image-cropper';
import { Capacitor } from '@capacitor/core';
import { AuthService } from '../../../core/services/auth/auth.service';
import { AlertService } from '../../../core/services/navigation/alert.service';
import { addIcons } from 'ionicons';
import {
  addCircle,
  refresh,
  codeOutline,
  chevronExpandOutline,
  closeCircleOutline,
  checkmarkCircleOutline,
} from 'ionicons/icons';
import {
  IonIcon,
  IonModal,
  IonRow,
  IonCol,
  IonButton,
  IonPopover,
} from '@ionic/angular/standalone';
import { User } from 'src/app/core/models/User';

@Component({
  selector: 'app-avatar',
  templateUrl: './avatar.component.html',
  styleUrls: ['./avatar.component.scss'],
  standalone: true,
  imports: [ImageCropperModule, IonIcon, IonModal, IonRow, IonCol, IonButton, IonPopover],
})
export class AvatarComponent implements OnInit {
  @ViewChild('cropper') cropper: ImageCropperComponent;
  @Input() set avatarUrl(url: string | null) {
    if (url) {
      this.downloadImage();
    }
  }
  @Output() upload = new EventEmitter<string>();

  _avatarUrl: SafeResourceUrl = 'assets/ic-profile-avatar.svg';
  imageChangedEvent = '';
  transform: ImageTransform = {};
  isMobile = Capacitor.getPlatform() !== 'web';
  isModalOpen = false;
  loggedInUser: User;

  constructor(
    private readonly authService: AuthService,
    private readonly dom: DomSanitizer,
    private alertService: AlertService,
  ) {
    addIcons({
      addCircle,
      refresh,
      codeOutline,
      chevronExpandOutline,
      closeCircleOutline,
      checkmarkCircleOutline,
    });
  }

  ngOnInit(): void {
    this.authService.user$.subscribe(user => {
      if (user) {
        this.loggedInUser = user;
      }
    })
  }

  downloadImage() {
    // Download image from server
  }

  selectImage(e) {
    this.imageChangedEvent = e;
    this.setOpen(true);
  }

  setOpen(isOpen: boolean) {
    this.isModalOpen = isOpen;
  }

  imageCropped(event: ImageCroppedEvent) {
    const objectUrl: string = URL.createObjectURL(event.blob);
    this._avatarUrl = this.dom.bypassSecurityTrustUrl(objectUrl);
    this.uploadImage();
  }

  imageLoaded() {}

  loadImageFailed() {
    console.error('Image load failed!');
  }

  cropImage() {
    this.cropper.crop();
    this.setOpen(false);
  }

  discardChanges() {
    this.setOpen(false);
  }

  // Image edit functions
  rotate() {
    const newValue: number = ((this.transform.rotate ?? 0) + 90) % 360;

    this.transform = {
      ...this.transform,
      rotate: newValue,
    };
  }

  flipHorizontal() {
    this.transform = {
      ...this.transform,
      flipH: !this.transform.flipH,
    };
  }

  flipVertical() {
    this.transform = {
      ...this.transform,
      flipV: !this.transform.flipV,
    };
  }

  async uploadImage(){
    const id: number = Number(this.loggedInUser.id);

    const objectUrl: string = this.dom.sanitize(SecurityContext.URL, this._avatarUrl) as string;
  
    if (!objectUrl) {
      console.error('Invalid cropped image URL');
      return;
    }
  
    try {
      const file: File = await this.convertToJpg(objectUrl);
      await this.authService.changeProfilePic(id, file);

    } catch (error) {
      console.error('Failed to upload image:', error);
    }
  }

  private convertToJpg(imageUrl: string): Promise<File> {
    return new Promise((resolve, reject) => {
      const canvas: HTMLCanvasElement = document.createElement('canvas');
      const ctx: CanvasRenderingContext2D = canvas.getContext('2d');
      const img: HTMLImageElement = new Image();
  
      img.crossOrigin = 'anonymous';
      img.onload = () => {
        canvas.width = img.width;
        canvas.height = img.height;
  
        ctx?.drawImage(img, 0, 0);
  
        canvas.toBlob(
          (blob: Blob) => {
            if (blob) {
              const file: File = new File([blob], 'avatar.jpg', { type: 'image/jpeg' });
              resolve(file);
            } else {
              reject(new Error('Failed to convert image to JPG.'));
            }
          },
          'image/jpeg',
          0.9
        );
      };
  
      img.onerror = () => reject(new Error('Failed to load image.'));
      img.src = imageUrl;
    });
  }
}
