import { Component, CUSTOM_ELEMENTS_SCHEMA, OnDestroy } from '@angular/core';
import { NavController } from '@ionic/angular/standalone';
import { NgClass, TitleCasePipe, CommonModule } from '@angular/common';
//import { CommerceService } from './../../../core/services/commerce/commerce.service';
import { Product } from './../../../core/models/Product';
import { Auction, AuctionData } from 'src/app/core/models/Auction';
import { Observable, Subject } from 'rxjs';
//import { takeUntil } from 'rxjs/operators';
import {
  IonHeader,
  IonToolbar,
  IonContent,
  IonSearchbar,
  IonRow,
  IonCol,
  IonSegment,
  IonSegmentButton,
  IonChip,
  IonGrid,
} from '@ionic/angular/standalone';

@Component({
  selector: 'app-charity-auctions',
  templateUrl: './charity-auctions.component.html',
  styleUrls: ['./charity-auctions.component.scss'],
  standalone: true,
  imports: [
    TitleCasePipe,
    NgClass,
    IonHeader,
    IonToolbar,
    IonContent,
    IonSearchbar,
    IonRow,
    IonCol,
    IonSegment,
    IonSegmentButton,
    IonChip,
    IonGrid,
    CommonModule
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class CharityAuctionsComponent implements OnDestroy {
  products$: Observable<Product[]>;
  products: Product[] = [];
  auctions: Auction[] = [];
  auctionsData: AuctionData[] = [];
  public ionChipTitles = ['All', 'Ending Soon', 'New', 'Favorite'];
  selectedChip: string = 'All';
  auctionId: number = 1;
  private destroy$ = new Subject<void>();

  constructor(
    private navController: NavController,
    //private commerceService: CommerceService
  ) {}

  // ngOnInit() {
  //   this.loadAuctions();
  // }

  // loadAuctions() {
  //   this.commerceService.loadAllAuctionData().pipe(
  //     takeUntil(this.destroy$)
  //   ).subscribe({
  //     next: (auctionData: AuctionData[]) => {
  //       this.auctionsData = auctionData;
  //     },
  //     error: (err) => {
  //       console.error('Failed to fetch auctions:', err);
  //     }
  //   });
  // }

  onFiltering(v: string) {
    this.selectedChip = v;
  }

  goToCharityAuction(charityId: string) {
    this.navController.navigateForward(['/user-role-tabs/charity-auction/' + charityId]).then(r => r);
  }

  onSelectItem(auctionData: AuctionData, product: Product) {
    const productId: string = product.id.toString();
    const charityId: string = auctionData.charity.id.toString();
    const auctionId: string = auctionData.auction.id.toString();
    this.navController.navigateForward(['/user-role-tabs/item-detail-auction/' + productId], {
      state: { charityId, auctionId }
    });
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }

  newAuction() {
    //console.log("new auction");
  }
}
