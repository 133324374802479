<!-- Tool bar -->
<ion-header class="ion-no-border">
  @if (!isConfirmation && !isCheckout) {
  <ion-toolbar class="donation-toolbar">
    <div class="top-buttons">
      <ion-buttons>
        <ion-button color="medium" (click)="cancel()">
          <img src="assets/ic-close.svg" alt="close button" />
        </ion-button>
      </ion-buttons>
      <div class="toggle">
        <app-donate-mode-toggle [lightStyle]="true" [isHourMode]="isHourMode" (toggle)="onToggle()" />
      </div>
    </div>
  </ion-toolbar>
  }
  
  @if (isConfirmation || isCheckout) {
  <ion-toolbar [ngClass]="isConfirmation ? 'donation-toolbar' : 'donation-toolbar-light'">
    <div class="top-buttons">
      <ion-buttons>
        <ion-button color="medium" (click)="cancel()">
          <img src="assets/ic-close.svg" alt="close button" />
        </ion-button>
      </ion-buttons>
    </div>
  </ion-toolbar>
  }
</ion-header>

<!--ON DONATING VIEW-->
@if (!isConfirmation && !isCheckout) {
<ion-content class="ion-padding">
  <ion-grid [fixed]="true">
    <ion-row>
      <ion-col>
        <span
          [ngClass]="
            donationValue?.length < 5
              ? 'donation-value-on-full-size'
              : 'donation-value-on-small-size'
          "
        >
          @if (!isHourMode) {
          <span>$</span>
          } @if (isHourMode) {
          <span>
            <img src="assets/ic-clock-light-pink.svg" alt="icon clock" />
          </span>
          }
          <h3>{{ donationValue ? donationValue : 0 }}</h3>
        </span>
      </ion-col>
    </ion-row>
    <!--      <ion-row>-->
    <!--        @if (!isHourMode) {-->
    <!--          <ion-col>-->
    <!--            <h6>You have $112.20 available-->
    <!--              <img src="assets/ic-info-pink.svg" alt="more information icon">-->
    <!--            </h6>-->
    <!--          </ion-col>-->
    <!--        }-->
    <!--      </ion-row>-->
    @if (!isHourMode) { @if (moneyDonationHistory?.length >= 10) {
    <ion-row>
      <ion-col>
        <div
          (click)="goToConfirmationPage(averageMoneyDonation / 2)"
          (keydown)="goToConfirmationPage(averageMoneyDonation / 2)"
          tabindex="{0}"
        >
          ${{ (averageMoneyDonation / 2).toFixed(0) }}
        </div>
      </ion-col>
      <ion-col>
        <div
          (click)="goToConfirmationPage(averageMoneyDonation)"
          (keydown)="goToConfirmationPage(averageMoneyDonation)"
          tabindex="{0}"
        >
          ${{ averageMoneyDonation }}
        </div>
      </ion-col>
      <ion-col>
        <div
          (click)="goToConfirmationPage(averageMoneyDonation * 2)"
          (keydown)="goToConfirmationPage(averageMoneyDonation * 2)"
          tabindex="{0}"
        >
          ${{ averageMoneyDonation * 2 }}
        </div>
      </ion-col>
    </ion-row>
    } @if (moneyDonationHistory?.length < 10) {
    <ion-row>
      <ion-col>
        <div (click)="goToConfirmationPage(25)" (keydown)="goToConfirmationPage(25)" tabindex="{0}">
          $25
        </div>
      </ion-col>
      <ion-col>
        <div (click)="goToConfirmationPage(50)" (keydown)="goToConfirmationPage(50)" tabindex="{0}">
          $50
        </div>
      </ion-col>
      <ion-col>
        <div
          (click)="goToConfirmationPage(100)"
          (keydown)="goToConfirmationPage(100)"
          tabindex="{0}"
        >
          $100
        </div>
      </ion-col>
    </ion-row>
    } } @if (isHourMode) {
    <ion-row>
      <ion-col>
        <div (click)="goToConfirmationPage(1)" (keydown)="goToConfirmationPage(1)" tabindex="{0}">
          1 hr
        </div>
      </ion-col>
      <ion-col>
        <div (click)="goToConfirmationPage(5)" (keydown)="goToConfirmationPage(5)" tabindex="{0}">
          5 hr
        </div>
      </ion-col>
      <ion-col>
        <div (click)="goToConfirmationPage(10)" (keydown)="goToConfirmationPage(10)" tabindex="{0}">
          10 hr
        </div>
      </ion-col>
    </ion-row>
    }
    <ion-row>
      <ion-col (click)="onKeyPress(1)">1</ion-col>
      <ion-col (click)="onKeyPress(2)">2</ion-col>
      <ion-col (click)="onKeyPress(3)">3</ion-col>
    </ion-row>
    <ion-row>
      <ion-col (click)="onKeyPress(4)">4</ion-col>
      <ion-col (click)="onKeyPress(5)">5</ion-col>
      <ion-col (click)="onKeyPress(6)">6</ion-col>
    </ion-row>
    <ion-row>
      <ion-col (click)="onKeyPress(7)">7</ion-col>
      <ion-col (click)="onKeyPress(8)">8</ion-col>
      <ion-col (click)="onKeyPress(9)">9</ion-col>
    </ion-row>
    <ion-row>
      <ion-col (click)="onKeyPress('.')">.</ion-col>
      <ion-col (click)="onKeyPress(0)">0</ion-col>
      <ion-col (click)="onBackSpace()">
        <img src="assets/ic-backspace.svg" alt="icon backspace" />
      </ion-col>
    </ion-row>
    <ion-row>
      <ion-col>
        <button
          [ngClass]="isNumeric(donationValue) ? 'btn-submit-enabled' : 'btn-submit-disabled'"
          (click)="goToConfirmationPage(+donationValue)"
          [disabled]="!isNumeric(donationValue)"
        >
          Submit
        </button>
      </ion-col>
    </ion-row>
  </ion-grid>
</ion-content>
}

<!--DONATION PREPAYMENT VIEW-->
@if (isConfirmation && !isCheckout) {
<ion-content class="ion-padding">
  <div class="confirmation-header">
    <h3>
      Confirm your Donation to
      <br />
      {{ selectedCharity?.name | titlecase }}
    </h3>
  </div>

  <ion-item>
    <ion-label>Donation Amount</ion-label>
    <ion-text>
      @if (!isHourMode) {
      <span>$</span>
      }
      {{ (+donationValue).toFixed(2) }}
      @if (isHourMode) {
      <span>hr</span>
      }
    </ion-text>
  </ion-item>

  @defer (when !isHourMode) {
  <ion-item lines="none">
    <ion-checkbox label-placement="end" [checked]="true">
      <div class="ion-text-wrap">
        I'd like to cover all transaction fees so that 100% of my donation goes to
        {{ selectedCharity?.name | titlecase }}.
      </div>
    </ion-checkbox>
  </ion-item>
  }

  <ion-item>
    <ion-label style="font-weight: 600">Total Donation</ion-label>
    <ion-text style="font-weight: 600">
      @if (!isHourMode) {
      <span>$</span>
      }
      {{ (+donationValue + serviceFee).toFixed(2) }}
      @if (isHourMode) {
      <span>hr</span>
      }
    </ion-text>
  </ion-item>

  <div class="confirm-btns">
    <button class="btn-done" (click)="onConfirm()">Confirm</button>
    <button class="btn-view" (click)="back()">Go Back</button>
  </div>
</ion-content>
}

<!--MONEY DONATION CHECKOUT PAGE-->
@if (isCheckout && !isConfirmation && !isHourMode) {
<ion-content class="ion-padding" style="--ion-background-color: white">
  <app-checkout
    [accountId]="accountId"
    [charityName]="selectedCharity?.name"
    [donation]="newDonation"
  ></app-checkout>
</ion-content>
}

<!-- HOUR DONATION COMPLETED VIEW-->
@if (isHourMode && isComplete && !isViewInvoice) {
<ion-content class="ion-padding">
  <div class="confirmation-header">
    <h3>Donation Completed</h3>
    <p>
      Your donation of {{ donationValue }}
      <span>hr</span>
      is complete.
    </p>
  </div>
  <div>
    <ion-item>
      <ion-label style="font-weight: 600">Today's Donation</ion-label>
      <ion-text style="font-weight: 600">
        {{ todaysMoneyDonation }}
        <span>hr</span>
      </ion-text>
    </ion-item>
    <ion-item>
      <ion-label>Average Donation</ion-label>
      <ion-text>
        {{ averageMoneyDonation }}
        <span>hr</span>
      </ion-text>
    </ion-item>
    <ion-item>
      <ion-label>Total Donation</ion-label>
      <ion-text>
        {{ totalMoneyDonation }}
        <span>hr</span>
      </ion-text>
    </ion-item>
  </div>
  <div class="confirm-btns">
    <button class="btn-done" (click)="done()">Done</button>
    <button class="btn-view" (click)="viewDonation()">View Donation</button>
  </div>
</ion-content>
}
<!--HOUR DONATION INVOICE VIEW-->
@if (isHourMode && isComplete && isViewInvoice) {
<ion-content class="ion-padding" style="--ion-background-color: #ffffff; --ion-text-color: #393d39">
  <div class="header-section">
    <h4>Hour Donation</h4>
    <h6>{{ selectedCharity?.name }}</h6>
  </div>
  <div id="line"></div>
  <div class="donation-details">
    <div>
      <h5>Donation Status</h5>
      <p>Filled</p>
    </div>
    <div>
      <h5>Submitted</h5>
      <p>{{ newDonation?.timestamp | date : "MMM dd, yyyy 'at' hh:mm a" }}</p>
    </div>
    <div>
      <h5>Entered Amount</h5>
      <p>
        {{ donationValue }}
        <span>hr</span>
      </p>
    </div>
    <div>
      <h5>Fill Date</h5>
      <p>{{ newDonation?.timestamp | date : "MMM dd, yyyy 'at' hh:mm a" }}</p>
    </div>
    <div id="btn-download">Download Receipt</div>
  </div>
  <ion-text>
    Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut
    labore et dolore magna aliqua. Tortor aliquam nulla facilisi cras fermentum. Fermentum odio eu
    feugiat pretium nibh ipsum. Hac habitasse platea dictumst vestibulum rhoncus est pellentesque
    elit. Ultrices vitae auctor eu augue ut lectus. Rutrum quisque non tellus orci ac auctor augue
    mauris.
  </ion-text>
</ion-content>
}
