<ion-header class="ion-no-border">
  <ion-toolbar class="donation-toolbar">
    <div class="top-buttons">
      <ion-buttons>
        <ion-button color="medium" (click)="cancel()">
          <img src="assets/ic-close.svg" alt="close button" />
        </ion-button>
      </ion-buttons>
    </div>
  </ion-toolbar> 
</ion-header>


<ion-content class="ion-padding">
  <ion-grid [fixed]="true">
    <ion-row>
      <ion-col>
        <span><h2>Current Bid: ${{product.currentPrice}}</h2></span>
      </ion-col>
    </ion-row>
    <ion-row>
      <ion-col>
        <span
          [ngClass]="
            donationValue?.length < 5
              ? 'donation-value-on-full-size'
              : 'donation-value-on-medium-size'
          "
        >
          <span>$</span>
          <h3>{{ donationValue ? donationValue : 0 }}</h3>
        </span>
      </ion-col>
    </ion-row>
    <ion-row>
      <ion-col>
        <div
          (click)="setAutoValue(product.currentPrice + 1)"
          (keydown)="setAutoValue(product.currentPrice + 1)"
          tabindex="{0}"
        >
          ${{ (product.currentPrice + 1) }}
        </div>
      </ion-col>
      <ion-col>
        <div
          (click)="setAutoValue(product.currentPrice + 5)"
          (keydown)="setAutoValue(product.currentPrice + 5)"
          tabindex="{0}"
        >
          ${{ (product.currentPrice + 5) }}
        </div>
      </ion-col>
      <ion-col>
        <div
          (click)="setAutoValue(product.currentPrice + 10)"
          (keydown)="setAutoValue(product.currentPrice + 10)"
          tabindex="{0}"
        >
          ${{ (product.currentPrice + 10) }}
        </div>
      </ion-col>
    </ion-row>
    <ion-row>
      <ion-col (click)="onKeyPress(1)">1</ion-col>
      <ion-col (click)="onKeyPress(2)">2</ion-col>
      <ion-col (click)="onKeyPress(3)">3</ion-col>
    </ion-row>
    <ion-row>
      <ion-col (click)="onKeyPress(4)">4</ion-col>
      <ion-col (click)="onKeyPress(5)">5</ion-col>
      <ion-col (click)="onKeyPress(6)">6</ion-col>
    </ion-row>
    <ion-row>
      <ion-col (click)="onKeyPress(7)">7</ion-col>
      <ion-col (click)="onKeyPress(8)">8</ion-col>
      <ion-col (click)="onKeyPress(9)">9</ion-col>
    </ion-row>
    <ion-row>
      <ion-col (click)="onKeyPress('.')">.</ion-col>
      <ion-col (click)="onKeyPress(0)">0</ion-col>
      <ion-col (click)="onBackSpace()">
        <img src="assets/ic-backspace.svg" alt="icon backspace" />
      </ion-col>
    </ion-row>
    <ion-row>
      <ion-col>
        <button
          [ngClass]="'btn-submit-enabled'"
          (click)="goToConfirmationPage(+donationValue)"
        >
          Place Bid
        </button>
      </ion-col>
    </ion-row>
  </ion-grid>
</ion-content>


