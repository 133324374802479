<ion-header class="ion-no-border">
  <ion-toolbar>
    <ion-button fill="clear" (click)="back()">
      <img src="assets/ic-back-clay.svg" alt="navigate back button" />
    </ion-button>
  </ion-toolbar>
</ion-header>

<ion-content [fullscreen]="true">
  <div class="container">
    <h3 class="header">Giving</h3>

    <div class="text-container">
      <ion-note>Portfolio Value</ion-note>
      <br />
      <ion-text>$0.00</ion-text>
    </div>

    <p>
      Portfolio value represents the total value of all the holdings in your account, including
      cash.
    </p>

    <div class="pie-chart-container">
      <div id="myDiv"></div>
      <div class="left-div">
        <div>
          <p>Segment 1</p>
          <ion-text>$10.00</ion-text>
        </div>
        <div>
          <p>Segment 2</p>
          <ion-text>$20.00</ion-text>
        </div>
        <div>
          <p>Segment 3</p>
          <ion-text>$70.00</ion-text>
        </div>
      </div>
    </div>

    <div class="text-container">
      <ion-note>Recurring Donations</ion-note>
      <br />
      <ion-text>0</ion-text>
    </div>

    <p>Lorem ipsum dolor sit amet consectetur. Neque nunc elementum enim cursus.</p>

    <div class="btn-view">
      <button (click)="view()">View Recurring Donations</button>
    </div>
  </div>
</ion-content>
