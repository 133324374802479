import { Component, OnDestroy, OnInit } from '@angular/core';
import { NavController } from '@ionic/angular/standalone';
import { CharityService } from '../../../../../core/services/charity/charity.service';
import { Subscription } from 'rxjs';
import { Charity } from '../../../../../core/models/Charity';
import { ClaimCharityComponent } from '../claim-charity/claim-charity.component';
import { ActivatedRoute } from '@angular/router';
import { SharedModule } from '../../../../shared.module';
import {
  IonHeader,
  IonToolbar,
  IonButton,
  IonContent,
  IonSearchbar,
  IonItem,
  IonLabel,
  IonText,
} from '@ionic/angular/standalone';

@Component({
  selector: 'app-search-charity',
  templateUrl: './search-charity.component.html',
  styleUrls: ['./search-charity.component.scss'],
  standalone: true,
  imports: [
    ClaimCharityComponent,
    SharedModule,
    IonHeader,
    IonToolbar,
    IonButton,
    IonContent,
    IonSearchbar,
    IonItem,
    IonLabel,
    IonText,
  ],
})
export class SearchCharityComponent implements OnInit, OnDestroy {
  private readonly subscriptions = new Subscription();
  charity: Charity;
  userId: string;
  inputEin: string;
  showForm = false;

  constructor(
    private navController: NavController,
    private charityService: CharityService,
    private activatedRoute: ActivatedRoute,
  ) {}

  ngOnInit() {
    this.userId = this.activatedRoute.snapshot.paramMap.get('id');
  }

  ngOnDestroy() {
    this.subscriptions.unsubscribe();
  }

  getSearchValue(e) {
    this.inputEin = e.detail.value;
  }

  searchCharityByEin(ein: string) {
    this.subscriptions.add(
      this.charityService.getCharityByEin(ein).subscribe(res => {
        this.charity = res;
      }),
    );
  }

  goToClaimCharityForm() {
    this.showForm = true;
  }

  back() {
    this.navController.back();
  }
}
