import { Component, OnInit } from '@angular/core';
import { NavController } from '@ionic/angular/standalone';
import {
  IonHeader,
  IonToolbar,
  IonButton,
  IonContent,
  IonList,
  IonItem,
} from '@ionic/angular/standalone';

@Component({
  selector: 'app-notifications',
  templateUrl: './notifications.page.html',
  styleUrls: ['./notifications.page.scss'],
  standalone: true,
  imports: [IonHeader, IonToolbar, IonButton, IonContent, IonList, IonItem],
})
export class NotificationsPage implements OnInit {
  constructor(private navController: NavController) {}

  // eslint-disable-next-line @angular-eslint/no-empty-lifecycle-method
  ngOnInit() {}

  back() {
    this.navController.back();
  }
}
