@for (segment of chartSegments; track segment; let i = $index) {
  <ion-grid [fixed]="true">
    <h5>{{ segment }}</h5>
    <ion-row class="ion-justify-content-between">
      <ion-col size="auto">
        <div class="segment-text">{{ allUserDonations[i] }} Donations</div>
      </ion-col>
      <ion-col size="auto">
        <div id="timeSeriesMiniChart{{ i }}"></div>
      </ion-col>
      <ion-col size="auto">
        <button class="segment-amount-personal">
          {{
            donationType === 'money'
              ? '$' + totalSegmentDonationArray[i]
              : totalSegmentDonationArray[i] + ' hr'
          }}
        </button>
      </ion-col>
    </ion-row>
    <ion-row class="ion-justify-content-between">
      <ion-col size="auto">
        <div class="segment-text">Friend</div>
      </ion-col>
      <ion-col size="auto">
        <div id="timeSeriesFriendMiniChart{{ i }}"></div>
      </ion-col>
      <ion-col size="auto">
        <button class="segment-amount-friend">
          {{ donationType === 'money' ? '$0' : '0 hr' }}
        </button>
      </ion-col>
    </ion-row>
  </ion-grid>
}
