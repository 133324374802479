<section class="header-section">
  <h4>Community Feed</h4>
  <img src="assets/ic-plus-circle.svg" alt="plus circle icon for adding new post" />
</section>

<section>
  <div class="filter-header">
    <h6>In This List</h6>
    <img src="assets/ic-sort.svg" alt="filter icon" />
  </div>

  <ion-row>
    <ion-col size="12">
      <ion-segment mode="md" [scrollable]="true">
        @for (title of ionChipTitles; track title) {
        <ion-segment-button (click)="onSelectFilterChip(title)">
          <ion-chip [ngClass]="selectedChip === title ? 'selected-chip' : ''">
            {{ title }}
          </ion-chip>
        </ion-segment-button>
        }
      </ion-segment>
    </ion-col>
  </ion-row>

  <ion-list>
    <ion-item>
      <ion-thumbnail slot="end">
        <img
          alt="Silhouette of mountains"
          src="https://ionicframework.com/docs/img/demos/thumbnail.svg"
        />
      </ion-thumbnail>
      <div>
        <span>
          <img src="" alt="" />
          <ion-text>User name</ion-text>
        </span>
        <p>This is a test charity text description, just want</p>
        <div class="social-icons-container">
          <ion-icon src="assets/ic-repeat.svg"></ion-icon>
          <ion-icon src="assets/ic-comment-text.svg"></ion-icon>
          <ion-icon src="assets/ic-heart-outline.svg"></ion-icon>
        </div>
      </div>
    </ion-item>
    <ion-item>
      <ion-thumbnail slot="end">
        <img
          alt="Silhouette of mountains"
          src="https://ionicframework.com/docs/img/demos/thumbnail.svg"
        />
      </ion-thumbnail>
      <div>
        <span>
          <img src="" alt="" />
          <ion-text>User name</ion-text>
        </span>
        <p>This is a test charity text description, just want to show some texts</p>
        <div class="social-icons-container">
          <ion-icon src="assets/ic-repeat.svg"></ion-icon>
          <ion-icon src="assets/ic-comment-text.svg"></ion-icon>
          <ion-icon src="assets/ic-heart-outline.svg"></ion-icon>
        </div>
      </div>
    </ion-item>
    <ion-item>
      <ion-thumbnail slot="end">
        <img
          alt="Silhouette of mountains"
          src="https://ionicframework.com/docs/img/demos/thumbnail.svg"
        />
      </ion-thumbnail>
      <div>
        <span>
          <img src="" alt="" />
          <ion-text>User name</ion-text>
        </span>
        <p>This is a test charity text description, just want to show some texts</p>
        <div class="social-icons-container">
          <ion-icon src="assets/ic-repeat.svg"></ion-icon>
          <ion-icon src="assets/ic-comment-text.svg"></ion-icon>
          <ion-icon src="assets/ic-heart-outline.svg"></ion-icon>
        </div>
      </div>
    </ion-item>
  </ion-list>
</section>
