<ion-header class="ion-no-border">
  <ion-toolbar></ion-toolbar>
</ion-header>

<ion-content [fullscreen]="true">
  <div class="main-content">
    <section>
      <ion-grid class="header-container">
        <ion-row>
          <ion-col size="auto">
            <ion-thumbnail>
              <img
                alt="Silhouette of mountains"
                src="https://ionicframework.com/docs/img/demos/thumbnail.svg"
              />
            </ion-thumbnail>
          </ion-col>
          <ion-col size="6">
            <h3>{{charity?.name | titlecase}}</h3>
          </ion-col>
          <ion-col size="2">
            @if (!isFollowing) {
            <div
              class="btn-follow"
              (click)="onFollowCharity()"
              (keydown)="onFollowCharity()"
              tabindex="{0}"
            >
              <img src="assets/btn-follow.svg" alt="follow button" />
            </div>
            } @if (isFollowing) {
            <div
              class="btn-following"
              (click)="presentAlert()"
              (keydown)="presentAlert()"
              tabindex="{0}"
            >
              <img src="assets/btn-following.svg" alt="following button" />
            </div>
            }
          </ion-col>
          <ion-col size="1">
            <div class="btn-mail">
              <img src="assets/ic-mail.svg" alt="mail icon button" />
            </div>
          </ion-col>
        </ion-row>
      </ion-grid>

      <div class="stats-summary">
        <h1>
          @if (!isHourMode) { ${{totalMoneyDonation.toFixed(2)}} } @else {
          <span>{{totalHourDonation}} hr</span>
          }
          <app-donate-mode-toggle [isHourMode]="isHourMode" (toggle)="onToggle()" />
        </h1>
        <div class="text-small">
          @if (!isHourMode) {
          <p id="dollar-text">
            <img src="assets/ic-polygon-green.svg" alt="icon" />
            ${{totalRangeMoneyDonation.toFixed(2)}}
          </p>
          } @else {
          <p id="hour-text">
            <img src="assets/ic-polygon-clay.svg" alt="icon" />
            {{totalRangeHourDonation}} hr
          </p>
          }
          <p>{{timeRangeText}}</p>
        </div>
      </div>
      <!--  Graph-->
      @defer (on immediate) {
      <app-charity-chart
        [donations]="getDonations()"
        [isHourMode]="isHourMode"
        (tabSelectedEmitter)="onTabSelected($event)"
      ></app-charity-chart>
      }
    </section>

    <section class="position-section">
      <h4>Your Position</h4>
      <ion-grid>
        <ion-row>
          <ion-col>
            <p>Quantity</p>
            <span>{{moneyDonationHistory?.length ? moneyDonationHistory.length : '0'}}</span>
          </ion-col>
          <ion-col>
            <p>Total Donation</p>
            <span>${{totalDonatedMoneyToCharityByUser.toFixed(2)}}</span>
          </ion-col>
        </ion-row>
        <ion-row>
          <ion-col>
            <p>Average Donation</p>
            <span>
              ${{(totalDonatedMoneyToCharityByUser / (moneyDonationHistory?.length ?
              moneyDonationHistory.length : 1)).toFixed(2)}}
            </span>
          </ion-col>
          <ion-col>
            <p>Portfolio Diversity</p>
            <span>
              {{((totalDonatedMoneyToCharityByUser / totalDonatedMoneyByUser)*100).toFixed(0)}}%
            </span>
          </ion-col>
        </ion-row>
        <ion-row style="margin-top: 1rem">
          <ion-col>
            <p class="today-donation">Today's Donation</p>
          </ion-col>
          <ion-col>
            <p class="today-donation">${{todayDonationByUser.toFixed(2)}}</p>
          </ion-col>
        </ion-row>
      </ion-grid>
    </section>

    <section class="charity-section">
      <h4>About Charity</h4>
      <ion-grid>
        <ion-row>
          <ion-col>
            <p>Revenue</p>
            <span>{{charity?.revenueAmount}}</span>
          </ion-col>
          <ion-col>
            <p>Assets</p>
            <span>{{charity?.assetAmount}}</span>
          </ion-col>
        </ion-row>
        <ion-row>
          <p class="about-text">
            {{charity?.description}}
          </p>
        </ion-row>
        <ion-row>
          <ion-col>
            <p>Name</p>
            <span>{{charity?.name}}</span>
          </ion-col>
          <ion-col>
            <p>EIN</p>
            <span>{{charity?.ein}}</span>
          </ion-col>
        </ion-row>
        <ion-row>
          <ion-col>
            <p>Website</p>
            <span id="website-link">{{charity?.website}}</span>
          </ion-col>
          <ion-col>
            <p>Segment</p>
            <span>{{charity?.charitySegment}}</span>
          </ion-col>
        </ion-row>
      </ion-grid>
    </section>

    <section class="donation-history-section">
      <h4>Your Donation History</h4>
      <ion-grid>
        <ion-row>
          <ion-col class="col-header-date">Date</ion-col>
          <ion-col class="col-header-amount">Amount</ion-col>
        </ion-row>
        @for (d of moneyDonationHistory; track d) {
        <ion-row>
          <ion-col class="col-date">{{d?.timestamp | date: "M/d/yyyy"}}</ion-col>
          <ion-col class="col-amount">${{d.amount}}</ion-col>
        </ion-row>
        }
      </ion-grid>
    </section>

    <section class="explore-impact-section">
      <h4>Explore Impact</h4>
      <div style="display: flex">
        <ion-card>
          <img alt="Silhouette of mountains" src="assets/test.JPG" />
          <div>
            Food banks feeds 100+ families in the local neighborhood during the time of disaster
          </div>
        </ion-card>

        <ion-card>
          <img alt="Silhouette of mountains" src="assets/test.JPG" />
          <div>Community helps local charity to fund school for children from poor families</div>
        </ion-card>
        <ion-card>
          <img alt="Silhouette of mountains" src="assets/test.JPG" />
          <div>
            School donates clothing to students with difficult background during Christmas season
          </div>
        </ion-card>
      </div>

      <div class="impact-point-more">
        <p>See more Impact Points</p>
        <img src="assets/ic-arrow-right.svg" alt="icon arrow right" />
      </div>
    </section>
  </div>

  <section class="btn-section">
    <button class="btn-donate" id="open-modal" (click)="onDonate()">Donate</button>
    <button class="btn-share" (click)="onShare()">Share</button>
  </section>
</ion-content>
