<ion-header class="ion-no-border">
  <ion-toolbar>
    <ion-icon
      class="ic-back"
      [src]="'/assets/ic-circle-back.svg'"
      (click)="goToWelcomePage()"
    ></ion-icon>
  </ion-toolbar>
</ion-header>

<ion-content [fullscreen]="true">
  <div class="logo">
    <ion-img
      style="width: 100%"
      src="assets/logo-big.svg"
      alt="Sunny Street clay color logo"
    ></ion-img>
  </div>

  <form [formGroup]="loginForm" (ngSubmit)="onLogIn()">
    <div class="input-group">
      <ion-item lines="none">
        <ion-input placeholder="email" formControlName="email" ngDefaultControl #email></ion-input>
      </ion-item>
      <br />
      <ion-item lines="none">
        <ion-input
          placeholder="password"
          formControlName="password"
          [type]="showPassword ? 'text' : 'password'"
          ngDefaultControl
          #password
        ></ion-input>
        <ion-icon
          [name]="showPassword ? 'eye' : 'eye-off'"
          class="m-auto"
          (click)="togglePasswordMode()"
        ></ion-icon>
      </ion-item>
    </div>

    <ion-button
      class="btn-login"
      type="submit"
      shape="round"
      expand="block"
      [disabled]="!loginForm.valid"
    >
      Log In
    </ion-button>
    <br />
    <ion-button
      class="btn-forgot-password"
      fill="outline"
      shape="round"
      expand="block"
      (click)="goToForgotPasswordPage()"
    >
      Forgot Password
    </ion-button>
  </form>
</ion-content>
