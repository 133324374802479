import { AbstractControl, ValidatorFn } from '@angular/forms';

export class FormValidators {
  private static readonly EMAIL_REGEX = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
  private static readonly ONE_DIGIT_REGEX = /^.*[0-9].*$/;
  private static readonly ONE_LOWERCASE_LETTER_REGEX = /^.*[a-z].*$/;
  private static readonly ONE_SPECIAL_CHAR_REGEX = /^.*[!"#$%&'()*+,-./:;<=>?@[\]^_`{|}~].*$/;
  private static readonly ONE_UPPERCASE_LETTER_REGEX = /^.*[A-Z].*$/;
  private static readonly WEBSITE_REGEX =
    /^((https?|ftp|smtp):\/\/)?(www.)?[a-z0-9]+\.[a-z]+(\/[a-zA-Z0-9#]+\/?)*$/;

  public static readonly email: ValidatorFn = (c: AbstractControl) => {
    const isString: boolean = typeof c.value === 'string';
    const val: string = (c.value as string) ?? '';
    const valid: boolean = isString && !!val.match(FormValidators.EMAIL_REGEX);
    return valid || val.length === 0 ? null : { email: true };
  };

  public static readonly website: ValidatorFn = (c: AbstractControl) => {
    const isString: boolean = typeof c.value === 'string';
    const val: string = (c.value as string) ?? '';
    const valid: boolean = isString && !!val.match(FormValidators.WEBSITE_REGEX);
    return valid || val.length === 0 ? null : { website: true };
  };

  public static readonly oneDigit: ValidatorFn = (c: AbstractControl) => {
    const isString: boolean = typeof c.value === 'string';
    const val: string = (c.value as string) ?? '';
    const valid: boolean = isString && !!val.match(FormValidators.ONE_DIGIT_REGEX);
    return valid || val.length === 0 ? null : { onedigit: true };
  };

  public static readonly oneLowercase: ValidatorFn = (c: AbstractControl) => {
    const isString: boolean = typeof c.value === 'string';
    const val: string = (c.value as string) ?? '';
    const valid: boolean = isString && !!val.match(FormValidators.ONE_LOWERCASE_LETTER_REGEX);
    return valid || val.length === 0 ? null : { onelowercase: true };
  };

  public static readonly oneSpecial: ValidatorFn = (c: AbstractControl) => {
    const isString: boolean = typeof c.value === 'string';
    const val: string = (c.value as string) ?? '';
    const valid: boolean = isString && !!val.match(FormValidators.ONE_SPECIAL_CHAR_REGEX);
    return valid || val.length === 0 ? null : { onespecial: true };
  };

  public static oneUppercase: ValidatorFn = (c: AbstractControl) => {
    const isString: boolean = typeof c.value === 'string';
    const val: string = (c.value as string) ?? '';
    const valid: boolean = isString && !!val.match(FormValidators.ONE_UPPERCASE_LETTER_REGEX);
    return valid || val.length === 0 ? null : { oneuppercase: true };
  };
}
