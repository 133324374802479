import { Component, OnDestroy, OnInit } from '@angular/core';
import { ModalController, NavController } from '@ionic/angular/standalone';
import { Subscription } from 'rxjs';
import {
  IonHeader,
  IonToolbar,
  IonButton,
  IonContent,
  IonRow,
  IonCol,
  IonGrid,
} from '@ionic/angular/standalone';
import { CommerceService } from 'src/app/core/services/commerce/commerce.service';
import { Product } from '../../../core/models/Product';
import { ActivatedRoute, Router } from '@angular/router';
import { Auction } from 'src/app/core/models/Auction';
import { BidModalComponent } from '../bid-modal/bid-modal.component';
import { Charity } from 'src/app/core/models/Charity';
import { CharityService } from 'src/app/core/services/charity/charity.service';
import { CommonModule } from '@angular/common';
import { Bid } from 'src/app/core/models/Bid';
import { User } from 'src/app/core/models/User';
import { AuthService } from 'src/app/core/services/auth/auth.service';

@Component({
  selector: 'app-item-detail-auction',
  templateUrl: './item-detail-auction.component.html',
  styleUrls: ['./item-detail-auction.component.scss'],
  standalone: true,
  imports: [IonHeader, IonToolbar, IonButton, IonContent, IonRow, IonCol, IonGrid, CommonModule],
})
export class ItemDetailAuctionComponent implements OnInit, OnDestroy {
  product: Product;
  auction: Auction;
  charity: Charity;
  bids: Bid[] = [];
  loggedInUser: User;

  private subscription: Subscription = new Subscription();
  remainingTime: { days: number; hours: number; minutes: number } = {
    days: 0,
    hours: 0,
    minutes: 0,
  };

  constructor(
    private navController: NavController,
    private route: ActivatedRoute,
    private commerceService: CommerceService,
    private router: Router,
    private modalCtrl: ModalController,
    private charityService: CharityService,
    private authService: AuthService
  ) {}

  ngOnInit() {
    const productId: string = this.route.snapshot.paramMap.get('id')!;
    const charityId: string = this.router.getCurrentNavigation().extras.state['charityId'];
    const auctionId: string = this.router.getCurrentNavigation().extras.state['auctionId'];
    this.loadProduct(productId);
    this.loadAuction(auctionId);
    this.loadCharity(charityId);
    this.loadBids(productId);
    this.getLoggedInUser();
  }

  loadProduct(productId: string) {
    const sub: Subscription = this.commerceService.getProductByProductId(productId).subscribe({
      next: (product) => {
        this.product = product;
      },
      error: (err) => {
        console.error('Failed to fetch products:', err);
      }
    });
    this.subscription.add(sub);
  }

  loadAuction(auctionId: string) {
    const sub: Subscription = this.commerceService.getAuctionByAuctionId(auctionId)
    .subscribe({
      next: (auction) => {
        this.auction = auction;
        this.calculateRemainingTime();
      },
      error: (err) => {
        console.error('Failed to fetch products:', err);
      }
    });
    this.subscription.add(sub);
  }

  loadCharity(charityId: string) {
    const sub: Subscription = this.charityService.getCharity(charityId).subscribe({
      next: (charity) => {
        this.charity = charity;
      },
      error: (err) => {
        console.error('Failed to fetch products:', err);
      }
    });
    this.subscription.add(sub);
  }

  loadBids(productId: string) {
    const sub: Subscription = this.commerceService.getBidsByProductId(productId).subscribe({
      next: (bids) => {
        this.bids = bids;
      },
      error: (err) => {
        console.error('Failed to fetch products:', err);
      }
    });
    this.subscription.add(sub);
  }

  getLoggedInUser() {
    this.subscription.add(
      this.authService.user$.subscribe(user => {
        this.loggedInUser = user;
      }),
    )
  }

  calculateRemainingTime() {
    const now: number = new Date().getTime();
    const end: number = new Date(this.auction.endDate).getTime();
    const diff: number = end - now;

    if (diff > 0) {
      const minutes: number = Math.floor(diff / (1000 * 60)) % 60;
      const hours: number = Math.floor(diff / (1000 * 60 * 60)) % 24;
      const days: number = Math.floor(diff / (1000 * 60 * 60 * 24));

      this.remainingTime = { days, hours, minutes };
    } else {
      this.remainingTime = { days: 0, hours: 0, minutes: 0 };
    }
  }

  back() {
    this.navController.back();
  }

  placeBid() {
    this.modalCtrl
      .create({
        component: BidModalComponent,
        componentProps: {
          product: this.product,
          userId: this.loggedInUser?.id,
          selectedCharity: this.charity
        },
      })
      .then(modal => {
        modal.onDidDismiss().then(result => {
          if (result.data?.bidPlaced) {
            this.loadBids(this.product.id.toString());
            this.loadProduct(this.product.id.toString());
          }
        });
        modal.present();
      });
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }
}
