<ion-header class="ion-no-border">
  <ion-toolbar></ion-toolbar>
</ion-header>

<ion-content [fullscreen]="true">
  <div class="main-content">
    <section>
      <h4>Charities You Follow</h4>
      <p>Explore Charities you follow in Sunny Street.</p>

      <div class="charity-segment-header">
        <app-donate-mode-toggle [isHourMode]="isHourMode" (toggle)="onToggleDonationMode()" />
      </div>

      @if (followedCharityList.length < 1) {
      <div>Please go to Explore Charities to find your favorite charities to follow.</div>
      } @defer (when followedCharityList.length > 0) {
      <ion-grid>
        @for (c of followedCharityList; track c; let i = $index) {
        <div>
          @if (i < showCount) {
          <div>
            <ion-row (click)="onSelectCharity(c?.id)" class="ion-justify-content-between">
              <ion-col size="auto">
                <ion-label class="ellipsis">{{c?.name | titlecase}}</ion-label>
                <br />
                <ion-note>{{numberOfDonationsArray[i]}} Donations</ion-note>
              </ion-col>
              <ion-col size="auto">
                <div id="timeSeriesCharityMiniChart{{i}}"></div>
              </ion-col>
              <ion-col size="auto">
                <button>
                  {{ !isHourMode ? '$' + totalDonatedAmountArray[i] : totalDonatedAmountArray[i] + '
                  hr' }}
                </button>
              </ion-col>
            </ion-row>
          </div>
          }
        </div>
        }
      </ion-grid>
      } @defer (when followedCharityList.length > 4) {
      <span
        (click)="toggleShowMore()"
        (keydown)="toggleShowMore()"
        tabindex="{0}"
        class="btn-show-more"
      >
        {{showButtonText}}
      </span>
      }
    </section>
  </div>
</ion-content>
