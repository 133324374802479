import { Component } from '@angular/core';
import { ModalController, NavController } from '@ionic/angular/standalone';
import {
  IonHeader,
  IonToolbar,
  IonButton,
  IonContent,
  IonList,
  IonItem,
  IonLabel,
  IonText,
  IonNote,
} from '@ionic/angular/standalone';

@Component({
  selector: 'app-transfer',
  templateUrl: './transfer.component.html',
  styleUrls: ['./transfer.component.scss'],
  standalone: true,
  imports: [
    IonHeader,
    IonToolbar,
    IonButton,
    IonContent,
    IonList,
    IonItem,
    IonLabel,
    IonText,
    IonNote,
  ],
})
export class TransferComponent {
  constructor(
    private navController: NavController,
    private modalCtrl: ModalController,
  ) {}

  addAccount() {
    // const modal = await this.modalCtrl.create({
    //   component: ConnectAccountComponent,
    //   componentProps: {
    //   },
    // });
    // await modal.present();
  }

  back() {
    this.navController.back();
  }
}
