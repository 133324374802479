import { Component, inject } from '@angular/core';
import { NavController } from '@ionic/angular/standalone';
import { addIcons } from 'ionicons';
import { searchOutline, notifications, menu } from 'ionicons/icons';
import { Subscription } from 'rxjs';
import {
  IonHeader,
  IonToolbar,
  IonButton,
  IonButtons,
  IonIcon,
  IonContent,
  IonRefresher,
  IonRefresherContent,
  IonTabs,
  IonTabBar,
  IonTabButton,
} from '@ionic/angular/standalone';
import { Router } from '@angular/router';
import { AuthService } from 'src/app/core/services/auth/auth.service';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { User } from '../../../core/models/User';

@Component({
  selector: 'app-user-role-tabs',
  templateUrl: './user-role-tabs.component.html',
  styleUrls: ['./user-role-tabs.component.scss'],
  standalone: true,
  imports: [
    IonHeader,
    IonToolbar,
    IonButton,
    IonButtons,
    IonIcon,
    IonContent,
    IonRefresher,
    IonRefresherContent,
    IonTabs,
    IonTabBar,
    IonTabButton,
  ],
})
export class UserRoleTabsComponent {
  currentTab = 'home';
  isMainPage: boolean = true;
  isSpecialPage: boolean = false;

  loggedInUser: User;
  private readonly subscriptions = new Subscription();
  authService = inject(AuthService);
  avatarUrl: SafeResourceUrl = 'assets/ic-profile-avatar.svg';

  readonly specialPages: string[] = [
    'history',
    'giving',
    'item-detail',
    'item-detail-auction',
    'charity-auction',
    'social',
  ];
  
  constructor(
    private navController: NavController,
    private router: Router,
    private domSanitizer: DomSanitizer,
  ) {
    addIcons({ searchOutline, notifications, menu });
    this.getLoggedInUserInfo();
  }

  getLoggedInUserInfo() {
    this.subscriptions.add(
      this.authService.user$.subscribe(user => {
        if (user) {
          this.loggedInUser = user;
          this.avatarUrl = this.domSanitizer.bypassSecurityTrustUrl(user.avatarUrl);
        }
      }),
    );
  }

  setCurrentTab(e) {
    this.currentTab = e.tab;
    this.isMainPage = !(
      e.tab == 'menu' ||
      e.tab == 'search' ||
      e.tab == 'notifications' ||
      e.tab == 'edit-profile'
    );

    const currentUrl: string = this.router.url;
    this.isSpecialPage = this.specialPages.some(page => currentUrl.includes(page));
  }

  goToMenuPage() {
    this.navController.navigateForward(['/menu']).then(r => r);
  }

  goToNotificationsPage() {
    this.navController.navigateForward(['/notifications']).then(r => r);
  }

  goToSearchPage() {
    this.navController.navigateForward(['/search']).then(r => r);
  }

  // To Do update for discover being the main tab
  back() {
    const currentUrl: string = this.router.url;
    if (currentUrl.includes('/user-role-tabs/charity/')) {
      const fromDiscover: boolean =
        this.router.getCurrentNavigation()?.extras?.queryParams?.['fromDiscover'];
      if (fromDiscover) {
        this.navController.navigateRoot('/user-role-tabs/discover');
      } else {
        this.navController.navigateRoot('/user-role-tabs/discover');
      }
    } else {
      this.navController.back();
    }
  }

  //Todo: Refresh page data
  handleRefresh(e) {
    setTimeout(() => {
      // Any calls to load data go here
      e.target.complete();
    }, 1000);
  }
}
