<ion-header class="ion-no-border">
  <ion-toolbar></ion-toolbar>
</ion-header>

<ion-content [fullscreen]="true">
  <div class="main-content">

    <section>
      <ion-row class="search">
        <ion-searchbar mode="md" [debounce]="1000" placeholder="Search for a Charity" (ionInput)="handleSearch($event)" />
        <ion-col size="auto">
          <ion-icon name="options" mode="md" size="large"></ion-icon>
        </ion-col>
      </ion-row>

      <ion-row>
        <ion-col size="12">
          <ion-segment mode="md" [scrollable]="true">
            @if (selectedChip !== ''){
              <ion-segment-button (click)="clearFilter()">
                <ion-chip>Clear</ion-chip>
              </ion-segment-button>
            }
            @for (title of ionChipTitles; track title) {
            <ion-segment-button (click)="onFilteringCharities(title)">
              
              <ion-chip [ngClass]="selectedChip === title ? 'selected-chip' : ''">
                {{title}}
              </ion-chip>
            </ion-segment-button>
            }
          </ion-segment>

        </ion-col>
      </ion-row>
    </section>
    @if (startSearch === false){
      <section>
        <h5>Recommended Charities</h5>
      </section>

      <ion-list>
        <ion-item (click)="onSelectCharity(charities[0]?.id)">
          <ion-row class="content">
            <ion-col>
              <ion-row class="top">
                <ion-col size="auto">
                  <ion-icon size="large" [src]="'/assets/profile.svg'"></ion-icon>
                </ion-col>
                <ion-col>
                  <h5>{{charities[0]?.name | titlecase}}</h5>
                </ion-col>
              </ion-row>
              <ion-row>
                <ion-col size="12">
                  <p>{{charities[0]?.description}}</p>
                </ion-col>
              </ion-row>
            </ion-col>
            <ion-button fill="clear">
              <ion-icon src="/assets/ic-arrow.svg" size="large"></ion-icon>
            </ion-button>
          </ion-row>
        </ion-item>
        <ion-item (click)="onSelectCharity(charities[1]?.id)">
          <ion-row class="content">
            <ion-col>
              <ion-row class="top">
                <ion-col size="auto">
                  <ion-icon size="large" [src]="'/assets/profile.svg'"></ion-icon>
                </ion-col>
                <ion-col>
                  <h5>{{charities[1]?.name | titlecase}}</h5>
                </ion-col>
              </ion-row>
              <ion-row>
                <ion-col size="12">
                  <p>{{charities[1]?.description}}</p>
                </ion-col>
              </ion-row>
            </ion-col>
            <ion-button fill="clear">
              <ion-icon src="/assets/ic-arrow.svg" size="large"></ion-icon>
            </ion-button>
          </ion-row>
        </ion-item>
        <ion-item (click)="onSelectCharity(charities[2]?.id)">
          <ion-row class="content">
            <ion-col>
              <ion-row class="top">
                <ion-col size="auto">
                  <ion-icon size="large" [src]="'/assets/profile.svg'"></ion-icon>
                </ion-col>
                <ion-col>
                  <h5>{{charities[2]?.name | titlecase}}</h5>
                </ion-col>
              </ion-row>
              <ion-row>
                <ion-col size="12">
                  <p>{{charities[2]?.description}}</p>
                </ion-col>
              </ion-row>
            </ion-col>
            <ion-button fill="clear">
              <ion-icon src="/assets/ic-arrow.svg" size="large"></ion-icon>
            </ion-button>
          </ion-row>
        </ion-item>
      </ion-list>

      <section class="news-title">
        <div class="news-title-container">
          <h5>Charities in the News</h5>
          <a (click)="goToNewsFeedPage()" (keydown)="goToNewsFeedPage()" tabindex="{0}">See More ></a>
        </div>
      </section>

      <div class="news-feed">
      <ion-list>
        <ion-item>
          <ion-thumbnail slot="end">
            <img
              alt="Silhouette of mountains"
              src="https://ionicframework.com/docs/img/demos/thumbnail.svg"
            />
          </ion-thumbnail>
          <div>
            <span>
              <ion-text>Source</ion-text>
              <ion-note>10m</ion-note>
            </span>
            <p>This is a test charity text description, just want</p>
          </div>
        </ion-item>
        <ion-item>
          <ion-thumbnail slot="end">
            <img
              alt="Silhouette of mountains"
              src="https://ionicframework.com/docs/img/demos/thumbnail.svg"
            />
          </ion-thumbnail>
          <div>
            <span>
              <ion-text>Source</ion-text>
              <ion-note>10m</ion-note>
            </span>
            <p>This is a test charity text description, just want to show some texts</p>
          </div>
        </ion-item>
        <ion-item>
          <ion-thumbnail slot="end">
            <img
              alt="Silhouette of mountains"
              src="https://ionicframework.com/docs/img/demos/thumbnail.svg"
            />
          </ion-thumbnail>
          <div>
            <span>
              <ion-text>Source</ion-text>
              <ion-note>10m</ion-note>
            </span>
            <p>This is a test charity text description, just want to show some texts</p>
          </div>
        </ion-item>
      </ion-list>
    </div>
    }

    @if (startSearch === true){
    <ion-list>
      @for (charity of charities; track charity.id) {
      <ion-item (click)="onSelectCharity(charity?.id)">
        <ion-row class="content">
          <ion-col>
            <ion-row class="top">
              <ion-col size="auto">
                <ion-icon size="large" [src]="'/assets/profile.svg'"></ion-icon>
              </ion-col>
              <ion-col>
                <h5>{{charity?.name | titlecase}}</h5>
              </ion-col>
            </ion-row>
            <ion-row>
              <ion-col size="12">
                <p>{{charity?.description}}</p>
              </ion-col>
            </ion-row>
          </ion-col>
          <ion-button fill="clear">
            <ion-icon src="/assets/ic-arrow.svg" size="large"></ion-icon>
          </ion-button>
        </ion-row>
      </ion-item>
      }
    </ion-list>
    <ion-infinite-scroll threshold="5rem" (ionInfinite)="getData($event)">
      <ion-infinite-scroll-content
        loadingSpinner="bubbles"
        loadingText="Loading more data..."
      ></ion-infinite-scroll-content>
    </ion-infinite-scroll>
  }
  </div>
</ion-content>
