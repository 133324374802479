import { Component, Input, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular/standalone';
import { SharedModule } from '../../../shared.module';
import { OnboardingComponent } from '../onboarding/onboarding.component';
import {
  IonHeader,
  IonToolbar,
  IonButtons,
  IonButton,
  IonContent,
} from '@ionic/angular/standalone';

@Component({
  selector: 'app-connect-account',
  templateUrl: './connect-account.component.html',
  styleUrls: ['./connect-account.component.scss'],
  standalone: true,
  imports: [
    SharedModule,
    OnboardingComponent,
    IonHeader,
    IonToolbar,
    IonButtons,
    IonButton,
    IonContent,
  ],
})
export class ConnectAccountComponent implements OnInit {
  @Input() accountId: string;

  constructor(
    private modalCtrl: ModalController,
  ) {}

  // eslint-disable-next-line @angular-eslint/no-empty-lifecycle-method
  ngOnInit() {}

  cancel() {
    this.modalCtrl.dismiss(null, 'cancel');
  }
}
