import { Component, OnInit } from '@angular/core';
import { NavController } from '@ionic/angular/standalone';
import { ActivatedRoute } from '@angular/router';
import { CharityManagementService } from '../../../../../core/services/charity/charity-management.service';
import { CharityClaim } from '../../../../../core/models/CharityClaim';
import { ClaimStatus } from '../../../../../core/constants/ClaimStatus';
import {
  IonHeader,
  IonToolbar,
  IonButton,
  IonContent,
  IonItem,
  IonLabel,
  IonText,
  IonNote,
} from '@ionic/angular/standalone';

@Component({
  selector: 'app-manage-charity',
  templateUrl: './manage-charity.component.html',
  styleUrls: ['./manage-charity.component.scss'],
  standalone: true,
  imports: [IonHeader, IonToolbar, IonButton, IonContent, IonItem, IonLabel, IonText, IonNote],
})
export class ManageCharityComponent implements OnInit {
  protected readonly ClaimStatus = ClaimStatus;
  claims: CharityClaim[];
  userId: string;

  constructor(
    private navController: NavController,
    private activatedRoute: ActivatedRoute,
    private manageCharityService: CharityManagementService,
  ) {}

  ngOnInit() {
    this.userId = this.activatedRoute.snapshot.paramMap.get('id');
    this.getClaims();
  }

  getClaims() {
    if (this.userId) {
      this.manageCharityService.getCharityClaimsByUserId(this.userId).subscribe(
        res => {
          this.claims = res;
        },
        error => {
          console.error('Error getting charity claims', error);
        },
      );
    } else {
      this.claims = [];
    }
  }

  back() {
    this.navController.back();
  }

  goToClaimCharity() {
    this.navController.navigateForward(['menu/search-charity/' + this.userId]).then(r => r);
  }

  //test charity_id: 870212465
  goToCharityManagementPage(id: string) {
    this.navController.navigateForward(['charity-role-tabs/charity-info/' + id]).then(r => r);
  }
}
