import { Component, inject, OnInit, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { NavController } from '@ionic/angular/standalone';
import { User } from '../../../core/models/User';
import { Subscription } from 'rxjs';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';

import {
  IonHeader,
  IonToolbar,
  IonTitle,
  IonButton,
  IonContent,
  IonList,
  IonItem,
  IonLabel,
  IonText,
  IonNote,
} from '@ionic/angular/standalone';
import { Router } from '@angular/router';

import { AuthService } from 'src/app/core/services/auth/auth.service';
import { CharityManagementService } from 'src/app/core/services/charity/charity-management.service';
import { CommonModule } from '@angular/common';

@Component({
  selector: 'app-menu',
  templateUrl: './menu.page.html',
  styleUrls: ['./menu.page.scss'],
  standalone: true,
  imports: [
    IonHeader,
    IonToolbar,
    IonTitle,
    IonButton,
    IonContent,
    IonList,
    IonItem,
    IonLabel,
    IonText,
    IonNote,
    CommonModule
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class MenuPage implements OnInit {
  loggedInUser: User;
  authority: string;
  charityId: string;
  private readonly subscriptions = new Subscription();

  authService = inject(AuthService);
  avatarUrl: SafeResourceUrl = 'assets/ic-profile-avatar.svg';

  constructor(
    private navController: NavController,
    private router: Router,
    private domSanitizer: DomSanitizer,
    private charityManagementService: CharityManagementService
  ) {}

  ngOnInit() {
    this.getLoggedInUserInfo();
    this.authService.getUserAthority().subscribe(
      (data) => {
        this.authority = data.authority;
        if(this.authority === "ROLE_CHARITY"){
          this.getCharityId(data.userId);
        }
      }
    )
  }

  // ionViewWillEnter() {
  //   this.authService.refreshCurrentUser();
  // }

  getLoggedInUserInfo() {
    this.subscriptions.add(
      this.authService.user$.subscribe(user => {
        if (user) {
          this.loggedInUser = user;
          this.avatarUrl = this.domSanitizer.bypassSecurityTrustUrl(user.avatarUrl);
        }
      }),
    );
  }

  getCharityId(userId: string) {
    this.charityManagementService.getCharityClaimsByUserId(userId).subscribe(
      (data) => {
        this.charityId = data[0].charityId
      }
    )
  }

  async signOut(): Promise<void>  {
    await this.authService.logout();
    this.navController.navigateRoot('/start');
  }

  back() {
    this.navController.back();
  }

  goToItem(item: string) {
    this.navController
      .navigateForward(['/menu/' + item + '/' + this.loggedInUser?.id])
      .then(r => r);
  }

  addProfilePicture() {
    this.navController.navigateForward(['/edit-profile']).then(r => r);
  }

  switchToCharity() {
    this.navController.navigateRoot(['charity-role-tabs/charity-info/' + this.charityId]).then(r => r);
  }
}
