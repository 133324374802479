import { CommonModule } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { NavController } from '@ionic/angular/standalone';
import { IonContent } from '@ionic/angular/standalone';
import { AuthService } from 'src/app/core/services/auth/auth.service';

@Component({
  selector: 'app-start',
  templateUrl: './start.page.html',
  styleUrls: ['./start.page.scss'],
  standalone: true,
  imports: [CommonModule, ReactiveFormsModule, IonContent],
})
export class StartPage implements OnInit {
  constructor(
    private navController: NavController,
    private authService: AuthService
  ) {}

  async ngOnInit() {
    const session: string = await sessionStorage.getItem('accessToken');
    if (session) {
      this.navController.navigateRoot('/user-role-tabs');
    } else {
      this.navController.navigateRoot('/welcome');
    }
  }
}
