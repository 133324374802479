import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { authGuard } from './core/services/auth/auth.guard';
import { UserRoleTabsComponent } from './shared/components/user-role-tabs/user-role-tabs.component';
import { WelcomePage } from './auth/welcome/welcome.page';
import { LoginPage } from './auth/login/login.page';
import { StartPage } from './auth/start/start.page';
import { SignupPage } from './auth/signup/signup.page';
import { NotificationsPage } from './shared/components/notifications/notifications.page';
import { SearchPage } from './shared/components/search/search.page';
import { ResetPasswordPage } from './auth/reset-password/reset-password.page';
import { ForgotPasswordPage } from './auth/forgot-password/forgot-password.page';
import { ManageCharityComponent } from './shared/components/menu/charity-management/manage-charity/manage-charity.component';
import { CharityRoleTabsComponent } from './shared/components/charity-role-tabs/charity-role-tabs.component';
import { MenuPage } from './shared/components/menu/menu.page';
import { GivingComponent } from './shared/components/menu/giving/giving.component';
import { TransferComponent } from './shared/components/menu/transfer/transfer.component';
import { SecurityComponent } from './shared/components/menu/security/security.component';
import { SettingsComponent } from './shared/components/menu/settings/settings.component';
import { SupportComponent } from './shared/components/menu/support/support.component';
import { ProfilePage } from './role-user/tab-account/profile/profile.page';
import { HomePage } from './role-user/tab-home/home/home.page';
import { GivingPage } from './role-user/tab-giving/giving/giving.page';
import { GivingHistoryPage } from './role-user/giving-history/giving-history.page';
import { CharityPage } from './role-user/tab-giving/charity/charity.page';
import { SocialPage } from './role-user/tab-social/social/social.page';
import { DiscoverPage } from './role-user/tab-social/discover/discover.page';
import { DonationConfirmationComponent } from './role-user/tab-giving/donation-confirmation/donation-confirmation.component';
import { EditProfilePage } from './role-user/tab-account/edit-profile/edit-profile.page';
import { CharityInfoPage } from './role-charity/tab-charity/charity-info/charity-info.page';
import { CharityEventPage } from './role-charity/tab-event/charity-event/charity-event.page';
import { CharityCommercePage } from './role-charity/tab-commerce/charity-commerce/charity-commerce.page';
import { CharityProfilePage } from './role-charity/tab-profile/charity-profile/charity-profile.page';
import { CharityNewsfeedPage } from './role-charity/tab-newsfeed/charity-newsfeed/charity-newsfeed.page';
import { CharityRoleMenuPage } from './shared/components/charity-role-menu/charity-role-menu.page';
import { CharityInfoComponent } from './shared/components/charity-role-menu/charity-info/charity-info.component';
import { CharityTransfersComponent } from './shared/components/charity-role-menu/charity-transfers/charity-transfers.component';
import { CharityAccountComponent } from './shared/components/charity-role-menu/charity-account/charity-account.component';
import { CommercePage } from './role-user/tab-commerce/commerce/commerce.page';
import { MarketplaceComponent } from './role-user/tab-commerce/marketplace/marketplace.component';
import { AuctionsComponent } from './role-user/tab-commerce/auctions/auctions.component';
import { CharityAuctionComponent } from './role-user/tab-commerce/charity-auction/charity-auction.component';
import { ItemDetailComponent } from './role-user/tab-commerce/item-detail/item-detail.component';
import { ItemDetailAuctionComponent } from './role-user/tab-commerce/item-detail-auction/item-detail-auction.component';
import { SearchCharityComponent } from './shared/components/menu/charity-management/search-charity/search-charity.component';

const routes: Routes = [
  {
    path: '',
    redirectTo: 'start',
    pathMatch: 'full',
  },
  {
    path: 'start',
    component: StartPage,
  },
  {
    path: 'welcome',
    component: WelcomePage,
  },
  {
    path: 'login',
    component: LoginPage,
  },
  {
    path: 'signup',
    component: SignupPage,
  },
  {
    path: 'reset-password',
    component: ResetPasswordPage,
  },
  {
    path: 'forgot-password',
    component: ForgotPasswordPage,
  },
  {
    path: 'charity-role-tabs',
    component: CharityRoleTabsComponent,
    canActivate: [authGuard],
    children: [
      {
        path: '',
        pathMatch: 'full',
        redirectTo: 'charity-info/:id',
      },
      {
        path: 'charity-info/:id',
        component: CharityInfoPage,
      },
      {
        path: 'charity-event',
        component: CharityEventPage,
      },
      {
        path: 'charity-commerce',
        component: CharityCommercePage,
      },
      {
        path: 'charity-profile',
        component: CharityProfilePage,
      },
      {
        path: 'charity-newsfeed',
        component: CharityNewsfeedPage,
      },
    ],
  },
  {
    path: 'user-role-tabs',
    component: UserRoleTabsComponent,
    canActivate: [authGuard],
    children: [
      {
        path: '',
        pathMatch: 'full',
        redirectTo: 'home',
      },
      {
        path: 'profile',
        component: ProfilePage,
      },
      {
        path: 'home',
        component: HomePage,
      },
      {
        path: 'giving',
        component: GivingPage,
      },
      {
        path: 'history',
        component: GivingHistoryPage,
      },
      {
        path: 'charity/:id',
        component: CharityPage,
      },
      {
        path: 'social',
        component: SocialPage,
      },
      {
        path: 'commerce',
        component: CommercePage,
      },
      {
        path: 'marketplace',
        component: MarketplaceComponent,
      },
      {
        path: 'auction',
        component: AuctionsComponent,
      },
      {
        path: 'discover',
        component: DiscoverPage,
      },
      {
        path: 'charity-auction/:id',
        component: CharityAuctionComponent,
      },
      {
        path: 'item-detail/:id',
        component: ItemDetailComponent,
      },
      {
        path: 'item-detail-auction/:id',
        component: ItemDetailAuctionComponent,
      },
    ],
  },
  {
    path: 'donation-confirmation',
    canActivate: [authGuard],
    component: DonationConfirmationComponent,
  },
  {
    path: 'donation-confirmation/:id',
    canActivate: [authGuard],
    component: DonationConfirmationComponent,
  },
  {
    path: 'edit-profile',
    canActivate: [authGuard],
    component: EditProfilePage,
  },
  {
    path: 'notifications',
    canActivate: [authGuard],
    component: NotificationsPage,
  },
  {
    path: 'menu',
    canActivate: [authGuard],
    component: MenuPage,
  },
  {
    path: 'menu/summary/:id',
    canActivate: [authGuard],
    component: GivingComponent,
  },
  {
    path: 'menu/transfer/:id',
    canActivate: [authGuard],
    component: TransferComponent,
  },
  {
    path: 'menu/security/:id',
    canActivate: [authGuard],
    component: SecurityComponent,
  },
  {
    path: 'menu/settings/:id',
    canActivate: [authGuard],
    component: SettingsComponent,
  },
  {
    path: 'menu/support/:id',
    canActivate: [authGuard],
    component: SupportComponent,
  },
  {
    path: 'menu/search-charity/:id',
    canActivate: [authGuard],
    component: SearchCharityComponent,
  },
  {
    path: 'menu/manage-charity/:id',
    canActivate: [authGuard],
    component: ManageCharityComponent,
  },
  {
    path: 'charity-role-menu',
    canActivate: [authGuard],
    component: CharityRoleMenuPage,
  },
  {
    path: 'charity-role-menu/summary',
    canActivate: [authGuard],
    component: CharityInfoComponent,
  },
  {
    path: 'charity-role-menu/transfer',
    canActivate: [authGuard],
    component: CharityTransfersComponent,
  },
  {
    path: 'charity-role-menu/account',
    canActivate: [authGuard],
    component: CharityAccountComponent,
  },
  {
    path: 'search',
    canActivate: [authGuard],
    component: SearchPage,
  },
  {
    path: '**',
    redirectTo: '',
    pathMatch: 'full',
  },
];
@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      preloadingStrategy: PreloadAllModules,
      onSameUrlNavigation: 'reload',
    }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
