import { Component, OnDestroy, OnInit } from '@angular/core';
import { SharedModule } from '../../../shared/shared.module';
import { CharityChartComponent } from 'src/app/role-user/tab-giving/charity/charity-chart/charity-chart.component';
import { Subscription } from 'rxjs';
import { Chart } from '../../../core/constants/Chart';
import { CharityService } from '../../../core/services/charity/charity.service';
import { CharityDataService } from '../../../core/services/graph/charity-data.service';
import { ActivatedRoute } from '@angular/router';
import { TimeSeriesChartService } from '../../../core/services/graph/time-series-chart.service';
import { DatePipe, TitleCasePipe } from '@angular/common';
import { Charity } from '../../../core/models/Charity';
import { Donation } from '../../../core/models/Donation';
import {
  IonHeader,
  IonToolbar,
  IonContent,
  IonThumbnail,
  IonGrid,
  IonRow,
  IonCol,
  IonItem,
} from '@ionic/angular/standalone';
import { AuthService } from 'src/app/core/services/auth/auth.service';

@Component({
  selector: 'app-charity-info',
  templateUrl: './charity-info.page.html',
  styleUrls: ['./charity-info.page.scss'],
  standalone: true,
  imports: [
    SharedModule,
    CharityChartComponent,
    TitleCasePipe,
    DatePipe,
    IonHeader,
    IonToolbar,
    IonContent,
    IonThumbnail,
    IonGrid,
    IonRow,
    IonCol,
    IonItem,
  ],
})
export class CharityInfoPage implements OnInit, OnDestroy {
  private readonly subscriptions = new Subscription();
  timeRangeText: string = Chart.TODAY_TEXT;

  charityId: string;
  charity: Charity;

  moneyDonations: Donation[] = [];
  hourDonations: Donation[] = [];
  moneyDonationHistory: Donation[] = [];

  totalMoneyDonation = 0;
  totalHourDonation = 0;
  totalRangeMoneyDonation = 0;
  totalRangeHourDonation = 0;

  totalDonatedMoneyForCharity = 0;
  todayDonationForCharity = 0;
  totalDonatedMoneyToCharity = 1;

  isHourMode: boolean = false;

  constructor(
    private charityService: CharityService,
    private charityDataService: CharityDataService,
    private authService: AuthService,
    private activatedRoute: ActivatedRoute,
  ) {}

  ngOnInit() {
    this.charityId = this.activatedRoute.snapshot.paramMap.get('id');
    this.subscriptions.add(
      this.authService.user$.subscribe(user => {
        if (user) {
          this.getCharityById(this.charityId);
          this.getCharityChartDataWithTimeRange('today');
          this.getCharityChartData();
          this.getCharityPosition();
          this.getDonationsByUser(user?.id);
        }
      }),
    );
  }

  getDonations() {
    return !this.isHourMode ? this.moneyDonations : this.hourDonations;
  }

  getDonationsByUser(id: string) {
    this.subscriptions.add(
      this.charityDataService
        .getDonationHistoryForCharityByUserId(id, this.charityId, 'MONEY')
        .subscribe(data => {
          if (data) {
            this.totalDonatedMoneyToCharity = data.reduce((a, b) => a + b.amount, 0);
          }
        }),
    );
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  getCharityPosition() {
    this.charityDataService.getDonationsByCharityId(this.charityId, 'MONEY').subscribe(res => {
      this.moneyDonationHistory = res;
      this.moneyDonationHistory.forEach(d => {
        if (d.timestamp > Date.now() - 24 * 60 * 60 * 100) {
          this.todayDonationForCharity = this.todayDonationForCharity + d.amount;
        }
      });
    });
  }

  getCharityById(id: string) {
    if (id) {
      this.subscriptions.add(
        this.charityService.getCharity(id).subscribe(res => {
          this.charity = res;
        }),
      );
    }
  }

  getCharityChartData() {
    this.subscriptions.add(
      this.charityDataService.getDonationsByCharityId(this.charityId, 'MONEY').subscribe(res => {
        if (res) {
          this.totalMoneyDonation = res.reduce((a, b) => a + b.amount, 0);
        }
      }),
    );

    this.subscriptions.add(
      this.charityDataService.getDonationsByCharityId(this.charityId, 'HOUR').subscribe(res => {
        if (res) {
          this.totalHourDonation = res.reduce((a, b) => a + b.amount, 0);
        }
      }),
    );

    // Get total all money donations given by auth
    this.subscriptions.add(
      this.charityDataService.getDonationsByUserId('', 'MONEY').subscribe(res => {
        if (res) {
          this.totalDonatedMoneyForCharity = res.reduce((a, b) => a + b.amount, 0);
        }
      }),
    );
  }

  getCharityChartDataWithTimeRange(tab: string) {
    const numOfDays: number = TimeSeriesChartService.convertStringTabToNumOfDays(tab);

    this.charityDataService.getDonationsByCharityId(this.charityId, 'MONEY').subscribe(res => {
      this.totalMoneyDonation = res.reduce((a, b) => a + b.amount, 0);
      this.moneyDonations = res;
      this.totalRangeMoneyDonation = this.moneyDonations.reduce((a, b) => a + b?.amount, 0);
    });
    this.charityDataService.getDonationsByCharityId(this.charityId, 'HOUR').subscribe(res => {
      this.hourDonations = res;
      this.totalHourDonation = res.reduce((a, b) => a + b.amount, 0);
      this.totalRangeHourDonation = this.moneyDonations.reduce((a, b) => a + b?.amount, 0);
    });

    this.charityDataService
      .getDonationsByTimeRangeByCharityId(this.charityId, numOfDays, 'MONEY')
      .subscribe(res => {
        this.moneyDonations = res.sort((a, b) => a.timestamp - b.timestamp);
      });
    this.charityDataService
      .getDonationsByTimeRangeByCharityId(this.charityId, numOfDays, 'HOUR')
      .subscribe(res => {
        this.hourDonations = res.sort((a, b) => a.timestamp - b.timestamp);
      });
  }

  onSeeFollowers() {}

  onEditProfile() {}

  onTabSelected(e: string) {
    this.getCharityChartDataWithTimeRange(e);

    switch (true) {
      case e == 'today':
        this.timeRangeText = Chart.TODAY_TEXT;
        break;
      case e == '1w':
        this.timeRangeText = Chart.ONE_WEEK_TEXT;
        break;
      case e == '1m':
        this.timeRangeText = Chart.ONE_MONTH_TEXT;
        break;
      case e == '3m':
        this.timeRangeText = Chart.THREE_MONTH_TEXT;
        break;
      case e == '6m':
        this.timeRangeText = Chart.SIX_MONTH_TEXT;
        break;
      case e == '1y':
        this.timeRangeText = Chart.ONE_YEAR_TEXT;
        break;
      case e == 'all':
        this.timeRangeText = Chart.ALL_TEXT;
        break;
    }
  }
}
