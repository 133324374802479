<ion-header class="ion-no-border">
  <ion-toolbar>
    <ion-button fill="clear" (click)="back()">
      <img src="assets/ic-back-clay.svg" alt="navigate back button" />
    </ion-button>
  </ion-toolbar>
</ion-header>

<ion-content [fullscreen]="true">
  <div class="container">
    <h3 class="header">Switch Account</h3>

    <p>Select the account you wish to access</p>

    <ion-list>
      <ion-item [button]="true" (click)="goToPersonalAccount()">
        <ion-thumbnail slot="start">
          <img
            alt="Silhouette of mountains"
            src="https://ionicframework.com/docs/img/demos/thumbnail.svg"
          />
        </ion-thumbnail>
        <ion-label>
          <ion-text>Personal Account</ion-text>
          <br />
        </ion-label>
      </ion-item>
    </ion-list>
  </div>
</ion-content>
