import { Component, EventEmitter, Output, Input } from '@angular/core';
import { IonToggle } from '@ionic/angular/standalone';

@Component({
  selector: 'app-donate-mode-toggle',
  templateUrl: './donate-mode-toggle.component.html',
  styleUrls: ['./donate-mode-toggle.component.scss'],
  standalone: true,
  imports: [IonToggle],
})
export class DonateModeToggleComponent {
  @Input() isHourMode = false;
  @Input() lightStyle = false;
  @Output() toggle = new EventEmitter<boolean>();

  constructor() {}

  onToggle() {
    this.toggle.emit();
  }
}
