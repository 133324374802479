import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { GivingSegment } from '../../models/GivingSegment';
import { HttpClientWrapperService } from '../http/http-client-wrapper.service';
import { environment } from '../../../../environments/environment';

@Injectable({
  providedIn: 'root',
})
export class BubbleChartService {
  private readonly baseApiUrl = `${environment.beApiUrl}/api/v1/giving-segments`;

  constructor(private httpClient: HttpClientWrapperService) {}

  getGivingSegments(userId: string): Observable<GivingSegment[]> {
    return this.httpClient.get<GivingSegment[]>(`${this.baseApiUrl}/${userId}`);
  }
}
