import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ModalController } from '@ionic/angular/standalone';
import { SharedModule } from '../../../shared.module';
import { StripePaymentService } from '../../../../core/services/payment/stripe-payment.service';
import { Donation } from '../../../../core/models/Donation';
import { IonHeader, IonToolbar, IonContent } from '@ionic/angular/standalone';
import { StripeEmbeddedCheckout } from '@stripe/stripe-js';

@Component({
  selector: 'app-checkout',
  templateUrl: './checkout.component.html',
  styleUrls: ['./checkout.component.scss'],
  standalone: true,
  imports: [SharedModule, IonHeader, IonToolbar, IonContent],
})
export class CheckoutComponent implements OnInit {
  @Input() accountId: string;
  @Input() donation: Donation;
  @Input() charityName: string;
  @Output() paymentProcessed = new EventEmitter<boolean>();

  constructor(
    private modalCtrl: ModalController,
    private stripeService: StripePaymentService
  ) {}

  ngOnInit() {
    this.checkout();
  }

  cancel() {
    this.modalCtrl.dismiss(null, 'cancel');
  }

  async checkout() {
    // Initialize Checkout
    const currentCheckout: StripeEmbeddedCheckout = await this.stripeService.getCheckoutInstance();
    if (currentCheckout) {
      await this.stripeService.destroyStripeCheckout();
    }

    this.stripeService
      .loadStripeAccount(this.accountId, this.charityName, this.donation)
      .subscribe(checkout => {
        checkout.mount('#checkout');
      });

    // Mount Checkout
  }
}
