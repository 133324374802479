import { Component, OnInit } from '@angular/core';
import { NavController } from '@ionic/angular/standalone';
import { addIcons } from 'ionicons';
import { searchOutline, notifications, menu } from 'ionicons/icons';
import {
  IonHeader,
  IonToolbar,
  IonButton,
  IonButtons,
  IonIcon,
  IonContent,
  IonRefresher,
  IonRefresherContent,
  IonTabs,
  IonTabBar,
  IonTabButton,
} from '@ionic/angular/standalone';

@Component({
  selector: 'app-charity-role-tabs',
  templateUrl: './charity-role-tabs.component.html',
  styleUrls: ['./charity-role-tabs.component.scss'],
  standalone: true,
  imports: [
    IonHeader,
    IonToolbar,
    IonButton,
    IonButtons,
    IonIcon,
    IonContent,
    IonRefresher,
    IonRefresherContent,
    IonTabs,
    IonTabBar,
    IonTabButton,
  ],
})
export class CharityRoleTabsComponent implements OnInit {
  currentTab = 'charity-info';
  isMainPage: boolean = true;
  constructor(private navController: NavController) {
    addIcons({ searchOutline, notifications, menu });
  }

  // eslint-disable-next-line @angular-eslint/no-empty-lifecycle-method
  ngOnInit() {}

  setCurrentTab(e) {
    this.currentTab = e.tab;
    this.isMainPage = !(
      e.tab == 'menu' ||
      e.tab == 'search' ||
      e.tab == 'notifications' ||
      e.tab == 'edit-profile'
    );
  }

  goToMenuPage() {
    this.navController.navigateForward(['/charity-role-menu']).then(r => r);
  }

  goToNotificationsPage() {
    this.navController.navigateForward(['/notifications']).then(r => r);
  }

  goToSearchPage() {
    this.navController.navigateForward(['/search']).then(r => r);
  }

  back() {
    this.navController.back();
  }
}
