/* eslint-disable @typescript-eslint/typedef */
export const Chart = {
  SUNNY_GREEN: '#88C28B',
  SUNNY_ORANGE: '#E26037',
  SUNNY_YELLOW: '#F9B418',
  SUNNY_CORAL: '#FA8668',

  TODAY: 1, // one day
  ONE_WEEK: 7,
  ONE_MONTH: 30,
  THREE_MONTH: 90,
  SIX_MONTH: 180,
  ONE_YEAR: 365,
  ALL: 2000, //TODO: need to find a better solution

  TODAY_TEXT: 'Today',
  ONE_WEEK_TEXT: 'Past 7 Days',
  ONE_MONTH_TEXT: 'Past 30 Days',
  THREE_MONTH_TEXT: 'Past 60 Days',
  SIX_MONTH_TEXT: 'Past 120 Days',
  ONE_YEAR_TEXT: 'Past 365 Days',
  ALL_TEXT: 'All',

  GIVING_CHART_HTML_ID: 'timeSeriesGivingChart',
  CHARITY_CHART_HTML_ID: 'timeSeriesCharityChart',

  PERSONAL_MINI_CHART_HTML_ID: 'timeSeriesMiniChart',
  FRIEND_MINI_CHART_HTML_ID: 'timeSeriesFriendMiniChart',

  CHARITY_MINI_CHART_HTML_ID: 'timeSeriesCharityMiniChart',
};

/* eslint-disable @typescript-eslint/typedef */
export const getPlotlyConfig = () => {
  return {
    displaylogo: false,
    responsive: true,
    displayModeBar: false,
  };
};

// BIG CHART LAYOUT
/* eslint-disable @typescript-eslint/typedef */
export const getLayoutForBigChart = () => {
  return {
    title: null,
    autosize: true,
    margin: { l: 10, r: 10, b: 10, t: 20 },
    height: 170,
    hovermode: 'closest',
    hoverdistance: -1,
    autorange: false,
    hoverlabel: {
      bordercolor: '#ffffff',
    },
    xaxis: {
      type: 'date',
      range: [],
      domain: [0, 1],
      showticklabels: false,
      showline: false,
      showgrid: false,
      zeroline: false,
      fixedrange: true,
    },
    yaxis: {
      range: [],
      domain: [0, 1],
      zeroline: false,
      showticklabels: false,
      showline: false,
      showgrid: false,
      fixedrange: true,
    },
    shapes: [
      {
        type: 'line',
        line: {
          color: '#000',
          width: 2,
        },
        visible: false,
      },
    ],
    annotations: [
      {
        showarrow: false,
        visible: false,
        yshift: 18,
      },
    ],
  };
};

// MINI CHART LAYOUT

/* eslint-disable @typescript-eslint/typedef */
export const getLayoutForMiniChart = () => {
  return {
    title: null,
    autosize: true,
    margin: { l: 10, r: 10, b: 10, t: 0 },
    height: 30,
    width: 70,
    hovermode: false,

    xaxis: {
      type: 'date',
      range: [],
      domain: [0, 1],
      showticklabels: false,
      showline: false,
      showgrid: false,
      zeroline: false,
      fixedrange: true,
    },
    yaxis: {
      range: [],
      domain: [0, 1],
      zeroline: false,
      showticklabels: false,
      showline: false,
      showgrid: false,
      fixedrange: true,
    },
  };
};

// BIG CHART TRACE

/* eslint-disable @typescript-eslint/typedef */
export const getEmptyBigChartTrace = (isHourMode: boolean) => {
  return {
    x: [],
    y: [],
    type: 'scatter',
    mode: 'lines+markers',
    line: {
      color: !isHourMode ? Chart.SUNNY_GREEN : Chart.SUNNY_ORANGE,
      width: 2,
    },
    marker: {
      size: 4.5,
      color: !isHourMode ? Chart.SUNNY_GREEN : Chart.SUNNY_ORANGE,
    },
    showlegend: false,
    hovertemplate: '',
  };
};

// MINI CHARTS TRACE

/* eslint-disable @typescript-eslint/typedef */
export const getEmptyMiniPersonalChartTrace = () => {
  return {
    x: [],
    y: [],
    type: 'scatter',
    mode: 'lines',
    line: {
      color: Chart.SUNNY_YELLOW,
      width: 1.3,
    },
    showlegend: false,
    hovertemplate: '',
  };
};

/* eslint-disable @typescript-eslint/typedef */
export const getEmptyMiniFriendChartTrace = () => {
  return {
    x: [],
    y: [],
    type: 'scatter',
    mode: 'lines',
    line: {
      color: Chart.SUNNY_CORAL,
      width: 1.3,
    },
    showlegend: false,
    hovertemplate: '',
  };
};
