import { Injectable } from '@angular/core';
import * as Plotly from 'plotly.js-dist';

@Injectable({
  providedIn: 'root',
})
export class PieChartService {
  renderPieChart() {
    const data: Partial<Plotly> = [
      {
        values: [10, 20, 70],
        labels: ['Environment', 'Culture', 'Health'],
        hole: 0.94,
        type: 'pie',
        textinfo: 'none',
        marker: {
          colors: ['#393D39', '#BBE2BD', '#E26037'],
        },
      },
    ];

    const layout: Partial<Plotly> = {
      height: 250,
      width: 250,
      showlegend: false,
      autosize: true,
      hovermode: false,
      margin: { l: 5, r: 20, b: 0, t: 0 },
    };

    const config: Partial<Plotly> = {
      displaylogo: false,
      responsive: true,
      displayModeBar: false,
    };

    Plotly.newPlot('myDiv', data, layout, config);
  }
}
