import { Component, OnInit } from '@angular/core';
import {
  FormBuilder,
  FormGroup,
  Validators,
  FormsModule,
  ReactiveFormsModule,
} from '@angular/forms';
import { NavController } from '@ionic/angular/standalone';
import { AuthService } from '../../core/services/auth/auth.service';
import { FormValidators } from '../../core/utilities/form-validators';
import { AlertService } from '../../core/services/navigation/alert.service';
import {
  IonHeader,
  IonToolbar,
  IonIcon,
  IonContent,
  IonItem,
  IonInput,
  IonButton,
} from '@ionic/angular/standalone';

@Component({
  selector: 'app-forgot-password',
  templateUrl: './forgot-password.page.html',
  styleUrls: ['./forgot-password.page.scss'],
  standalone: true,
  imports: [
    FormsModule,
    ReactiveFormsModule,
    IonHeader,
    IonToolbar,
    IonIcon,
    IonContent,
    IonItem,
    IonInput,
    IonButton,
  ],
})
export class ForgotPasswordPage implements OnInit {
  sendEmailForm: FormGroup;
  emailSent = false;

  constructor(
    private fb: FormBuilder,
    private navController: NavController,
    private alertService: AlertService,
    private authService: AuthService,
  ) {}

  ngOnInit() {
    this.sendEmailForm = this.fb.group({
      email: ['', [Validators.required, FormValidators.email]],
    });
  }

  goToLoginPage() {
    this.navController.navigateBack(['/login']);
  }

  onSendResetPasswordEmail() {
    //
  }
}
