import { Injectable } from '@angular/core';
import { AlertController } from '@ionic/angular/standalone';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class AlertService {
  constructor(private alertController: AlertController) {}

  showAlertMessage(title, msg) {
    this.alertController
      .create({
        header: title,
        message: msg,
        buttons: ['OK'],
        cssClass: 'alert-message',
      })
      .then((alert: HTMLIonAlertElement) => {
        alert.present();
      });
  }

  showAlertForDonation(title, msg) {
    return new Observable(observer => {
      let alertHandler: HTMLIonAlertElement;
      this.alertController
        .create({
          header: title,
          message: msg,
          buttons: [
            {
              text: 'Yes',
              role: 'yes',
            },
            {
              text: 'No',
              role: 'no',
              handler: () => {
                alertHandler.dismiss();
                return false;
              },
            },
          ],
          cssClass: 'alert-message',
        })
        .then((alert: HTMLIonAlertElement) => {
          alertHandler = alert;
          alertHandler.present();
          observer.next(alertHandler);
          observer.complete();
        });
    });
  }
}
