import { Injectable } from '@angular/core';
import { environment } from '../../../../environments/environment';
import { loadConnectAndInitialize } from '@stripe/connect-js';
import { loadStripe, StripeEmbeddedCheckout } from '@stripe/stripe-js';
import { Observable } from 'rxjs';
import { StripeCustomer } from '../../models/stripe/StripeCustomer';
import { HttpClientWrapperService, Params } from '../http/http-client-wrapper.service';
import { Donation, DonationStatus, StripeAccount } from '../../models/Donation';
import { AuthService } from '../auth/auth.service';
import { User } from '../../models/User';

@Injectable({
  providedIn: 'root',
})
export class StripePaymentService {
  private readonly baseApiUrl = `${environment.beApiUrl}/api/v1/stripe`;
  private readonly stripePublishableKey = `${environment.stripePublishableKey}`;
  private baseUrl: string;
  private currentCheckout: StripeEmbeddedCheckout | null = null;
  loggedInUser: User

  constructor(private httpClient: HttpClientWrapperService, private authService: AuthService) {
    this.baseUrl = environment.baseUrl;
    this.authService.user$.subscribe(user => {
      if (user) {
        this.loggedInUser = user;
      }
    })
  }

  createConnectAccount(): Observable<string> {
    return new Observable(observer => {
      this.httpClient.post<StripeAccount, Params>(`${this.baseApiUrl}/account`, {}).subscribe(
        res => {
          observer.next(res?.account_id);
          observer.complete();
        },
        err => {
          observer.error(err);
          observer.complete();
        },
      );
    });
  }
  createNewCustomer(customer: StripeCustomer): Observable<string> {
    return this.httpClient.post<string, StripeCustomer>(`${this.baseApiUrl}/customers`, customer);
  }

  createAccountLink(accountId: string): Observable<string> {
    return this.httpClient.post<string, Params>(`${this.baseApiUrl}/account/${accountId}/link`, {});
  }

  createCheckoutSession(
    accountId: string,
    charityName: string,
    donation: Donation,
  ): Observable<string> {
    return new Observable<string>(observer => {
      this.httpClient
        .post<StripeAccount, Params>(`${this.baseApiUrl}/checkout/session?accountId=${accountId}`, {
          charityId: donation.charityId,
          name: charityName,
          amount: donation.amount,
          fee: donation.feeAmount,
          base_url: this.baseUrl,
          userId: this.loggedInUser.id
        })
        .subscribe(
          res => {
            observer.next(res?.client_secret);
            observer.complete();
          },
          err => {
            observer.error(err);
            observer.complete();
          },
        );
    });
  }

  createAccountSession(accountId: string): Observable<string> {
    return new Observable<string>(observer => {
      this.httpClient
        .post<StripeAccount, Params>(`${this.baseApiUrl}/account/${accountId}/session`, {})
        .subscribe(
          res => {
            observer.next(res?.client_secret);
            observer.complete();
          },
          err => {
            observer.error(err);
            observer.complete();
          },
        );
    });
  }

  getStripeDonationStatus(id: string, accountId: string): Observable<DonationStatus> {
    return this.httpClient.get<DonationStatus>(
      `${this.baseApiUrl}/checkout/${id}/status?accountId=${accountId}`,
    );
  }

  loadStripeAccount(
    accountId: string,
    charityName: string,
    donation: Donation,
  ): Observable<StripeEmbeddedCheckout> {
    return new Observable(observer => {
      loadStripe(this.stripePublishableKey, { stripeAccount: accountId })
        .then(stripe => {
          stripe
            .initEmbeddedCheckout({
              fetchClientSecret: () =>
                this.createCheckoutSession(accountId, charityName, donation).toPromise(),
            })
            .then(res => {
              this.currentCheckout = res;
              observer.next(res);
              observer.complete();
            })
            .catch(err => {
              observer.error(err);
              observer.complete();
            });
        })
        .catch(err => {
          observer.error(err);
          observer.complete();
        });
    });
  }

  getCheckoutInstance(): StripeEmbeddedCheckout | null {
    return this.currentCheckout;
  }

  destroyStripeCheckout(): void {
    if (this.currentCheckout) {
      try {
        this.currentCheckout.destroy();
      } catch (err) {
        console.error('Error destroying checkout instance:', err);
      }
    }
  }

  initializeStripeConnectInstance(accountId: string) {
    /* eslint-disable  @typescript-eslint/typedef */
    const res = this.createAccountSession(accountId).toPromise();

    return loadConnectAndInitialize({
      publishableKey: this.stripePublishableKey,
      fetchClientSecret: () => res,
      appearance: {
        overlays: 'dialog',
        variables: {
          colorPrimary: '#F9B418',
          borderRadius: '24px',
          spacingUnit: '11px',
        },
      },
    });
  }
}
