import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClientWrapperService } from '../http/http-client-wrapper.service';
import { environment } from '../../../../environments/environment';
import { Donation } from '../../models/Donation';

interface APIParamsObj {
  userId: string;
  charityId: string;
  donationType: string;
  charitySegment: string;
  createdAfter: string;
  createdBefore: string;
}

@Injectable({
  providedIn: 'root',
})
export class CharityDataService {
  private readonly baseApiUrl = `${environment.beApiUrl}/api/v1/donations`;
  private donations: Observable<Donation[]>;

  constructor(private httpClient: HttpClientWrapperService) {}

  getDonations(options: Partial<APIParamsObj>, fetchNew: boolean = false): Observable<Donation[]> {
    if (this.donations == undefined || fetchNew) {
      const urlParams: URLSearchParams = new URLSearchParams();
      // prettier-ignore
      const {
        userId,
        charityId,
        donationType,
        charitySegment,
        createdAfter,
        createdBefore,
      } = options;

      const params: Array<{ key: string; value: string }> = [
        {
          key: 'userId',
          value: userId,
        },
        {
          key: 'charityId',
          value: charityId,
        },
        {
          key: 'donationType',
          value: donationType,
        },
        {
          key: 'charitySegment',
          value: charitySegment,
        },
        {
          key: 'createdAfter',
          value: createdAfter,
        },
        {
          key: 'createdBefore',
          value: createdBefore,
        },
      ];

      // Append parameters if they exist
      params.forEach(item => {
        if (item.value !== undefined) {
          urlParams.append(item.key, item.value);
        }
      });
      this.donations = this.httpClient.get<Donation[]>(`${this.baseApiUrl}?${urlParams}`);
    }
    return this.donations;
  }

  getDonationsByType(donationType: string): Observable<Donation[]> {
    return this.getDonations({ donationType }, true);
  }

  getDonationsByCharityId(charityId: string, donationType: string): Observable<Donation[]> {
    return this.getDonations({ charityId, donationType }, true);
  }

  getDonationsByUserId(userId: string, donationType: string): Observable<Donation[]> {
    return this.getDonations({ userId, donationType }, true);
  }

  // Get charity donations with time range
  getDonationsByTimeRangeByCharityId(
    charityId: string,
    numOfDays: number,
    donationType: string,
  ): Observable<Donation[]> {
    const secondsOfOneDay: number = 24 * 60 * 60 * 1000;
    const createdBefore: number = Date.now();
    const createdAfter: number = createdBefore - secondsOfOneDay * numOfDays;

    return this.getDonations(
      {
        charityId,
        donationType,
        createdAfter: String(createdAfter),
        createdBefore: String(createdBefore),
      },
      true,
    );
  }

  getDonationsByTimeRangeByUserId(userId: string, numOfDays: number): Observable<Donation[]> {
    const secondsOfOneDay: number = 24 * 60 * 60 * 1000;
    const createdBefore: number = Date.now();
    const createdAfter: number = createdBefore - secondsOfOneDay * numOfDays;

    return this.getDonations(
      { userId, createdAfter: String(createdAfter), createdBefore: String(createdBefore) },
      true,
    );
  }

  getDonationHistoryForCharityByUserId(
    userId: string,
    charityId: string,
    donationType: string,
  ): Observable<Donation[]> {
    return this.getDonations({ userId, charityId, donationType }, true);
  }

  getDonationsByUserWithSegmentAndType(
    userId: string,
    charitySegment: string,
    donationType: string,
  ): Observable<Donation[]> {
    return this.getDonations({ userId, charitySegment, donationType }, true);
  }

  addNewDonation(donation: Donation): Observable<Donation> {
    return this.httpClient.post<Donation, Donation>(`${this.baseApiUrl}`, donation);
  }

  getDonationById(id: string): Observable<Donation> {
    return this.httpClient.get<Donation>(`${this.baseApiUrl}/${id}`);
  }
}
