import { Component, OnDestroy, OnInit } from '@angular/core';
import { NavController, ModalController } from '@ionic/angular/standalone';
import { Subscription } from 'rxjs';
import { SharedModule } from '../../../shared/shared.module';
import { Donation } from '../../../core/models/Donation';
import { ActivatedRoute } from '@angular/router';
import { StripePaymentService } from '../../../core/services/payment/stripe-payment.service';
import { CharityDataService } from '../../../core/services/graph/charity-data.service';
import {
  IonHeader,
  IonToolbar,
  IonButtons,
  IonButton,
  IonContent,
  IonItem,
  IonLabel,
  IonText,
} from '@ionic/angular/standalone';

@Component({
  selector: 'app-donation-confirmation',
  templateUrl: './donation-confirmation.component.html',
  styleUrls: ['./donation-confirmation.component.scss'],
  standalone: true,
  imports: [
    SharedModule,
    IonHeader,
    IonToolbar,
    IonButtons,
    IonButton,
    IonContent,
    IonItem,
    IonLabel,
    IonText,
  ],
})
export class DonationConfirmationComponent implements OnInit, OnDestroy {
  private readonly subscriptions = new Subscription();

  isViewInvoice = false;

  todaysMoneyDonation: number = 0;
  averageMoneyDonation: number = 0;
  totalMoneyDonation: number = 0;
  moneyDonationHistory: Donation[];

  currentDonation: Donation;
  checkoutSessionId: string;
  donationStatus: string;

  constructor(
    private stripeService: StripePaymentService,
    private charityDataService: CharityDataService,
    private activatedRoute: ActivatedRoute,
    private navController: NavController,
    private modalCtrl: ModalController
  ) {}

  ngOnInit() {
    this.modalCtrl.dismiss();
    this.stripeService.destroyStripeCheckout();
    this.checkoutSessionId = this.activatedRoute.snapshot.queryParamMap.get('session_id');
    this.subscriptions.add(
      this.stripeService
        .getStripeDonationStatus(this.checkoutSessionId, 'acct_1PVcbkDBVigCH5BR')
        .subscribe(res => {
          this.donationStatus = res['status'];
          const donationStr: string = sessionStorage.getItem('current_donation');
          if (donationStr && this.donationStatus === 'complete') {
            this.currentDonation = JSON.parse(donationStr);
            this.saveCurrentDonation();
          }
        }),
    );
  }

  ngOnDestroy() {
    this.subscriptions.unsubscribe();
  }

  saveCurrentDonation() {
    this.subscriptions.add(
      this.charityDataService.addNewDonation(this.currentDonation).subscribe(
        () => {
          this.getUserDonationStats(this.currentDonation.userId, this.currentDonation.charityId);
        },
        error => {
          console.error('Error adding new donation', error);
        },
      ),
    );
  }

  viewDonationInvoice() {
    this.isViewInvoice = true;
  }

  downloadDonationReceipt() {}

  getUserDonationStats(userId: string, charityId: string) {
    this.todaysMoneyDonation = 0;
    this.totalMoneyDonation = 0;
    this.averageMoneyDonation = 0;

    this.charityDataService
      .getDonationHistoryForCharityByUserId(userId, charityId, 'MONEY')
      .subscribe(res => {
        if (res && res.length > 0) {
          this.moneyDonationHistory = res;

          this.totalMoneyDonation = +res.reduce((a, b) => a + b.amount, 0).toFixed(2);
          this.averageMoneyDonation = +(
            this.totalMoneyDonation / this.moneyDonationHistory.length
          ).toFixed(2);
          this.moneyDonationHistory.forEach(d => {
            if (d.timestamp > Date.now() - 24 * 60 * 60 * 100) {
              this.todaysMoneyDonation = +(this.todaysMoneyDonation + d.amount).toFixed(2);
            }
          });
      } else {
        this.totalMoneyDonation = this.currentDonation.amount;
        this.averageMoneyDonation = this.currentDonation.amount;
        this.todaysMoneyDonation = this.currentDonation.amount;
      }
      });
  }

  done() {
    this.navController
      .navigateRoot(['/user-role-tabs/charity/' + this.currentDonation?.charityId], {
        animated: true,
        animationDirection: 'forward'
      })
      .then(r => r);
  }
}
