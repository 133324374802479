<ion-header class="ion-no-border">
  @if (!isMainPage) {
    <ion-toolbar>
      <ion-button fill="clear" (click)="back()">
        <img src="../../../../assets/ic-back-clay.svg" alt="navigate back button" />
      </ion-button>
    </ion-toolbar>
  }

  @if (isMainPage) {
    <ion-toolbar>
      <ion-buttons slot="start">
        @if (currentTab === 'charity' || isSpecialPage) {
          <p class="btn-back" (click)="back()" (keydown)="back()" tabindex="{0}">
            <img src="../../../../assets/ic-back.svg" alt="back icon" />
          </p>
        }
        <p class="ss-logo">Sunny Street</p>
      </ion-buttons>
      <ion-buttons slot="end">
        <!-- Might bring back -->
        <!-- <ion-button (click)="goToSearchPage()" id="btn-ic-search" fill="clear">
          <ion-icon name="search-outline"></ion-icon>
        </ion-button> -->
        <ion-button (click)="goToNotificationsPage()" id="btn-ic-notification" fill="clear">
          <ion-icon name="notifications"></ion-icon>
        </ion-button>
        <ion-button (click)="goToMenuPage()" id="btn-ic-menu" fill="clear">
          <div class="profile-avatar">
            <img alt="avatar" [src]="avatarUrl" />
          </div>
        </ion-button>
      </ion-buttons>
    </ion-toolbar>
  }
</ion-header>

<ion-content [fullscreen]="true" force-overscroll="true">
  <ion-refresher
    slot="fixed"
    [pullFactor]="0.5"
    [pullMin]="100"
    [pullMax]="200"
    (ionRefresh)="handleRefresh($event)"
  >
    <ion-refresher-content></ion-refresher-content>
  </ion-refresher>

  <ion-tabs (ionTabsDidChange)="setCurrentTab($event)">
    <ion-tab-bar slot="bottom">
      <ion-tab-button tab="home">
        <ion-icon
          [src]="currentTab === 'home' ? '/assets/ic-trend.svg' : '/assets/ic-trend-outline.svg'"
        ></ion-icon>
      </ion-tab-button>

      <ion-tab-button tab="discover">
        <ion-icon
          [src]="currentTab === 'discover' ? '/assets/ic-hands.svg' : '/assets/ic-hands-outline.svg'"
        ></ion-icon>
      </ion-tab-button>

      <ion-tab-button tab="commerce">
        <ion-icon
          [src]="currentTab === 'commerce' ? '/assets/ic-bag.svg' : '/assets/ic-bag-outline.svg'"
        ></ion-icon>
      </ion-tab-button>

      <!-- <ion-tab-button tab="social">
        <ion-icon
          [src]="currentTab === 'social' ? '/assets/ic-feed.svg' : '/assets/ic-feed-outline.svg'"
        ></ion-icon>
      </ion-tab-button>

      <ion-tab-button tab="profile">
        <ion-icon
          [src]="
            currentTab === 'profile' ? '/assets/ic-person.svg' : '/assets/ic-person-outline.svg'
          "
        ></ion-icon>
      </ion-tab-button> -->
    </ion-tab-bar>
  </ion-tabs>
</ion-content>
