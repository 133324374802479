import { Component, OnInit } from '@angular/core';
import { NavController } from '@ionic/angular/standalone';
import { PieChartService } from '../../../../core/services/graph/pie-chart.service';
import {
  IonHeader,
  IonToolbar,
  IonButton,
  IonContent,
  IonNote,
  IonText,
} from '@ionic/angular/standalone';

@Component({
  selector: 'app-giving',
  templateUrl: './giving.component.html',
  styleUrls: ['./giving.component.scss'],
  standalone: true,
  imports: [IonHeader, IonToolbar, IonButton, IonContent, IonNote, IonText],
})
export class GivingComponent implements OnInit {
  constructor(
    private navController: NavController,
    private pieChartService: PieChartService,
  ) {}

  ngOnInit() {
    this.pieChartService.renderPieChart();
  }

  back() {
    this.navController.back();
  }

  view() {}
}
