import { Component, OnInit } from '@angular/core';
import { NavController } from '@ionic/angular/standalone';
import { Subscription } from 'rxjs';
import { AuthService } from 'src/app/core/services/auth/auth.service';
import { Charity } from '../../../core/models/Charity';
import { User } from 'src/app/core/models/User';
import { CharityService } from '../../../core/services/charity/charity.service';
import {
  IonHeader,
  IonToolbar,
  IonButton,
  IonContent,
  IonList,
  IonItem,
  IonLabel,
  IonText,
  IonNote,
} from '@ionic/angular/standalone';

@Component({
  selector: 'app-charity-role-menu',
  templateUrl: './charity-role-menu.page.html',
  styleUrls: ['./charity-role-menu.page.scss'],
  standalone: true,
  imports: [
    IonHeader,
    IonToolbar,
    IonButton,
    IonContent,
    IonList,
    IonItem,
    IonLabel,
    IonText,
    IonNote,
  ],
})
export class CharityRoleMenuPage implements OnInit {
  selectedCharity: Charity;
  loggedInUser: User;
  private readonly subscriptions = new Subscription();

  constructor(
    private authService: AuthService,
    private charityService: CharityService,
    private navController: NavController,
  ) {}

  ngOnInit() {
    this.getCharityInfo();
  }

  getCharityInfo() {
    this.subscriptions.add(
      this.charityService.getCharity('1').subscribe(charity => {
        if (charity) {
          this.selectedCharity = charity;
        }
      }),
    );
  }

  getLoggedInUserInfo() {
    this.subscriptions.add(
      this.authService.user$.subscribe(user => {
        if (user) {
          this.loggedInUser = user;
        }
      }),
    );
  }

  signOut() {
    this.authService.logout();
  }

  back() {
    this.navController.back();
  }

  goToItem(item: string) {
    this.navController.navigateForward(['/charity-role-menu/' + item + '/']).then(r => r);
  }

  switchToUser() {
    this.navController.navigateRoot(['user-role-tabs/home']).then(r => r);
  }
}
