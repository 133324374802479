import { Component, OnInit } from '@angular/core';
import { NavController } from '@ionic/angular/standalone';
import {
  IonHeader,
  IonToolbar,
  IonButton,
  IonContent,
  IonList,
  IonItem,
  IonLabel,
  IonText,
} from '@ionic/angular/standalone';

@Component({
  selector: 'app-settings',
  templateUrl: './settings.component.html',
  styleUrls: ['./settings.component.scss'],
  standalone: true,
  imports: [IonHeader, IonToolbar, IonButton, IonContent, IonList, IonItem, IonLabel, IonText],
})
export class SettingsComponent implements OnInit {
  constructor(private navController: NavController) {}

  // eslint-disable-next-line @angular-eslint/no-empty-lifecycle-method
  ngOnInit() {}

  back() {
    this.navController.back();
  }

  deactivate() {}
}
