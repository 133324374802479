<ion-header class="ion-no-border">
  <ion-toolbar>
    <ion-button fill="clear" (click)="back()">
      <img src="../../../../../../assets/ic-back-clay.svg" alt="navigate back button" />
    </ion-button>
  </ion-toolbar>
</ion-header>

<ion-content [fullscreen]="true">
  @if (!showForm) {
    <div class="container">
      <div class="search-container">
        <h3 class="header">Charities Search</h3>
        <p>Enter the EIN of the charity you wish to claim.</p>

        <div class="search-bar">
          <ion-searchbar placeholder="EIN" (ionInput)="getSearchValue($event)"></ion-searchbar>
          <div
            (click)="searchCharityByEin(inputEin)"
            (keydown)="searchCharityByEin(inputEin)"
            tabindex="{0}"
          >
            <img src="assets/ic-search-black.svg" alt="search icon button" />
          </div>
        </div>

        @if (charity) {
          <ion-item class="found-charity-item">
            <img src="assets/ic-profile-charity.svg" alt="profile icon" />
            <ion-label>
              <ion-text>{{ charity?.name | titlecase }}</ion-text>
              <p>{{ charity?.ein }}</p>
            </ion-label>
            <a (click)="goToClaimCharityForm()" (keydown)="goToClaimCharityForm()" tabindex="{0}">
              Claim
            </a>
          </ion-item>
        }
        @if (!charity) {
          <small>No charity found.</small>
        }
      </div>

      <div class="help-container">
        <h3 class="header">Can't find your charity?</h3>
        <ion-item [button]="true">
          <ion-text>Send us an email</ion-text>
        </ion-item>
        <ion-item [button]="true">
          <ion-text>Claim Charity Online</ion-text>
        </ion-item>
      </div>
    </div>
  }

  @if (showForm) {
    <app-claim-charity [userId]="userId" [charity]="charity"></app-claim-charity>
  }
</ion-content>
