<ion-header class="ion-no-border">
  <ion-toolbar>
    <ion-button fill="clear" (click)="back()">
      <img src="assets/ic-back-clay.svg" alt="navigate back button" />
    </ion-button>
  </ion-toolbar>
</ion-header>

<ion-content [fullscreen]="true">
  <div class="main-content">
    <section>
      <div class="img-item">
        <img [src]="product.imageUrl" alt="{{ product.name }}" />
      </div>

      <div class="item-details">
        <h5>{{ product.name }}</h5>
        <h4>${{ product.currentPrice }}</h4>
        <!-- <a>Sunny Street</a> -->
        <p>{{ product.description }}</p>
      </div>
    </section>
  </div>

  <section class="btn-section">
    <button class="btn-donate" id="open-modal" (click)="onDonate()">Buy</button>
  </section>
</ion-content>
