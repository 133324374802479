import { Component, OnInit, OnDestroy, inject } from '@angular/core';
import { Subscription } from 'rxjs';
import { TitleCasePipe } from '@angular/common';
import {
  IonHeader,
  IonToolbar,
  IonContent,
  IonGrid,
  IonRow,
  IonCol,
  IonLabel,
  IonNote,
} from '@ionic/angular/standalone';
import { User } from 'src/app/core/models/User';
import { Donation } from 'src/app/core/models/Donation';
import { AuthService } from 'src/app/core/services/auth/auth.service';
import { CharityService } from 'src/app/core/services/charity/charity.service';
import { CharityDataService } from 'src/app/core/services/graph/charity-data.service';

interface UserDonation extends Donation {
  charityName?: string;
  dateString?: string;
}

@Component({
  selector: 'app-giving-history',
  templateUrl: './giving-history.page.html',
  styleUrls: ['./giving-history.page.scss'],
  standalone: true,
  imports: [
    IonHeader,
    IonToolbar,
    IonContent,
    IonGrid,
    IonRow,
    IonCol,
    IonLabel,
    IonNote,
    TitleCasePipe,
  ],
})
export class GivingHistoryPage implements OnInit, OnDestroy {
  subscriptions = new Subscription();
  authService = inject(AuthService);
  charityService = inject(CharityService);
  charityDataService = inject(CharityDataService);

  donations: UserDonation[] = [];
  loggedInUser: User;
  dataIsLoaded: boolean = false;

  constructor() {}

  ngOnInit() {
    this.getLoggedInUser();
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  getLoggedInUser() {
    this.subscriptions.add(
      this.authService.user$.subscribe(user => {
        if (user) {
          this.loggedInUser = user;
          this.getDonationHistory(user?.id);
        }
      }),
    );
  }

  getDonationHistory(userId: string) {
    this.subscriptions.add(
      // Get all charities
      this.charityService.getCharities().subscribe(charities => {
        this.subscriptions.add(
          // Get user's donations
          this.charityDataService.getDonations({ userId }, true).subscribe(res => {
            if (res) {
              this.donations = res
                .map(donation => {
                  const date: Date = new Date(donation.timestamp);
                  const userDonation: UserDonation = {
                    ...donation,
                    charityName: charities.find(charity => charity.id == donation.charityId)?.name,
                    dateString: date.toDateString(),
                  };
                  return userDonation;
                })
                .sort((a, b) => b.timestamp - a.timestamp);
              this.dataIsLoaded = true;
            }
          }),
        );
      }),
    );
  }
}
