<ion-header class="ion-no-border">
</ion-header>

<ion-content [fullscreen]="true">
  <div class="main-content">
    <!--  User summary section-->
    <section class="user-summary-section">
      <div
        class="profile-avatar"
        (click)="addProfilePicture()"
        (keydown)="addProfilePicture()"
        tabindex="{0}"
      >
        <img alt="avatar" [src]="avatarUrl" />
      </div>

      <div class="user-info">
        <ion-label>
          <p class="username">&#64;{{loggedInUser?.email}}</p>
          <br />
          <p class="donation-amount">${{totalGiving.toFixed(2)}}</p>
          <br />
          <p class="sub-text">Total in Sunny Street</p>
        </ion-label>
      </div>
    </section>

    <!--  Giving summary/segment chart section-->
    <section class="giving-summary-section">
      <h4>
        Giving
        <img src="assets/ic-info.svg" alt="" />
      </h4>
      <ion-list>
        <ion-item id="total-giving" lines="none">
          <ion-label>Total giving value</ion-label>
          <ion-note slot="end">${{totalGiving.toFixed(2)}}</ion-note>
        </ion-item>
        @for (data of chartData; track data) {
        <span>
          <ion-item class="segment-giving" lines="none">
            <ion-label>{{data?.charitySegment | titlecase}}</ion-label>
            <ion-note slot="end">{{data?.sum ? '$' + data?.sum.toFixed(2) : '-'}}</ion-note>
          </ion-item>
        </span>
        }
      </ion-list>
    </section>

    <div class="line"></div>

    <!--  Stats summary section-->
    <section class="stats-summary-section">
      <ion-row>
        <ion-col size="12">
          <ion-segment mode="md" [scrollable]="true">
            @for (title of tabTitles; track title) {
            <ion-segment-button (click)="setCurrentTab(title)">
              <p [ngClass]="selectedTab === title ? 'tab-selected' : 'tab-not-selected'">
                {{title | titlecase}}
              </p>
            </ion-segment-button>
            }
          </ion-segment>
        </ion-col>
      </ion-row>

      <div class="circles-container">
        @for (data of chartData; track data; let i = $index) {
        <div
          class="deg{{i+1}}"
          [ngClass]="selectedTab === data?.charitySegment ? 'btn-selected' : 'btn-not-selected'"
          (click)="setCurrentTab(data?.charitySegment)"
          (keydown)="setCurrentTab(data?.charitySegment)"
          tabindex="{0}"
          [style.width.px]="data?.percentage+90"
          [style.height.px]="data?.percentage+90"
        >
          <p class="graph-percentage">
            {{data?.percentage ? data?.percentage : 0}}%
            <br />
            <span class="graph-title">{{data?.charitySegment | titlecase}}</span>
          </p>
        </div>
        }
      </div>

      <!-- Culture segment suggestions-->
      @if (selectedTab === 'CULTURE') {
      <div class="suggestions-container">
        This is all of your charitable activity on private organizations.
        <br />
        <div class="chip-container">
          <ion-chip color="primary">
            <ion-icon name="beer-outline"></ion-icon>
            <ion-label>Drinks</ion-label>
          </ion-chip>
          <ion-chip color="primary">
            <ion-icon name="footsteps-outline"></ion-icon>
            <ion-label>Activities</ion-label>
          </ion-chip>
          <ion-chip color="primary">
            <ion-icon name="medkit-outline"></ion-icon>
            <ion-label>Health</ion-label>
          </ion-chip>
          <ion-chip color="primary">
            <ion-icon name="color-palette-outline"></ion-icon>
            <ion-label>Art</ion-label>
          </ion-chip>
          <ion-chip color="primary">
            <ion-icon name="tennisball-outline"></ion-icon>
            <ion-label>Sports</ion-label>
          </ion-chip>
          <ion-chip color="primary">
            <ion-icon name="globe-outline"></ion-icon>
            <ion-label>Community</ion-label>
          </ion-chip>
        </div>
      </div>
      }

      <!--  Environment segment suggestions-->
      @if (selectedTab === 'ENVIRONMENT') {
      <div class="suggestions-container">
        This is all of your charitable activity on private operating organizations.
      </div>
      }

      <!-- Health segment suggestions-->
      @if (selectedTab === 'HEALTH') {
      <div class="suggestions-container">
        This is all of your charitable activity on public charitable organizations.
        <div class="chip-container">
          <ion-chip color="medium">
            <ion-icon name="beer-outline"></ion-icon>
            <ion-label>Drinks</ion-label>
          </ion-chip>
          <ion-chip color="medium">
            <ion-icon name="footsteps-outline"></ion-icon>
            <ion-label>Activities</ion-label>
          </ion-chip>
          <ion-chip color="medium">
            <ion-icon name="tennisball-outline"></ion-icon>
            <ion-label>Sports</ion-label>
          </ion-chip>
          <ion-chip color="medium">
            <ion-icon name="globe-outline"></ion-icon>
            <ion-label>Community</ion-label>
          </ion-chip>
        </div>
      </div>
      }

      <!-- Social segment suggestions-->
      @if (selectedTab === 'SOCIAL') {
      <div class="suggestions-container">
        This is all of your charitable activity on trust charitable activities.
      </div>
      }

      <!-- Social segment suggestions-->
      @if (selectedTab === 'RELIEF') {
      <div class="suggestions-container">
        This is all of your charitable activity on trust charitable activities.
      </div>
      }

      <!-- Social segment suggestions-->
      @if (selectedTab === 'STEM') {
      <div class="suggestions-container">
        This is all of your charitable activity on trust charitable activities.
      </div>
      }

      <!-- Social segment suggestions-->
      @if (selectedTab === 'RELIGION') {
      <div class="suggestions-container">
        This is all of your charitable activity on religious and spiritual development segment.
      </div>
      }

      <!-- Social segment suggestions-->
      @if (selectedTab === 'MISC') {
      <div class="suggestions-container">
        This is all of your charitable activity on miscellaneous charity segment.
      </div>
      }
    </section>

    <!--   Learn more section-->
    <section class="discover-section">
      <h4>
        Discover More
        <img src="assets/ic-info.svg" alt="additional information icon" />
      </h4>

      <ion-list>
        <div (click)="goToDiscoverPage()" (keydown)="goToDiscoverPage()" tabindex="{0}">
          <ion-text>Explore Charities</ion-text>
          <img style="padding: 10px 1rem" src="assets/img-discover-explore.svg" alt="" />
        </div>

        <div (click)="goToNewsFeedPage()" (keydown)="goToNewsFeedPage()" tabindex="{0}">
          <ion-text>Charities in the News</ion-text>
          <img style="padding: 10px 1rem" src="assets/img-discover-news.svg" alt="" />
        </div>

        <div (click)="goToNewsFeedPage()" (keydown)="goToNewsFeedPage()" tabindex="{0}">
          <ion-text>See Friends Activity</ion-text>
          <img style="padding: 5px 1.5rem" src="assets/img-discover-friend.svg" alt="" />
        </div>
      </ion-list>
    </section>
  </div>

  <section class="btn-section">
    <button class="btn-invite" (click)="onClickInviteFriends()">Invite Friends</button>
  </section>
</ion-content>
