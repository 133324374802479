import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular/standalone';
import { NgClass, DatePipe, TitleCasePipe } from '@angular/common';
import { CheckoutComponent } from '../../../shared/components/stripe-embedded-components/checkout/checkout.component';
import { AlertService } from '../../../core/services/navigation/alert.service';
import { Charity } from '../../../core/models/Charity';
import { Donation } from '../../../core/models/Donation';
import { DonateModeToggleComponent } from 'src/app/shared/components/donate-mode-toggle/donate-mode-toggle.component';
import { Subscription } from 'rxjs';
import {
  IonHeader,
  IonToolbar,
  IonButtons,
  IonButton,
  IonContent,
  IonItem,
  IonGrid,
  IonRow,
  IonCol,
  IonLabel,
  IonText,
  IonCheckbox,
} from '@ionic/angular/standalone';
import { StripePaymentService } from 'src/app/core/services/payment/stripe-payment.service';
import { Product } from 'src/app/core/models/Product';
import { User } from 'src/app/core/models/User';
import { AuthService } from 'src/app/core/services/auth/auth.service';
import { Bid } from 'src/app/core/models/Bid';
import { CommerceService } from 'src/app/core/services/commerce/commerce.service';

@Component({
  selector: 'app-bid-modal',
  templateUrl: './bid-modal.component.html',
  styleUrls: ['./bid-modal.component.scss'],
  standalone: true,
  imports: [
    NgClass,
    DatePipe,
    TitleCasePipe,
    CheckoutComponent,
    IonHeader,
    IonToolbar,
    IonButtons,
    IonButton,
    IonContent,
    IonGrid,
    IonRow,
    IonCol,
    IonItem,
    IonLabel,
    IonText,
    IonCheckbox,
    DonateModeToggleComponent,
  ],
})
export class BidModalComponent implements OnInit, OnDestroy {
  private readonly subscriptions = new Subscription();

  @Input() selectedCharity: Charity;
  @Input() product: Product;
  @Input() userId: string;
  donationValue: string = '';
  serviceFee: number;
  isConfirmation: boolean = true;
  loggedInUser: User;

  accountId = 'acct_1PVcbkDBVigCH5BR';
  newDonation: Donation;

  constructor(
    private modalCtrl: ModalController,
    private alertService: AlertService,
    private stripeService: StripePaymentService,
    private authService: AuthService,
    private commerceService: CommerceService
  ) {}

  ngOnInit(): void {
    //this.donationValue = this.product.currentPrice;
    this.getLoggedInUser();
  }

  ngOnDestroy() {
    this.subscriptions.unsubscribe();
  }

  getLoggedInUser() {
    this.subscriptions.add(
      this.authService.user$.subscribe(user => {
        if (user) {
          this.loggedInUser = user;
        }
      })
    )
  }

  cancel() {
    this.alertService
      .showAlertForDonation('Cancel', 'Do you want to cancel this action?')
      .subscribe((res: HTMLIonAlertElement) => {
        res.onDidDismiss().then(data => {
          if (data.role === 'yes') {
            this.modalCtrl.dismiss(null, 'no');
            this.stripeService.destroyStripeCheckout();
          }
        });
      });
  }

  calculateServiceFee(value: number) {
    if (value < 40) {
      this.serviceFee = 1;
    } else {
      this.serviceFee = value * 0.025;
    }
  }

  onConfirm() {
    if (this.selectedCharity) {
      this.newDonation = {
        donationType: 'MONEY',
        charityId: this.selectedCharity?.id,
        userId: this.loggedInUser.id,
        amount: +this.donationValue,
        feeAmount: this.serviceFee,
        timestamp: Date.now(),
        charitySegment: this.selectedCharity.charitySegment,
      };
      sessionStorage.setItem('current_donation', JSON.stringify(this.newDonation));
      this.isConfirmation = false;
    }
  }

  goToConfirmationPage(value: number){
    if (value <= this.product.currentPrice){
      this.alertService.showAlertMessage("Bid too small", "Your bid must be larger than the current bid to be submitted")
    } else {
      this.placeBid(value);
    }
  }

  placeBid(value: number) {
    const bid: Bid = {
      productId: this.product.id,
      userId: Number(this.userId),
      amount: value
    }
    this.commerceService.placeBid(bid).subscribe({
      next: () => {
        this.alertService.showAlertMessage('Your bid was placed successfully!', "Check your notifications and email for updates regarding this Auction.");
        this.modalCtrl.dismiss({ bidPlaced: true });
      },
      error: () => {
        this.alertService.showAlertMessage('An error occurred while placing your bid.', 'You cannot place a new bid if you already have the highest bid.');
      },
    });
  }

  setAutoValue(value: number){
    this.donationValue = value.toString();
  }

  onKeyPress(v) {
    return (this.donationValue = this.donationValue + v.toString());
  }

  onBackSpace() {
    this.donationValue = this.donationValue.substring(0, this.donationValue.length - 1);
  }
}
