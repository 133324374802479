<ion-header class="ion-no-border">
  <ion-toolbar class="donation-toolbar">
    <div class="top-buttons">
      <ion-buttons>
        <ion-button color="medium" (click)="cancel()">
          <img src="assets/ic-close.svg" alt="close button" />
        </ion-button>
      </ion-buttons>
    </div>
  </ion-toolbar> 
</ion-header>


<ion-content class="ion-padding" fullscreen>
  <form [formGroup]="itemForm" (ngSubmit)="publishItem()">
    <ion-grid [fixed]="true">
      <ion-row>
        <ion-col>
          <ion-item>Item Name</ion-item>
          <ion-input formControlName="itemName"></ion-input>
        </ion-col>
      </ion-row>
      <ion-row>
        <ion-col>
          <ion-item>Price</ion-item>
          <ion-input formControlName="itemPrice" type="number"></ion-input>
        </ion-col>
      </ion-row>
      <ion-row>
        <ion-col>
          <ion-item>Description</ion-item>
          <ion-input formControlName="itemDescription"></ion-input>
        </ion-col>
      </ion-row>
      <ion-row>
        <ion-col>
          <!-- <ion-item *ngIf="!itemForm.get('itemImage').value"> -->
          <ion-item *ngIf="!imagePreview">
          <ion-button class="image-upload" (click)="fileInput.click()">
            <ion-icon [src]="'/assets/camera-plus.svg'"></ion-icon>
          </ion-button>
          <input #fileInput formControlName="itemImage" type="file" (change)="selectImage($event)">
        </ion-item>
        </ion-col>
        <ion-item class="imagePreview" *ngIf="imagePreview">
          <img [src]="imagePreview" alt="Selected Image" />
        </ion-item>
      </ion-row>
      <ion-row>
        <ion-col>
            <button
            class="btn-publish"
            [ngClass]="'btn-submit-enabled'"
            type="submit"
          >
            Publish Item
          </button>
        </ion-col>
      </ion-row>
    </ion-grid>
  </form>
</ion-content>


