import { Component, OnDestroy, OnInit } from '@angular/core';
import { NavController } from '@ionic/angular/standalone';
import { filter, Subscription } from 'rxjs';
import { TitleCasePipe } from '@angular/common';
import { TimeSeriesChartService } from '../../../core/services/graph/time-series-chart.service';
import { Chart } from '../../../core/constants/Chart';
import { Donation } from '../../../core/models/Donation';
import { CharityFollowService } from '../../../core/services/charity/charity-follow.service';
import { Charity } from '../../../core/models/Charity';
import { User } from '../../../core/models/User';
import { CharityDataService } from '../../../core/services/graph/charity-data.service';
import { DonateModeToggleComponent } from 'src/app/shared/components/donate-mode-toggle/donate-mode-toggle.component';
import { NavigationEnd, Router } from '@angular/router';
import {
  IonHeader,
  IonToolbar,
  IonContent,
  IonGrid,
  IonRow,
  IonCol,
  IonLabel,
  IonNote,
  IonList,
  IonText,
} from '@ionic/angular/standalone';
import { AuthService } from 'src/app/core/services/auth/auth.service';

@Component({
  selector: 'app-giving',
  templateUrl: './giving.page.html',
  styleUrls: ['./giving.page.scss'],
  standalone: true,
  imports: [
    TitleCasePipe,
    IonHeader,
    IonToolbar,
    IonContent,
    IonGrid,
    IonRow,
    IonCol,
    IonLabel,
    IonNote,
    IonList,
    IonText,
    DonateModeToggleComponent,
  ],
})
export class GivingPage implements OnInit, OnDestroy {
  private readonly subscriptions = new Subscription();
  followedCharityList: Charity[] = [];
  showButtonText = 'Show More';
  showCount = 4;

  isHourMode = false;
  totalDonatedAmountArray = [];
  numberOfDonationsArray = [];
  loggedInUser: User;

  noChartData: Donation[] = [
    {
      timestamp: 1710518453216,
      amount: 0,
    },
    {
      timestamp: 1710524642486,
      amount: 0,
    },
  ];

  constructor(
    private charityFollowService: CharityFollowService,
    private timeSeriesChart: TimeSeriesChartService,
    private navController: NavController,
    private authService: AuthService,
    private charityDataService: CharityDataService,
    private route: Router,
  ) {}

  ngOnInit() {
    this.subscriptions.add(
      this.authService.user$.subscribe(user => {
        if (user) {
          this.loggedInUser = user;
          this.getFollowedCharities();
        }
      }),
    );

    this.route.events.pipe(filter(event => event instanceof NavigationEnd)).subscribe(() => {
      if (this.loggedInUser) {
        this.getFollowedCharities();
      }
    });
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  toggleShowMore() {
    if (this.followedCharityList.length === this.showCount) {
      this.showCount = 4;
      this.showButtonText = 'Show More';
    } else {
      this.showCount = this.followedCharityList.length;
      this.showButtonText = 'Show Less';
    }
    this.getFollowedCharities();
  }

  onToggleDonationMode() {
    this.isHourMode = !this.isHourMode;
    this.getFollowedCharities();
  }

  renderChart(index: number, data: Donation[]) {
    if (data.length <= 1) {
      data = this.noChartData;
    }
    this.timeSeriesChart.renderMiniPersonalGivingChart(
      Chart.CHARITY_MINI_CHART_HTML_ID + index,
      data,
    );
  }

  getFollowedCharities() {
    this.subscriptions.add(
      this.charityFollowService
        .getFollowedCharitiesList(this.loggedInUser?.id)
        .subscribe(charityList => {
          this.totalDonatedAmountArray = [];
          this.numberOfDonationsArray = [];

          let currList: Charity[] = [];
          if (charityList?.length > 0) {
            this.followedCharityList = charityList;

            if (this.showButtonText === 'Show More') {
              currList = charityList.slice(0, 4);
            } else {
              currList = charityList;
            }

            let total: number = 0;
            for (const c of currList) {
              const i: number = this.followedCharityList.indexOf(c);
              if (c) {
                this.charityDataService
                  .getDonationsByCharityId(c.id, this.isHourMode ? 'HOUR' : 'MONEY')
                  .subscribe(res => {
                    this.numberOfDonationsArray.splice(i, 0, res.length);

                    total = res.reduce((a, b) => a + b.amount, 0);
                    this.totalDonatedAmountArray.splice(i, 0, total.toFixed(2));

                    setTimeout(() => {
                      this.renderChart(i, res);
                    }, 0);
                  });
              }
            }
          }
        }),
    );
  }
  
  goToDiscoverPage() {
    this.navController.navigateForward(['/user-role-tabs/discover']).then(r => r);
  }

  goToNewsFeedPage() {
    this.navController.navigateForward(['/user-role-tabs/social']).then(r => r);
  }

  onSelectCharity(charityId: string) {
    this.navController.navigateForward(['/user-role-tabs/charity/' + charityId]);
  }
}
