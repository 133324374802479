import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ModalController, NavController } from '@ionic/angular/standalone';
import { Subscription } from 'rxjs';
import {
  IonHeader,
  IonToolbar,
  IonButton,
  IonContent,
  IonRow,
  IonCol,
  IonGrid,
} from '@ionic/angular/standalone';
import { Product } from './../../../core/models/Product';
import { User } from 'src/app/core/models/User';
import { PurchaseModalComponent } from './../purchase-modal/purchase-modal.component';
import { Charity } from 'src/app/core/models/Charity';
import { CharityService } from 'src/app/core/services/charity/charity.service';

@Component({
  selector: 'app-item-detail',
  templateUrl: './item-detail.component.html',
  styleUrls: ['./item-detail.component.scss'],
  standalone: true,
  imports: [IonHeader, IonToolbar, IonButton, IonContent, IonRow, IonCol, IonGrid],
})
export class ItemDetailComponent implements OnInit, OnDestroy {
  private readonly subscriptions = new Subscription();
  product: Product;
  private subscription: Subscription = new Subscription();
  loggedInUser: User;
  charityId: string = "6";
  charity: Charity;

  constructor(
    private navController: NavController,
    private router: Router,
    private modalCtrl: ModalController,
    private charityService: CharityService
  ) {}

  ngOnInit() {
    this.product = this.router.getCurrentNavigation().extras.state['product'];
    this.subscriptions.add(
      this.charityService.getCharity(this.charityId).subscribe(res => {
        this.charity = res;
      }),
    );
  }

  back() {
    this.navController.back();
  }

  onDonate() {
    this.modalCtrl
      .create({
        component: PurchaseModalComponent,
        componentProps: {
          product: this.product,
          userId: this.loggedInUser?.id,
          selectedCharity: this.charity
        },
      })
      .then(modal => modal.present());
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }
}
