import { Component, CUSTOM_ELEMENTS_SCHEMA, OnDestroy, OnInit } from '@angular/core';
import { NgClass, TitleCasePipe, CommonModule } from '@angular/common';
import { Subscription } from 'rxjs';
import {
  IonHeader,
  IonToolbar,
  IonContent,
  IonSearchbar,
  IonRow,
  IonCol,
  IonSegment,
  IonSegmentButton,
  IonChip,
  IonGrid,
  NavController,
} from '@ionic/angular/standalone';
import { CommerceService } from './../../../core/services/commerce/commerce.service';
import { Product } from './../../../core/models/Product';

@Component({
  selector: 'app-marketplace',
  templateUrl: './marketplace.component.html',
  styleUrls: ['./marketplace.component.scss'],
  standalone: true,
  imports: [
    TitleCasePipe,
    NgClass,
    IonHeader,
    IonToolbar,
    IonContent,
    IonSearchbar,
    IonRow,
    IonCol,
    IonSegment,
    IonSegmentButton,
    IonChip,
    IonGrid,
    CommonModule
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class MarketplaceComponent implements OnInit, OnDestroy {
  products: Product[] = [];
  public ionChipTitles = ['All', 'Ending Soon', 'New', 'Favorite'];
  selectedChip: string = 'All';
  private subscription: Subscription = new Subscription();

  constructor(
    private commerceService: CommerceService, 
    private navController: NavController
  ) {}

  onFiltering(v: string) {
    this.selectedChip = v;
  }

  ngOnInit() {
    this.loadProducts();
  }

  loadProducts() {
    const sub: Subscription = this.commerceService.getAllMarketplaceProducts().subscribe({
      next: (products) => {
        this.products = products;
      },
      error: (err) => {
        console.error('Failed to fetch products:', err);
      }
    });
    this.subscription.add(sub);
  }

  onSelectItem(product: Product) {
    const id: string = product.id.toString();
    this.navController.navigateForward('/user-role-tabs/item-detail/'+id, {
      state: { product }
    });
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }
  
}
