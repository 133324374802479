import { Component, OnDestroy, OnInit } from '@angular/core';
import { DonationModalComponent } from '../donation-modal/donation-modal.component';
import { ActivatedRoute } from '@angular/router';
import { Subscription } from 'rxjs';
import { CharityChartComponent } from './charity-chart/charity-chart.component';
import { TitleCasePipe, DatePipe } from '@angular/common';
import { Chart } from '../../../core/constants/Chart';
import { User } from '../../../core/models/User';
import { CharityService } from '../../../core/services/charity/charity.service';
import { CharityDataService } from '../../../core/services/graph/charity-data.service';
import { TimeSeriesChartService } from '../../../core/services/graph/time-series-chart.service';
import { Charity } from '../../../core/models/Charity';
import { Donation } from '../../../core/models/Donation';
import { CharityFollowService } from '../../../core/services/charity/charity-follow.service';
import { DonateModeToggleComponent } from 'src/app/shared/components/donate-mode-toggle/donate-mode-toggle.component';
import {
  IonHeader,
  IonToolbar,
  IonContent,
  IonGrid,
  IonRow,
  IonCol,
  IonThumbnail,
  IonCard,
  AlertController,
  ModalController,
} from '@ionic/angular/standalone';
import { AuthService } from 'src/app/core/services/auth/auth.service';

@Component({
  selector: 'app-charity',
  templateUrl: './charity.page.html',
  styleUrls: ['./charity.page.scss'],
  standalone: true,
  imports: [
    CharityChartComponent,
    TitleCasePipe,
    DatePipe,
    IonHeader,
    IonToolbar,
    IonContent,
    IonGrid,
    IonRow,
    IonCol,
    IonThumbnail,
    IonCard,
    DonateModeToggleComponent,
  ],
})
export class CharityPage implements OnInit, OnDestroy {
  private readonly subscriptions = new Subscription();
  timeRangeText: string = Chart.TODAY_TEXT;

  charityId: string;
  charity: Charity;
  loggedInUser: User;
  isHourMode = false;

  moneyDonations: Donation[] = [];
  hourDonations: Donation[] = [];
  moneyDonationHistory: Donation[] = [];
  hourDonationHistory: Donation[] = [];

  totalMoneyDonation = 0;
  totalHourDonation = 0;
  totalRangeMoneyDonation = 0;
  totalRangeHourDonation = 0;

  totalDonatedMoneyByUser = 0;
  todayDonationByUser = 0;
  totalDonatedMoneyToCharityByUser = 1;

  isFollowing: boolean | object = false;
  fromDiscover: boolean = false;

  constructor(
    private modalCtrl: ModalController,
    private alertCtrl: AlertController,
    private charityFollowService: CharityFollowService,
    private charityService: CharityService,
    private charityDataService: CharityDataService,
    private authService: AuthService,
    private activatedRoute: ActivatedRoute,
  ) {}

  ngOnInit() {
    this.charityId = this.activatedRoute.snapshot.paramMap.get('id');
    this.getCharityById(this.charityId);
    this.getCharityChartDataWithTimeRange('all');
    this.getUserPosition();
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  onToggle() {
    this.isHourMode = !this.isHourMode;
  }

  getDonations() {
    return !this.isHourMode ? this.moneyDonations : this.hourDonations;
  }

  getUserPosition() {
    this.subscriptions.add(
      this.authService.user$.subscribe(user => {
        if (user) {
          this.loggedInUser = user;
          this.charityFollowService
            .isCharityFollowed(this.loggedInUser?.id, this.charityId)
            .subscribe(res => {
              this.isFollowing = res;
            });

          this.getDonationsByUser(user?.id);

          this.charityDataService
            .getDonationHistoryForCharityByUserId(this.loggedInUser?.id, this.charityId, 'MONEY')
            .subscribe(res => {
              this.moneyDonationHistory = res;
              this.totalDonatedMoneyToCharityByUser = res.reduce((a, b) => a + b.amount, 0);
            });

          this.moneyDonationHistory.forEach(d => {
            if (d.timestamp > Date.now() - 24 * 60 * 60 * 100) {
              this.todayDonationByUser = this.todayDonationByUser + d.amount;
            }
          });
        }
      }),
    );
  }

  getCharityById(id: string) {
    if (id) {
      this.subscriptions.add(
        this.charityService.getCharity(id).subscribe(res => {
          this.charity = res;
        }),
      );
    }
  }

  getDonationsByUser(id: string) {
    this.subscriptions.add(
      this.charityDataService.getDonationsByUserId(id, 'MONEY').subscribe(res => {
        if (res) {
          this.totalDonatedMoneyByUser = res.reduce((a, b) => a + b.amount, 0);
        }
      }),
    );
  }

  getCharityChartDataWithTimeRange(tab: string) {
    const numOfDays: number = TimeSeriesChartService.convertStringTabToNumOfDays(tab);

    this.charityDataService.getDonationsByCharityId(this.charityId, 'MONEY').subscribe(res => {
      this.totalMoneyDonation = res.reduce((a, b) => a + b.amount, 0);
    });
    this.charityDataService.getDonationsByCharityId(this.charityId, 'HOUR').subscribe(res => {
      this.totalHourDonation = res.reduce((a, b) => a + b.amount, 0);
    });

    this.charityDataService
      .getDonationsByTimeRangeByCharityId(this.charityId, numOfDays, 'MONEY')
      .subscribe(res => {
        this.moneyDonations = res;
        this.totalRangeMoneyDonation = this.moneyDonations.reduce((a, b) => a + b?.amount, 0);
      });
    this.charityDataService
      .getDonationsByTimeRangeByCharityId(this.charityId, numOfDays, 'HOUR')
      .subscribe(res => {
        this.hourDonations = res;
        this.totalRangeHourDonation = this.hourDonations.reduce((a, b) => a + b.amount, 0);
      });
  }

  onDonate() {
    this.modalCtrl
      .create({
        component: DonationModalComponent,
        componentProps: {
          selectedCharity: this.charity,
          userId: this.loggedInUser?.id,
          moneyDonationHistory: this.moneyDonationHistory,
          hourDonationHistory: this.hourDonationHistory,
        },
      })
      .then(modal => modal.present());
  }

  onFollowCharity() {
    if (this.isFollowing === false) {
      this.charityFollowService.followCharity(this.loggedInUser?.id, this.charity).subscribe(
        res => {
          if (res) {
            this.isFollowing = true;
          }
        },
        error => {
          console.error(error);
        },
      );
    }
  }

  presentAlert() {
    this.alertCtrl
      .create({
        header: 'Unfollow Charity',
        message: 'Are you sure you want to unfollow ' + this.charity?.name,
        buttons: [
          {
            text: 'Cancel',
            role: 'cancel',
          },
          {
            text: 'OK',
            handler: () => this.onUnfollowCharity(),
          },
        ],
      })
      .then(alert => alert.present());
  }

  onUnfollowCharity() {
    this.charityFollowService.unfollowCharity(this.loggedInUser?.id, this.charityId).subscribe(
      () => {
        this.isFollowing = false;
      },
      error => {
        console.error(error);
      },
    );
  }

  //TODO
  onShare() {}

  onTabSelected(e: string) {
    this.getCharityChartDataWithTimeRange(e);

    switch (true) {
      case e == 'today':
        this.timeRangeText = Chart.TODAY_TEXT;
        break;
      case e == '1w':
        this.timeRangeText = Chart.ONE_WEEK_TEXT;
        break;
      case e == '1m':
        this.timeRangeText = Chart.ONE_MONTH_TEXT;
        break;
      case e == '3m':
        this.timeRangeText = Chart.THREE_MONTH_TEXT;
        break;
      case e == '6m':
        this.timeRangeText = Chart.SIX_MONTH_TEXT;
        break;
      case e == '1y':
        this.timeRangeText = Chart.ONE_YEAR_TEXT;
        break;
      case e == 'all':
        this.timeRangeText = Chart.ALL_TEXT;
        break;
    }
  }
}
