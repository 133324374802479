<ion-content [ngClass]="slideIndex === 0 ? 'welcome-page-style-1' : 'welcome-page-style-2'">
  <swiper-container
    #welcomePageSwiper
    class="swiper-container"
    effect="card"
    pagination="{clickable: true}"
    initialSlide="0"
    (swiperslidechange)="onSlideChange()"
  >
    <!--    Welcome/First Intro Page-->
    <swiper-slide>
      <header>
        <h5 class="ion-text-center" size="large">
          <span id="welcome-title-1">Welcome to</span>
          <br />
          <span id="welcome-title-2">Sunny Street</span>
        </h5>
      </header>

      <div class="sub-title">
        <span>
          The app that manages your
          <br />
          charity activity.
          <br />
          <br />
          <strong>Swipe to Learn More -></strong>
        </span>
      </div>

      <div class="logo">
        <ion-img src="assets/sun-logo-yellow.svg" alt="Sunny Street yellow color logo"></ion-img>
      </div>
    </swiper-slide>

    <!--    Second intro page-->
    <swiper-slide>
      <div class="logo">
        <ion-img src="assets/logo-big.svg" alt="Sunny Street clay color logo"></ion-img>
      </div>

      <div class="app-description">
        <span>
          Charitable giving
          <br />
          made for everyone
        </span>
        <br />
        <br />
        <p>
          Access the tools you need to donate, connect, share, and shop with charities that matter
          to you.
        </p>
        <p>
          Keep track of your historical charitable donations, read impact stories, share with
          friends and explore new causes.
        </p>
      </div>
    </swiper-slide>

    <!--    Third intro page-->
    <swiper-slide>
      <div class="logo">
        <ion-img src="assets/logo-big.svg" alt="Sunny Street clay color logo"></ion-img>
      </div>
      <div class="app-description">
        <span>Why Sunny Street?</span>
        <br />
        <br />
        <p>
          Money is the currency of our physical needs. Charity is the currency of our spiritual
          needs.
          <br />
        </p>
        <p>
          By giving our gratitude for what we have increases, our understanding for what is
          important magnifies and our inner strength grows. We seek to achieve this by serving the
          giver, elevating the reach of charities dedicated to improving the human condition, and
          empower the influencer to use their reach to bring about good.
        </p>
      </div>
    </swiper-slide>
  </swiper-container>
</ion-content>

<ion-grid class="btns">
  <ion-row>
    <ion-col size-sm="6" size="6" (click)="goToLogInPage()">
      <ion-button class="btn-login" expand="full" shape="round">Log in</ion-button>
    </ion-col>

    <ion-col size-sm="6" size="6" (click)="goToSignUpPage()">
      <ion-button class="btn-signup" expand="full" shape="round">Sign up</ion-button>
    </ion-col>
  </ion-row>
</ion-grid>
