import { Component } from '@angular/core';
import { NgClass } from '@angular/common';
import { CharityMarketplaceComponent } from '../charity-marketplace/charity-marketplace.component';
import { CharityAuctionsComponent } from '../charity-auctions/charity-auctions.component';
import { IonHeader, IonToolbar, IonContent } from '@ionic/angular/standalone';

@Component({
  selector: 'app-charity-commerce',
  templateUrl: './charity-commerce.page.html',
  styleUrls: ['./charity-commerce.page.scss'],
  standalone: true,
  imports: [
    CharityMarketplaceComponent,
    CharityAuctionsComponent,
    NgClass,
    IonHeader,
    IonToolbar,
    IonContent,
  ],
})
export class CharityCommercePage {
  isMarketplaceSelected = true;
  constructor() {}

  onClickMarketplaceBtn() {
    this.isMarketplaceSelected = true;
  }

  onClickAuctionsBtn() {
    this.isMarketplaceSelected = false;
  }
}
