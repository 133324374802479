import { Component, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { NgClass } from '@angular/common';
import {
  IonRow,
  IonCol,
  IonSegment,
  IonSegmentButton,
  IonChip,
  IonList,
  IonItem,
  IonThumbnail,
  IonText,
  IonIcon,
} from '@ionic/angular/standalone';

@Component({
  selector: 'app-community-feed',
  templateUrl: './community-feed.component.html',
  styleUrls: ['./community-feed.component.scss'],
  standalone: true,
  imports: [
    NgClass,
    IonRow,
    IonCol,
    IonSegment,
    IonSegmentButton,
    IonChip,
    IonList,
    IonItem,
    IonThumbnail,
    IonText,
    IonIcon,
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class CommunityFeedComponent {
  selectedChip = 'All';
  public ionChipTitles = [
    'All',
    'Culture',
    'Environment',
    'Health',
    'Relief',
    'Religion',
    'Social',
    'STEM',
    'Trending',
    'Top Earner',
  ];
  constructor() {}

  onSelectFilterChip(v: string) {
    this.selectedChip = v;
  }
}
