<ion-header class="ion-no-border">
  <ion-toolbar></ion-toolbar>
</ion-header>

<ion-content [fullscreen]="true">
  <div class="main-content">
    @if (isMarketplaceSelected) {
    <app-marketplace></app-marketplace>
    } @if (!isMarketplaceSelected) {
    <app-auctions></app-auctions>
    }
  </div>

  <section class="btn-section">
    <button
      [ngClass]="isMarketplaceSelected ? 'btn-mp-selected' : 'btn-mp'"
      (click)="onClickMarketplaceBtn()"
    >
      Items
    </button>
    <button
      [ngClass]="!isMarketplaceSelected ? 'btn-auction-selected' : 'btn-auction'"
      (click)="onClickAuctionsBtn()"
    >
      Auctions
    </button>
  </section>
</ion-content>
